import { PointTypeEnum } from 'apis/mapNotesApi';
import PointTypeItem from './PointTypeItem';

interface PointTypeButtonProps {
    pointType: PointTypeEnum;
    onClick: () => void;
    selected?: boolean;
}

const getButtonClassName = (pointType: PointTypeEnum) => {
    switch (pointType) {
        case 'active':
            return 'bg-green-700 hover:bg-green-800 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800';
        case 'on_hold':
            return 'bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 dark:focus:ring-yellow-900';
        default:
            return 'bg-red-700 hover:bg-red-800 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900';
    }
};

export default function PointTypeButton({
    pointType,
    onClick,
    selected = false,
}: PointTypeButtonProps): JSX.Element {
    return (
        <button
            onClick={onClick}
            type="button"
            disabled={selected}
            className={`text-white border border-transparent focus:ring-4 focus:!ring-2 group flex h-min w-fit items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg ${getButtonClassName(
                pointType,
            )} ${
                selected ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
        >
            <span
                className={`flex items-center bg-white text-gray-900 transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit dark:bg-gray-900 dark:text-white rounded-md text-xs px-2  ${
                    selected ? 'bg-opacity-0 text-inherit' : ''
                }`}
            >
                {PointTypeItem(pointType)}
            </span>
        </button>
    );
}
