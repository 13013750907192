import { useMsal } from '@azure/msal-react';
import { ErrorNotificationContext } from 'components/providers/errorNotification';
import { useContext } from 'react';

import { loginRequest } from '../../authConfig';

export default function SignInButton() {
    const { instance } = useMsal();

    const { addError } = useContext(ErrorNotificationContext);

    function handleLogin() {
        instance.loginRedirect(loginRequest).catch(() => {
            addError('Login failed');
        });
    }
    return (
        <button
            type="button"
            className="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={handleLogin}
        >
            Sign in
            <svg
                className="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    );
}
