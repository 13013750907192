import {
    useEffect,
    useState,
    useCallback,
    useContext,
    forwardRef,
} from 'react';
import {
    createPointNote,
    deletePointNote,
    getPointNotesOfPoint,
    PointGet,
    PointGroupGet,
    PointNoteGet,
    PointPatch,
    PointTypeEnum,
    updatePointNote,
} from 'apis/mapNotesApi';
import { getPointTypeBackgroundColor } from 'helpers';
import DatePicker from 'react-datepicker';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { find as findLink } from 'linkifyjs';
import { Tooltip } from 'flowbite-react';
import NewNote from './PointNoteTextArea';
import PointNote from './PointNote';
import PointNotesContainer from './PointNotesContainer';
import { ErrorNotificationContext } from './providers/errorNotification';
import 'react-datepicker/dist/react-datepicker.css';
import './customReactSearchSelectStyle.css';
import PointTypeButton from './PointTypeButton';
import getRenderSearchSelectValue from './RenderSearchSelectValue';

interface PointInfoProps {
    point: PointGet;
    users: { [key: string]: string };
    pointGroups: PointGroupGet[];
    currentUsername: string;
    onPointDelete: (point: PointGet) => void;
    onPointUpdate: (point: PointPatch) => void;
}

const DatepickerInput = forwardRef<
    HTMLInputElement,
    JSX.IntrinsicElements['input']
>(({ value, onClick }, ref) => (
    <div className="relative z-0 mb-3 group">
        <input
            readOnly
            type="text"
            id="floating_datepicker"
            className="block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            value={value}
            onClick={onClick}
            ref={ref}
        />
        <label
            htmlFor="floating_datepicker"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
            Follow up date
        </label>
    </div>
));

const renderOwnerSearchSelectValue = getRenderSearchSelectValue('NREP owner');

const renderPointGroupSearchSelectValue =
    getRenderSearchSelectValue('Point group');

const getUrlHostname = (urlString: string) => {
    try {
        const url = new URL(urlString);

        if (url && url.hostname) {
            return url.hostname;
        }
        return urlString;
    } catch (e) {
        return urlString;
    }
};

export default function PointInfo({
    point,
    users,
    pointGroups,
    onPointDelete,
    onPointUpdate,
    currentUsername,
}: PointInfoProps): JSX.Element {
    const [editName, setEditName] = useState(false);
    const [contactPerson, setContactPerson] = useState(point.contact_person);

    const [size, setSize] = useState(point.size);
    const [followUpTimestamp, setFollowUpTimestamp] = useState<null | Date>(
        point.follow_up_timestamp
            ? new Date(`${point.follow_up_timestamp}Z`)
            : null,
    );
    const [name, setName] = useState<string | null>(point.name);
    const [pointNotes, setPointNotes] = useState<PointNoteGet[]>([]);
    const [notesLoading, setNotesLoading] = useState<boolean>(false);

    const { addError } = useContext(ErrorNotificationContext);

    const pointGroup = point
        ? pointGroups.find((group) => group.uuid === point.point_group_uuid)
        : undefined;

    const fetchPointNotesOfPoint = () => {
        setNotesLoading(true);
        getPointNotesOfPoint(point.uuid)
            .then((getPointNotesResponse) => {
                if (getPointNotesResponse.status === 200) {
                    setPointNotes(getPointNotesResponse.data);
                } else {
                    addError(
                        `Couldn't fetch notes for the point. Error code: ${getPointNotesResponse.status}`,
                    );
                }
                setNotesLoading(false);
            })
            .catch(() => {
                addError(
                    "Network or server error! Check your internet connection! Couldn't fetch notes for the point. ",
                );
                setNotesLoading(false);
            });
    };
    useEffect(fetchPointNotesOfPoint, []);

    const renderMapPointNote = useCallback(
        (note: PointNoteGet) => (
            <PointNote
                pointNote={note}
                creatorName={users[note.creator_username] || ''}
                currentUsername={currentUsername}
                onPointNoteDelete={(deletedNote) => {
                    deletePointNote(deletedNote.uuid)
                        .then((response) => {
                            if (response.status === 204) {
                                fetchPointNotesOfPoint();
                            } else {
                                addError(
                                    `Couldn't delete note: Error code: ${response.status}`,
                                );
                            }
                        })
                        .catch(() => {
                            addError(
                                "Network or server error! Check your internet connection! Couldn't delete note.",
                            );
                        });
                }}
                onPointNoteUpdate={(notePatch) => {
                    updatePointNote(note.uuid, notePatch)
                        .then((updateNoteResponse) => {
                            if (updateNoteResponse.status === 200) {
                                fetchPointNotesOfPoint();
                            } else {
                                addError(
                                    `Couldn't update note! Error code: ${updateNoteResponse.status}`,
                                );
                            }
                        })
                        .catch(() => {
                            addError(
                                "Network or server error! Check your internet connection! Couldn't update note.",
                            );
                        });
                }}
            />
        ),
        [currentUsername, pointNotes, setPointNotes],
    );

    const noteLinks = findLink(pointNotes.map((note) => note.text).join(' '));
    return (
        <>
            <div
                className={`px-3 py-1 w-full rounded-lg border border-2 ${getPointTypeBackgroundColor(
                    point,
                )}  dark:bg-gray-800 dark:border-gray-700`}
            >
                <div className="flex justify-between space-x-2 mb-1">
                    {editName ? (
                        <input
                            value={name || ''}
                            ref={(input) => input && input.focus()}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={point.address || ''}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => {
                                onPointUpdate({
                                    name:
                                        typeof name === 'string' &&
                                        name.trim() !== ''
                                            ? name.trim()
                                            : null,
                                    updater_username: currentUsername,
                                });
                                setEditName(false);
                            }}
                        />
                    ) : (
                        <div className="flex space-x-4">
                            <span className="text-2xl tracking-tight font-bold text-gray-900 dark:text-white">
                                {point.name || point.address}
                            </span>
                            <button
                                type="button"
                                className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 text-center dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                                onClick={() => {
                                    setEditName(true);
                                }}
                            >
                                {point.name === null ? 'Add name' : 'Edit name'}
                            </button>
                        </div>
                    )}

                    <button
                        type="button"
                        onClick={() => onPointDelete(point)}
                        className={`${
                            pointNotes.length > 0
                                ? 'bg-red-400 dark:bg-red-500 cursor-not-allowed'
                                : 'bg-red-700 hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 focus:ring-red-300 focus:ring-4'
                        } text-white focus:outline-none font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center `}
                        disabled={pointNotes.length > 0}
                    >
                        <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                        </svg>
                        Delete
                    </button>
                </div>

                <div className="mb-3 flex space-x-2 items-center">
                    {(
                        [
                            'active',
                            'on_hold',
                            'killed_or_lost',
                        ] as PointTypeEnum[]
                    ).map((pointType) => (
                        <PointTypeButton
                            key={pointType}
                            pointType={pointType}
                            onClick={() =>
                                onPointUpdate({
                                    point_type: pointType,
                                    updater_username: currentUsername,
                                })
                            }
                            selected={point.point_type === pointType}
                        />
                    ))}
                </div>
                <div className="relative z-0 w-full mb-3 group">
                    <input
                        type="text"
                        id="floating_contact_person"
                        className="block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        value={contactPerson || ''}
                        onChange={(e) => setContactPerson(e.target.value)}
                        onBlur={() => {
                            if (point.contact_person !== contactPerson) {
                                onPointUpdate({
                                    contact_person: contactPerson,
                                    updater_username: currentUsername,
                                });
                            }
                        }}
                    />
                    <label
                        htmlFor="floating_contact_person"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                        Seller / contact person
                    </label>
                </div>
                <div className="mb-3">
                    <SelectSearch
                        options={Object.keys(users).map((key: string) => ({
                            name: users[key],
                            value: key,
                        }))}
                        renderValue={renderOwnerSearchSelectValue}
                        search
                        // autoComplete="on"
                        value={point.owner_username || undefined}
                        filterOptions={fuzzySearch}
                        onChange={(newOwnerUsername) => {
                            if (typeof newOwnerUsername === 'string') {
                                if (point.owner_username !== newOwnerUsername) {
                                    onPointUpdate({
                                        owner_username: newOwnerUsername,
                                        updater_username: currentUsername,
                                    });
                                }
                            }
                        }}
                        placeholder=" "
                    />
                </div>

                <div className="relative z-0 w-full mb-3 group">
                    <input
                        type="text"
                        id="floating_size"
                        className="block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=""
                        value={typeof size === 'number' ? size : ''}
                        onChange={(e) => {
                            const number = +e.target.value.replace(
                                /[^0-9]/g,
                                '',
                            );

                            if (
                                (Number.isNaN(e.target.value) ||
                                    !e.target.value) &&
                                number === 0
                            ) {
                                setSize(null);
                            } else {
                                setSize(number);
                            }
                        }}
                        onBlur={() => {
                            if (point.size !== size) {
                                onPointUpdate({
                                    size,
                                    updater_username: currentUsername,
                                });
                            }
                        }}
                    />
                    <label
                        htmlFor="floating_size"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                        Size (k-m<sup>2</sup>)
                    </label>
                </div>

                <DatePicker
                    selected={followUpTimestamp}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(newDate) => {
                        setFollowUpTimestamp(newDate);
                    }}
                    showPopperArrow={false}
                    dateFormat="dd/MM/yyyy"
                    showWeekNumbers
                    onCalendarClose={() => {
                        const newDateValue =
                            followUpTimestamp === null
                                ? null
                                : followUpTimestamp
                                      .toISOString()
                                      .replace('Z', '');

                        if (followUpTimestamp !== null) {
                            if (
                                point.follow_up_timestamp === null ||
                                followUpTimestamp.getTime() !==
                                    new Date(
                                        `${point.follow_up_timestamp}Z`,
                                    ).getTime()
                            ) {
                                onPointUpdate({
                                    follow_up_timestamp: newDateValue,
                                    updater_username: currentUsername,
                                });
                            }
                        } else if (point.follow_up_timestamp !== null) {
                            onPointUpdate({
                                follow_up_timestamp: null,
                                updater_username: currentUsername,
                            });
                        }
                    }}
                    customInput={<DatepickerInput />}
                />

                <div className="mb-3">
                    <SelectSearch
                        options={pointGroups.map((group) => ({
                            name: group.name,
                            value: group.uuid,
                        }))}
                        renderValue={renderPointGroupSearchSelectValue}
                        search
                        // autoComplete="on"
                        value={pointGroup?.uuid || undefined}
                        filterOptions={fuzzySearch}
                        onChange={(newPointGroupUuid) => {
                            if (typeof newPointGroupUuid === 'string') {
                                if (
                                    point.point_group_uuid !== newPointGroupUuid
                                ) {
                                    onPointUpdate({
                                        point_group_uuid: newPointGroupUuid,
                                        updater_username: currentUsername,
                                    });
                                }
                            }
                        }}
                        placeholder=" "
                    />
                </div>

                {(point.name !== null || editName) && (
                    <p className="mb-1 font-normal text-xs text-gray-700 dark:text-gray-400">
                        Address: {point.address}
                    </p>
                )}

                <p className="mb-1 font-normal text-xs text-gray-700 dark:text-gray-400">
                    Creator:{' '}
                    {`${users[point.creator_username] || ''} (${
                        point.creator_username
                    })`}
                </p>
                <p className="mb-1 font-normal text-xs text-gray-700 dark:text-gray-400">
                    Created:{' '}
                    {new Date(`${point.created_timestamp}Z`).toLocaleString(
                        'fi-FI',
                    )}
                </p>
                <p className="mb-1 font-normal text-xs text-gray-700 dark:text-gray-400">
                    Coordinates: {`${point.lat}, ${point.lng}`}
                </p>

                {noteLinks && noteLinks.length > 0 && (
                    <div className="mb-3 mt-3 w-full">
                        <p className="mb-1 font-bold text-xs text-gray-700 dark:text-gray-400">
                            Note links
                        </p>

                        <div className="flex flex-wrap">
                            {noteLinks.map((noteLink) => (
                                <Tooltip
                                    key={noteLink.start}
                                    content={noteLink.value}
                                >
                                    <a
                                        href={noteLink.href}
                                        target="_blank"
                                        className="mb-1 mr-1 block max-w-[35ch] truncate bg-blue-200 text-blue-800 text-xs font-semibold  px-2.5 py-0.5 rounded dark:bg-blue-300 dark:text-blue-900 hover:underline"
                                        rel="noreferrer"
                                    >
                                        {getUrlHostname(noteLink.value)}
                                    </a>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <PointNotesContainer
                mapPointNotes={pointNotes}
                loading={notesLoading}
                renderPointNote={renderMapPointNote}
                NewPointNoteComponent={
                    <NewNote
                        buttonType="add"
                        onPointNoteAdd={(newNoteText) => {
                            createPointNote({
                                point_uuid: point.uuid,
                                text: newNoteText,
                                creator_username: currentUsername,
                            })
                                .then((createPointNoteResponse) => {
                                    if (
                                        createPointNoteResponse.status === 200
                                    ) {
                                        fetchPointNotesOfPoint();
                                    } else {
                                        addError(
                                            `Couldn't create a point note! Error code: ${createPointNoteResponse.status}`,
                                        );
                                    }
                                })
                                .catch(() => {
                                    addError(
                                        "Network or server error! Check your internet connection! Couldn't create note!",
                                    );
                                });
                        }}
                    />
                }
            />
        </>
    );
}
