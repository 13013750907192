import { AccountInfo } from '@azure/msal-browser';
import {
    PointGroupGetWithPointCount,
    getPointGroups,
    updatePointGroup,
    deletePointGroup,
} from 'apis/mapNotesApi';
import LoadingSpinner from 'components/LoadingSpinner';
import { Modal, Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';

function renderTimeStamp(timeStamp: string): string {
    const date = new Date(`${timeStamp}Z`);
    return `${date.toLocaleDateString('fi-FI')} (${date.toLocaleTimeString(
        'fi-FI',
    )})`;
}

function renderUsername(
    username: string,
    users: Record<string, string>,
): string {
    return `${users[username] || ''} (${username})`;
}

function PointGroupRow(props: {
    pointGroup: PointGroupGetWithPointCount;
    users: Record<string, string>;
    onEdit: (pointGroup: PointGroupGetWithPointCount) => void;
    onDelete: (pointGroup: PointGroupGetWithPointCount) => void;
}): JSX.Element {
    const { pointGroup, users, onEdit, onDelete } = props;

    const renderDeleteIconAndText = (): JSX.Element => (
        <>
            <svg
                className="w-4 h-4 mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
            </svg>
            Delete
        </>
    );

    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
                {pointGroup.name}
            </th>
            <td className="py-4 px-6">{pointGroup.point_count}</td>
            <td className="py-4 px-6">
                {pointGroup.creator_username !== null
                    ? renderUsername(pointGroup.creator_username, users)
                    : '-'}
            </td>
            <td className="py-4 px-6">
                {renderTimeStamp(pointGroup.created_timestamp)}
            </td>
            <td className="py-4 px-6">
                {pointGroup.updater_username !== null
                    ? renderUsername(pointGroup.updater_username, users)
                    : '-'}
            </td>
            <td className="py-4 px-6">
                {pointGroup.updated_timestamp
                    ? renderTimeStamp(pointGroup.updated_timestamp)
                    : '-'}
            </td>
            <td className="py-4 px-6">
                <button
                    type="button"
                    className="bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 focus:ring-blue-300 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-white focus:ring`-4 focus:outline-none  font-medium rounded-md text-xs px-1 py-1 text-center inline-flex items-center"
                    onClick={() => onEdit(pointGroup)}
                >
                    Edit
                </button>
            </td>
            <td className="py-4 px-6">
                {pointGroup.point_count > 0 ? (
                    <Tooltip
                        placement="left"
                        content="You cannot delete a point group that contains points. Delete points first"
                    >
                        <button
                            type="button"
                            onClick={() => onDelete(pointGroup)}
                            className="bg-red-400 dark:bg-red-500 cursor-not-allowed text-white focus:outline-none font-medium rounded-md text-xs px-1 py-1 text-center inline-flex items-center"
                            disabled
                        >
                            {renderDeleteIconAndText()}
                        </button>
                    </Tooltip>
                ) : (
                    <button
                        type="button"
                        onClick={() => onDelete(pointGroup)}
                        className="bg-red-700 hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 focus:ring-red-300 focus:ring-4 text-white focus:outline-none font-medium rounded-md text-xs px-1 py-1 text-center inline-flex items-center"
                    >
                        {renderDeleteIconAndText()}
                    </button>
                )}
            </td>
        </tr>
    );
}

function PointGroupEditModal({
    open,
    pointGroup,
    onEditPointGroup,
    onClose,
}: {
    open: boolean;
    pointGroup: PointGroupGetWithPointCount;
    onEditPointGroup: (pointGroupPatch: { name: string }) => void;
    onClose: () => void;
}): JSX.Element {
    const [pointGroupName, setPointGroupName] = useState(pointGroup.name);
    return (
        <Modal show={open} onClose={onClose} size="md">
            <Modal.Header>Edit point group</Modal.Header>
            <Modal.Body>
                <div className=" px-6 lg:px-8 space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Point group name
                        </label>
                        <input
                            type="text"
                            name="pointGroupName"
                            value={pointGroupName}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            onChange={(e) => setPointGroupName(e.target.value)}
                        />
                    </div>

                    <button
                        type="button"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => {
                            if (pointGroupName) {
                                onEditPointGroup({ name: pointGroupName });
                                setPointGroupName('');
                            }
                        }}
                    >
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

interface PointGroupsProps {
    users: Record<string, string>;
    account: AccountInfo;
}

export default function PointGroups(props: PointGroupsProps): JSX.Element {
    const { users, account } = props;

    const [loadingPointGroups, setLoadingPointGroups] = useState(true);
    const [pointGroups, setPointGroups] = useState<
        PointGroupGetWithPointCount[]
    >([]);

    const [pointGroupToBeEdited, setPointGroupToBeEdited] = useState<
        PointGroupGetWithPointCount | undefined
    >(undefined);

    const fetchPointGroups = async () => {
        if (!loadingPointGroups) {
            setLoadingPointGroups(true);
        }

        const fetchedPointGroupsResponse = await getPointGroups();
        if (
            fetchedPointGroupsResponse.status === 200 &&
            fetchedPointGroupsResponse.data
        ) {
            setPointGroups(
                fetchedPointGroupsResponse.data.sort((a, b) =>
                    new Date(a.created_timestamp) <
                    new Date(b.created_timestamp)
                        ? 1
                        : -1,
                ),
            );
            setLoadingPointGroups(false);
        }
    };

    useEffect(() => {
        fetchPointGroups();
    }, []);

    return loadingPointGroups ? (
        <div className="flex w-full h-full mt-16 justify-center">
            <LoadingSpinner text="Loading point groups..." />
        </div>
    ) : (
        <div>
            <div className="mt-4 w-full h-full flex flex-col items-center justify-center ">
                <div className="mx-2 mt-1 shadow-md p-4 rounded-md">
                    <div className="overflow-x-auto relative">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="py-3 px-6">
                                        Name
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Point count
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Creator
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Created at
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Updater
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Updated at
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Edit
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {pointGroups.map((pointGroup) => (
                                    <PointGroupRow
                                        pointGroup={pointGroup}
                                        users={users}
                                        key={pointGroup.uuid}
                                        onEdit={(clickedPointGroup) => {
                                            setPointGroupToBeEdited(
                                                clickedPointGroup,
                                            );
                                        }}
                                        onDelete={(clickedPointGroup) => {
                                            deletePointGroup(
                                                clickedPointGroup.uuid,
                                            ).then(() => {
                                                fetchPointGroups();
                                            });
                                        }}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {pointGroupToBeEdited && (
                <PointGroupEditModal
                    open={!!pointGroupToBeEdited}
                    pointGroup={pointGroupToBeEdited}
                    onEditPointGroup={(pointGroupPatch) => {
                        setPointGroupToBeEdited(undefined);

                        if (
                            pointGroupPatch.name !== pointGroupToBeEdited.name
                        ) {
                            updatePointGroup(pointGroupToBeEdited.uuid, {
                                ...pointGroupPatch,
                                updater_username: account.username,
                            }).then(() => {
                                fetchPointGroups();
                            });
                        }
                    }}
                    onClose={() => {
                        setPointGroupToBeEdited(undefined);
                    }}
                />
            )}
        </div>
    );
}
