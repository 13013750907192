export const nrepUsers = {
    '10year_anniversary@nrep.com': '10year_anniversary',
    '2ndckm@nrep.com': 'Kenneth Madsen (2ndc)',
    '365scanrelay@nrep.com': '365ScanRelay',
    'aaha@nrep.com': 'Aaron Hardiman',
    'aami@nrep.com': 'Åsmund Michalsen',
    'abga@nrep.com': 'Abdulrahman Gargoum',
    'adlu@nrep.com': 'Andreas Luczak',
    'adm@nrep.com': 'adm',
    'adm_abal@nrep.com': 'Abdulkafi Al-Imam (Admin)',
    'adm_anbo@nrep.com': 'adm_anbo',
    'adm_andi@nrep.com': 'Anders Dissing (Admin)',
    'adm_anfi@nrep.com': 'Andreas Fisker (Admin)',
    'adm_bawa@nrep.com': 'Bastian Wagner',
    'adm_boch@nrep.com': 'Bo Christiansen (Admin)',
    'adm_demo@nrep.com': 'Dennis Mortensen',
    'adm_esti@nrep.com': 'Esa Tirkkonen (Admin)',
    'adm_jehol@nrep.com': 'Jens Holbach',
    'adm_joal@nrep.com': 'Joakim Albrechtsen (Admin)',
    'adm_jowu@nrep.com': 'Johanna Wunsch (Admin)',
    'adm_kiso@nrep.com': 'Kim Søndergård',
    'adm_laro@nrep.com': 'Lasse Ronnenberg (Admin)',
    'adm_maad@nrep.com': 'Marc Adalete (Admin)',
    'adm_mags@nrep.com': 'Markus Gustavsson',
    'adm_mato@nrep.com': 'Mats Törnqvist (Admin)',
    'adm_miku@nrep.com': 'Michelle Kusmic',
    'adm_mora@nrep.com': 'Morteza Rakhshandehkhoo',
    'adm_olpa@nrep.com': 'Oliver Paulsen (Admin)',
    'adm_oshe@nrep.com': 'Osku Heikkinen (Admin)',
    'adm_pewa@nrep.com': 'Peter Wallin (Admin)',
    'adm_rach@nrep.com': 'Ralph Chreskjær (Admin)',
    'adm_ramp@nrep.com': 'Rami Peltola (admin)',
    'adm_resc@nrep.com': 'Reinhard Scheck (Admin)',
    'adm_roost@nrep.com': 'Rolf Østergaard (Admin)',
    'adm_time@nrep.com': 'Tiina Merilainen (Admin)',
    'adm_toje@nrep.com': 'Tobias Claudi Jensen (Admin)',
    'adm_toni@nrep.com': 'Tommy Nielsen (Admin)',
    'adm_vime@nrep.com': 'Ville Metsola (Admin)',
    'admdk@nrep.com': 'Adm Denmark',
    'nrepazsql02@nrep.com': 'NREP Azure SQL02 Admin',
    'adminjaynet@nrep.com': 'Jaynet Admin',
    'admno@nrep.com': 'Adm  Norway',
    'admse@nrep.com': 'Adm Sweden',
    'agjo@nrep.com': 'Agris Jomerts',
    'agoralounge@nrep.com': 'Conf. Room SE Agora Lounge (50 seats – internal)',
    'ahbr@nrep.com': 'Ahti Brummer',
    'ahom@nrep.com': 'Ahad Omar',
    'akho@nrep.com': 'Aksel Homan',
    'akib@nrep.com': 'Aki Baarman',
    'akri@nrep.com': 'Akseli Rikka',
    'albe@nrep.com': 'Alexander Berthelsen',
    'albi@nrep.com': 'Alexander Sabroe Bitzow',
    'alda@nrep.com': 'Alexander Danielsen',
    'alek@nrep.com': 'Alfred Eklöf',
    'alhi@nrep.com': 'Aleksi Hiltunen',
    'alki@nrep.com': 'Alexander Kielland',
    'alpe@nrep.com': 'Aleksa Pešić',
    'alpl@nrep.com': 'Allan Plauborg',
    'alra@nrep.com': 'Aleksandra Radomska',
    'alst@nrep.com': 'Alexandra Ståhlberg',
    'alwi@nrep.com': 'Alexandra Winther',
    'alwo@nrep.com': 'Alan Wood',
    'alyo@nrep.com': 'Alek Yordanov',
    'amfiles@nrep.com': 'amfiles',
    'amis@nrep.com': 'Ameer Issa',
    'amla@nrep.com': 'Amalie Lange',
    'amsa@nrep.com': 'Amandus Sahlberg',
    'anah@nrep.com': 'Anders Ahlmark',
    'anar@nrep.com': 'Angelica Aria',
    'anco@nrep.com': 'Andreea Covatariu',
    'anda@nrep.com': 'Anne Cathrine Bertelsen Dall',
    'andi@nrep.com': 'Anders Dissing',
    'ands@nrep.com': 'Anders Damkilde Schmidt',
    'anei@nrep.com': 'Anna Eriksson',
    'anerl@nrep.com': 'Anna Erlandsson',
    'ango@nrep.com': 'Anders Gottlieb',
    'angr@nrep.com': 'Anna Katrine Granzow',
    'anha@nrep.com': 'Anders Østergaard Hansen',
    'anhg@nrep.com': 'Anders Boe Hauggaard',
    'anhi@nrep.com': 'Anna Hillinge',
    'anhe@nrep.com': 'Anu Hulkkonen',
    'anjd@nrep.com': 'Anna Jo Dinesen',
    'anka@nrep.com': 'Anni Kauppila',
    'anke@nrep.com': 'Anna Keergaard Christiansen',
    'anld@nrep.com': 'Andreas Lundberg',
    'anln@nrep.com': 'Antti Lindström',
    'anma@nrep.com': 'Anne Marx',
    'anme@nrep.com': 'Antonio Melani',
    'anmn@nrep.com': 'Anna-Mette Monnelly',
    'anmo@nrep.com': 'Annemette Demant Møller',
    'anmu@nrep.com': 'Anna Carina Mullins',
    'anol@nrep.com': 'Andreas Olsson',
    'anpa@nrep.com': 'Ansofie Paddison',
    'anpo@nrep.com': 'Anton Lejre Poulsen',
    'anpr@nrep.com': 'Andreas Proschowsky',
    'anre@nrep.com': 'Anne Sofie Vett Raaschou',
    'anro@nrep.com': 'Anders Rosendahl',
    'ansae@nrep.com': 'Anders Sælan',
    'ansc@nrep.com': 'Anne Schachner',
    'ansj@nrep.com': 'Ann-Sofi Sjöström',
    'ansl@nrep.com': 'Anna Slabinskaya',
    'anso@nrep.com': 'Anders Berg Sørensen',
    'antli@nrep.com': 'Anthony Lindfield',
    'anto@nrep.com': 'Antti Toropainen',
    'antp@nrep.com': 'Antti Partanen',
    'anur@nrep.com': 'Anna Urbańska',
    'anvh@nrep.com': 'Antti Vähäsarja',
    'anvn@nrep.com': 'Anne Rask Vendelbjerg',
    'anwa@nrep.com': 'Annika Wahlund',
    'approval.vendor@nrep.com': 'Approval Vendor',
    'arba@nrep.com': 'Armin Bajramovic',
    'arbe@nrep.com': 'Arthur Bergenheim',
    'arbr@nrep.com': 'Arne Becker',
    'arko@nrep.com': 'Arkadiusz Kossakowski',
    'arme@nrep.com': 'Arto Mehtonen',
    'asha@nrep.com': 'Astrid Vilhelm Hansen',
    'audr@nrep.com': 'Alina Drǎgan',
    'aufr@nrep.com': 'Ausra Frankaite',
    'auja@nrep.com': 'August Jacobsen',
    'Avallone@nrep.com': 'Avallone',
    'ax@nrep.com': 'Dynamix AX',
    'axpe@nrep.com': 'Axel Pettersson',
    'axsn@nrep.com': 'Axel Snellman',
    'azureadconnect@nrep.com': 'Azure AD Connect',
    'becr@nrep.com': 'Bee Crinnion',
    'bema@nrep.com': 'Benjamin Mabbett',
    'beoe@nrep.com': 'Benedicte Østergaard Hansen',
    'besadmin@nrep.com': 'BESAdmin',
    'bivi@nrep.com': 'Birgitte Villadsen',
    'blsy@nrep.com': 'Bloomberg System',
    'bohr@nrep.com': 'Bo Holse Rasmussen',
    'brli@nrep.com': 'Britt Lindqvist',
    'brmo@nrep.com': 'Brian Møllebjerg',
    'cach@nrep.com': 'Casper Christensen',
    'caer@nrep.com': 'Camilla Nies Eriksen',
    'caha@nrep.com': 'Camilla Håkansson',
    'cahj@nrep.com': 'Carlo Hjort',
    'CAJO@nrep.com': 'Carina Johansson',
    'cala@nrep.com': 'Casper F. Langhoff',
    'cape@nrep.com': 'Carsten Pedersen',
    'cara@nrep.com': 'Calogero Rallo',
    'caro@nrep.com': 'Camille Robertsen',
    'casc@nrep.com': 'Carl-Adam Von Scheele',
    'cate@nrep.com': 'Caroline Amstrup Termansen',
    'cavi@nrep.com': 'Caroline Vilhelmsson',
    'cawe@nrep.com': 'Camilla Wermelin',
    'cawen@nrep.com': 'Carl-Gustaf Wennström',
    'cazh@nrep.com': 'Caroline Zheng',
    'CCS_notifications@nrep.com': 'CCS Notifications',
    'cees@nrep.com': 'Cecilia Estlander Kønigsfeldt',
    'cefr@nrep.com': 'Celia Francis',
    'cehu@nrep.com': 'Cecilia Hurtig',
    'cekl@nrep.com': 'Cecilie Klepstad',
    'cepb@nrep.com': 'Cecilie Peuron-Berg',
    'cepe@nrep.com': 'Cecilie Orland Pedersen',
    'CGI-CRM@nrep.com': 'CGI-CRM',
    'chaa@nrep.com': 'Christopher Aandahl',
    'chairpersonsoffice@nrep.com': 'Chair Persons Office',
    'chax@nrep.com': 'Christian Axeltoft',
    'chbl@nrep.com': 'Christian Blankschøn',
    'chbr@nrep.com': 'Christoffer Breum',
    'chbw@nrep.com': 'Christine Bjellekjær Winther',
    'chgu@nrep.com': 'Chatrine Gustavsson',
    'chha@nrep.com': 'Christian Harder',
    'chho@nrep.com': 'Christian Højdevang',
    'chhs@nrep.com': 'Christian Schneekloth Hass',
    'chja@nrep.com': 'Christa Jantunen',
    'chje@nrep.com': 'Christian Lundhøj Jensen',
    'chjo@nrep.com': 'Christian Jølck',
    'chkn@nrep.com': 'Christoffer Knudsen',
    'chku@nrep.com': 'Christina Kühne',
    'chni@nrep.com': 'Christian Haaber Westergaard Nielsen',
    'chno@nrep.com': 'Christopher Nordberg',
    'chod@nrep.com': 'Christoffer Odin',
    'chol@nrep.com': 'Christina Ors Lykke',
    'chpa@nrep.com': 'Christoffer Paaske',
    'chrh@nrep.com': 'Christine Roos Holm',
    'chsk@nrep.com': 'Christian Nikolaj Skovfoged Tinggaard',
    'chst@nrep.com': 'Christian Stagetorn',
    'chsv@nrep.com': 'Charlotte Svalgaard',
    'chta@nrep.com': 'Christoffer Terp Andersen',
    'chth@nrep.com': 'Christer Skov Thorell',
    'chvo@nrep.com': 'Christian von der Recke',
    'chwa@nrep.com': 'Christina Warelius',
    'ciho@nrep.com': 'Cindie Kofoed Holm',
    'cjen@nrep.com': 'Carl Johan Enegren',
    'clbo@nrep.com': 'Claus Borkenfelt',
    'clel@nrep.com': 'Clive William Eley',
    'clge@nrep.com': 'Claes-Johan Geijer',
    'clla@nrep.com': 'Claudia Laroux',
    'clman@nrep.com': 'Claus Mathisen',
    'compliance@nrep.com': 'NREP Compliance',
    'computeradmin@nrep.com': 'ComputerAdmin',
    'Conf.RoomLUX-Meeting@nrep.com': 'Conf. Room LUX Meeting room (8 seats)',
    'Conf.RoomLUX-NREP@nrep.com': 'Conf. Room LUX Conference room (10 seats)',
    'confdedouglas@nrep.com': 'Conf. Room DE Frankfurt Douglas (Large)',
    'confdelibrary@nrep.com': 'Conf. Room DE Frankfurt Library (Small)',
    'confroomdkrooftop4@nrep.com':
        'Conf. Room DK Rooftop Brick (4 seats - Polycom)',
    'confroomdkrooftop5@nrep.com':
        'Conf. Room DK Rooftop Cement (8 seats - Polycom)',
    'confroomdkrooftop7@nrep.com':
        'Conf. Room DK Rooftop Marble (10 seats - Polycom)',
    'confroomdkrooftop6@nrep.com':
        'Conf. Room DK Rooftop Relaxation (2 seats - Relax Room)',
    'ConfRoomDKrooftop1@nrep.com':
        'Conf. Room DK Rooftop Lava (4 seats - Polycom)',
    'confroomdkrooftop3@nrep.com':
        'Conf. Room DK Rooftop Stone (4 seats - Polycom)',
    'conffibackstage@nrep.com':
        'Conf. Room FI NREP Floor 3 Backstage (4 seats – internal use)',
    'conffibox@nrep.com': 'Conf. Room FI NREP Floor 3 Box (4 seats – Polycom)',
    'confficabin@nrep.com':
        'Conf. Room FI Juli Floor 5 Cabin (6 seats – Polycom)',
    'confficave@nrep.com':
        'Conf. Room FI NREP Floor 3 Cave (4 seats – Polycom)',
    'confficlub@nrep.com': 'Conf. Room FI NREP Floor 3 Club (8 seats)',
    'conffiden@nrep.com':
        'Conf. Room FI NREP Floor 3 Den (4 seats – Internal use)',
    'conffifactory@nrep.com': 'Conf. Room FI NREP Floor 3 Factory (6 seats)',
    'conffifort@nrep.com':
        'Conf. Room FI NREP Floor 3 Fort (14 seats – Polycom)',
    'conffigarden@nrep.com':
        'Conf. Room FI NREP Floor 3 Garden (8seats – Polycom)',
    'conffihut@nrep.com': 'Conf. Room FI NREP Floor 3 Hut (2 seats – Internal)',
    'conffikivi@nrep.com':
        'Conf. Room FI Nelio Floor 7 Kivi (6 seats – Polycom)',
    'conffimansion@nrep.com':
        'Conf. Room FI Juli Floor 5 Mansion (10 seats – Polycom)',
    'conffipaperi@nrep.com':
        'Conf. Room FI Nelio Floor 7 Paperi (6 seats – Polycom)',
    'conffisakset@nrep.com':
        'Conf. Room FI Nelio Floor 7 Sakset (4 seats – Polycom)',
    'conffitree@nrep.com':
        'Conf. Room FI NREP Floor 3 Tree (4 seats – Polycom)',
    'conffivilla@nrep.com':
        'Conf. Room FI Juli Floor 5 Villa (4 seats – Polycom)',
    'confnodag@nrep.com': 'Conf. Room NO Dag (4 seats - Polycom)',
    'confnofokus@nrep.com': 'Conf. Room NO Fokus (3 seats)',
    'confnohav@nrep.com': 'Conf. Room NO Hav (8 seats - Polycom)',
    'confnonatt@nrep.com': 'Conf. Room NO Natt (6 seats - Polycom)',
    'confnooy@nrep.com': 'Conf. Room NO Øy (2 seats - Polycom)',
    'confnorelax@nrep.com': 'Conf. Room NO Relax',
    'confnovik@nrep.com': 'Conf. Room NO Vik (2 seats - Polycom)',
    'confroomdkaarhus@nrep.com': 'Conf. Room DK Aarhus Office',
    'ConfRoomDKKantinen@nrep.com': 'Conf. Room DK Riffel Canteen',
    'ConfRoomDKMikkel@nrep.com': 'Conf. Room DK Rooftop Mikkel (Polycom)',
    'confroomdkrolighedsvej@nrep.com': 'Conf. Room DK Rolighedsvej',
    'confroomdkrooftopgranite@nrep.com':
        'Conf. Room DK Rooftop Granite (8 seats - Polycom)',
    'confroomfriheden@nrep.com': 'Conf. Room DK Friheden',
    'ConfRoomSeKungsholmen@nrep.com': 'Conference Kungsholmen',
    'ConfRoomSERegeringsgatan01@nrep.com':
        'Conf. Room SE Floor 11 Rainforest (5 seats - external)',
    'ConfRoomSERegeringsgatan02@nrep.com':
        'Conf. Room SE Floor 11 Hill (2 seats – internal)',
    'ConfRoomSERegeringsgatan03@nrep.com':
        'Conf. Room SE Floor 11 Reef (3 seats – internal)',
    'ConfRoomSERegeringsgatan04@nrep.com':
        'Conf. Room SE Floor 11 Bay (8 seats – internal, Polycom)',
    'ConfRoomSERegeringsgatan05@nrep.com':
        'Conf. Room SE Floor 11 Desert (6 seats – Polycom - internal)',
    'ConfRoomSERegeringsgatan06@nrep.com':
        'Conf. Room SE Floor 11 Lagoon (4 seats – external)',
    'ConfRoomSERegeringsgatan07@nrep.com':
        'Conf. Room SE Floor 11 Meadow (13 seats – external, Polycom)',
    'ConfRoomSERegeringsgatan08@nrep.com':
        'Conf. Room SE Floor 11 Jungle (4 seats- external)',
    'ConfRoomSERegeringsgatan09@nrep.com':
        'Conf. Room SE Floor 11 Coast (8 seats – external, Polycom)',
    'ConfRoomSERegeringsgatan10@nrep.com':
        'Conf. Room SE Floor 11 Archipelago (4 seats – internal)',
    'confselogi@nrep.com': 'Conf. Room SE Gothenburg Logicenters',
    'confukbamboo@nrep.com': 'Conf. Room UK Bamboo (4 seats)',
    'confukcopper@nrep.com': 'Conf. Room UK Copper (10 seats)',
    'confukcotton@nrep.com': 'Conf. Room UK Cotton (4 seats)',
    'confukearth@nrep.com': 'Conf. Room UK Earth (4 seats)',
    'confukglass@nrep.com': 'Conf. Room UK Glass (8 seats)',
    'Corporatecalendar@nrep.com': 'Corporate Calendar',
    'corpsec@nrep.com': 'CorpSec',
    'coth@nrep.com': 'Colin Paul Throssell',
    'dabo@nrep.com': 'Daniel Bogale',
    'DAC6@nrep.com': 'DAC6',
    'daek@nrep.com': 'David Eklund',
    'dafl@nrep.com': 'David Oidermaa Flach',
    'dama@nrep.com': 'Daniel Reuter Mærsk',
    'dano@nrep.com': 'David Nordström',
    'daro@nrep.com': 'Daniel Rönnqvist',
    'dasa@nrep.com': 'Daniel Sannéus',
    'daso@nrep.com': 'David Solovej',
    'dast@nrep.com': 'Daniel Steenbuch',
    'deco@nrep.com': 'Denis Corluka',
    'deei@nrep.com': 'Denise Eibye',
    'dekr@nrep.com': 'Dennis Krogsgaard',
    'desm@nrep.com': 'Desirée Mogensen',
    'dmwk@nrep.com': 'Didde Maria Trachsel Kristensen',
    'docl@nrep.com': 'Dorte Clement',
    'domains@nrep.com': 'Domains',
    'dosi@nrep.com': 'Dorte Silberg',
    'dost@nrep.com': 'Dorotea Stellmach',
    'dw@nrep.com': 'NREP DatawareHouse',
    'e-Boks@nrep.com': 'e-Boks',
    'eamo@nrep.com': 'Egil-André Mortensen',
    'ecl@nrep.com': 'ECL',
    'eeme@nrep.com': 'Eero Mertsola',
    'eeva@nrep.com': 'Eevi Varmavuo',
    'elba@nrep.com': 'Elias Bayat',
    'elda@nrep.com': 'Elias Daugaard',
    'ELFR@nrep.com': 'Elisabeth Hermann Frederiksen',
    'elku@nrep.com': 'Ella Kuljukka',
    'elmo@nrep.com': 'Ella Moberg',
    'eloh@nrep.com': 'Elena Öhman',
    'eloj@nrep.com': 'Ella Ojanperä',
    'elol@nrep.com': 'Elisabeth L. Olsen',
    'elsa@nrep.com': 'Elias Salonen',
    'eltu@nrep.com': 'Elisa Tuomi',
    'elwal@nrep.com': 'Elin Wallón',
    'embe@nrep.com': 'Emanuele Bena',
    'embi@nrep.com': 'Emma Bille',
    'emda@nrep.com': 'Emelie Danielsson',
    'emge@nrep.com': 'Emma Gertman',
    'emja@nrep.com': 'Emil Jansson',
    'emse@nrep.com': 'Emmi Seppälä',
    'emso@nrep.com': 'Emilie Søndergaard',
    'emth@nrep.com': 'Emelie Thodén-Selin',
    'erah@nrep.com': 'Erik Ahl',
    'erfo@nrep.com': 'Erik Fogelström',
    'erfr@nrep.com': 'Erik Frank',
    'erha@nrep.com': 'Erika Hård',
    'erih@nrep.com': 'Erik Ihse',
    'erka@nrep.com': 'Erik Karlsson',
    'erko@nrep.com': 'Erik Bjerg Koggersbøl',
    'erla@nrep.com': 'Erik Lorentz Larsen',
    'erlu@nrep.com': 'Erik Lundmark',
    'ermo@nrep.com': 'Erika Moberg',
    'erri@nrep.com': 'Erik Ris',
    'erry@nrep.com': 'Erik Rystedt',
    'ertr@nrep.com': 'Erik Quan Peng Trang',
    'espe@nrep.com': 'Essi Penttinen',
    'esul@nrep.com': 'Esben Poulsen Ulvbjerg',
    'EventSE@nrep.com': 'NREP Event Sweden',
    'eventcalender@nrep.com': 'Event Calender (Shared)',
    'Events@nrep.com': 'NREP Events',
    'evsc@nrep.com': 'Evi Schmidhuber',
    'evst@nrep.com': 'Eva Sterner',
    'evtr@nrep.com': 'Eva Louise Troels Petersen',
    'eyke@nrep.com': 'Eyd Kentsdóttir Mohr Pedersen',
    'faab@nrep.com': 'Fabian Aron Binder',
    'fako@nrep.com': 'Fanny Koskinen',
    'fatca-crs@nrep.com': 'NREP Fatca-crs',
    'fbp@nrep.com': 'Finance Business Partner',
    'febe@nrep.com': 'Felicity Beasley',
    'feme@nrep.com': 'Felix Melin',
    'fepa@nrep.com': 'Felix Paljak',
    'fi.hr@nrep.com': 'Finland HR',
    'finance.denmark@nrep.com': 'Finance Denmark',
    'financeSE@nrep.com': 'Swedish finance',
    'fjell@nrep.com': 'Conf. Room NO Fjell (8 seats - Polycom)',
    'fjord@nrep.com': 'Conf. Room NO Fjord (12 seats - Polycom)',
    'frbj@nrep.com': 'Frederik Nisbeth Bjørnsen',
    'frbr@nrep.com': 'Frederik Sidelmann Borg',
    'frda@nrep.com': 'Frida Dahlberg',
    'frek@nrep.com': 'Frederik Emil Knudsen',
    'frfr@nrep.com': 'Frederik Nagel Fryland',
    'frha@nrep.com': 'Fredrik Häggbom',
    'frmj@nrep.com': 'Frederik Møller Jensen',
    'frni@nrep.com': 'Frederik Vonge Nielsen',
    'frny@nrep.com': 'Fredrik Nygren',
    'frra@nrep.com': 'Frans Ramberg',
    'frso@nrep.com': 'Frederik Søndberg',
    'frsy@nrep.com': 'Fredrik Sylven',
    'gakr@nrep.com': 'Gabriela Horacio Krabbenhøft',
    'gego@nrep.com': 'Gennadi Goussev',
    'gimu@nrep.com': 'Gitte Münster',
    'glge@nrep.com': 'Gloria George',
    'grgr@nrep.com': 'Gry Gronenberg',
    'gube@nrep.com': 'Gustav Bergquist',
    'gude@nrep.com': 'Gustav Detter',
    'GULI@nrep.com': 'Gustaf Lilliehook',
    'guol@nrep.com': 'Gustav Olofsson',
    'gusc@nrep.com': 'Gustav Fønss Schrøder',
    'guwa@nrep.com': 'Gustav Wallin',
    'hago@nrep.com': 'Hannes Gosch',
    'haha@nrep.com': 'Harald Haakonsen',
    'haii@nrep.com': 'Harri Iisakka',
    'hais@nrep.com': 'Hannes Isotalo',
    'haka@nrep.com': 'Hanna Kauppinen',
    'hakk@nrep.com': 'Hanna Kakavandi',
    'hala@nrep.com': 'Hanna Lauri',
    'haol@nrep.com': 'Hans Møllnitz Olsen',
    'hasw@nrep.com': 'Hanna Swietochowska',
    'hava@nrep.com': 'Hannes Väre',
    'hebd@nrep.com': 'Henriette Berg-Deleuran',
    'hehe@nrep.com': 'Henriette Hesselson',
    'hehu@nrep.com': 'Henrik Huotari',
    'hehy@nrep.com': 'Henri Hynynen',
    'HEKN@nrep.com': 'Henrik Knudsen',
    'hema@nrep.com': 'Heli Männistö',
    'hemu@nrep.com': 'Heather Mulahasani',
    'henh@nrep.com': 'Henri Hyvärinen',
    'hens@nrep.com': 'Henri Sipilä',
    'hepe@nrep.com': 'Henrik Gauhl Petersen',
    'hesi@nrep.com': 'Henri Simola',
    'hesk@nrep.com': 'Heidi Ø. Skovgaard',
    'hesp@nrep.com': 'Heidi Sperling',
    'holtemidtpunkt@nrep.com': 'HolteMidtPunkt',
    'hroperationsdk@nrep.com': 'hroperationsdk',
    'hrpy@nrep.com': 'Hanna-Riikka Pynnönen',
    'huma@nrep.com': 'Hugo Martinsson',
    'huzh@nrep.com': 'Huanhuan Zhang',
    'hyresgastvallingbyc@nrep.com': 'hyresgastvallingbyc',
    'ibca@nrep.com': 'Iben Frisk Carlsen',
    'idah@nrep.com': 'Ida Hasselbalch',
    'idha@nrep.com': 'Ida Hammerberg',
    'idwi@nrep.com': 'Ida Sofie Winding',
    'ilis@nrep.com': 'Ilona Issa',
    'ilka@nrep.com': 'Ilkka Kaakinen',
    'ILKS@nrep.com': 'Ilkka Salminen',
    'Info_Finland@nrep.com': 'Info_Finland',
    'infu@nrep.com': 'Ingerid Furuly',
    'ihna@nrep.com': 'Inga-Lill Hamrén',
    'internship_SWE@nrep.com': 'Internship_SWE',
    'internship2015@nrep.com': 'Internship2015',
    'investorcommunication@nrep.com': 'NREP Investor Communication',
    'iont@nrep.com': 'Ioannis Ntzouvaras',
    'IR-2150@nrep.com': 'Investor Relations 2150 Fund',
    'investorrelations@nrep.com': 'NREP Investor Relations',
    'isfu@nrep.com': 'Isa Fuhrmann',
    'isjo@nrep.com': 'Isabel Johansen',
    'isle@nrep.com': 'Isabella Lehto',
    'ispe@nrep.com': 'Ismail Peksen',
    'ISTDK@nrep.com': 'Investment Services Team DK (Not for tasks)',
    'itreports@nrep.com': 'itreports',
    'itse@nrep.com': 'IT Sweden prep',
    'itserviceaccounts@nrep.com': 'IT Shared mailbox for Service Accounts',
    'jaaa@nrep.com': 'Jannica Aalto',
    'jaai@nrep.com': 'Jarkko Äikää',
    'jaan@nrep.com': 'Jaana Niemi',
    'jach@nrep.com': 'Jacob Chemnitz',
    'jafa@nrep.com': 'Janine Fager-Jokisalo',
    'jafl@nrep.com': 'Jakob Flymer',
    'jaha@nrep.com': 'Janne Hakkarainen',
    'jahn@nrep.com': 'Janus Hanghøj',
    'jaho@nrep.com': 'Jaakko Holopainen',
    'jahu@nrep.com': 'Jannick Huseth',
    'jaju@nrep.com': 'Janina Jungell',
    'jakr@nrep.com': 'Jakob Kristjánsson',
    'jala@nrep.com': 'Jakob Laudrup',
    'jalax@nrep.com': 'James Lax',
    'jalt@nrep.com': 'Jacob Lykke Thomsen',
    'jaml@nrep.com': 'Jakob Møller',
    'jamo@nrep.com': 'Jakob Vedel Møller',
    'JANO@nrep.com': 'Jani Nokkanen',
    'janr@nrep.com': 'Janecke Nordby Rondan',
    'jaol@nrep.com': 'Jakob Olofsson',
    'japa@nrep.com': 'Jane Pan',
    'jaru@nrep.com': 'Janita Ruusuvaara',
    'jasa@nrep.com': 'Jacob Sandberg Gjems-Onstad',
    'jath@nrep.com': 'Jacob Koroma Thylstrup',
    'java@nrep.com': 'Jan Vapaavuori',
    'jawo@nrep.com': 'Jason Woods',
    'sentia@nrep.com': 'Sentia sentia',
    'jaytest27@nrep.com': 'jaytest27 Jaytest27',
    'jaytest32@nrep.com': 'Jaytest 32',
    'jebe@nrep.com': 'Jens Peter West Bertelsen',
    'jecn@nrep.com': 'Jens Christian Nielsen',
    'jeg@nrep.com': 'Jens Gottlieb',
    'jegn@nrep.com': 'Jesper Grauer Nielsen',
    'jegr@nrep.com': 'Jere Gröhn',
    'jegu@nrep.com': 'Jerker Gustafsson',
    'jeha@nrep.com': 'Jens Petter Hagen',
    'jehay@nrep.com': 'Jenna Häyrynen',
    'jeju@nrep.com': 'Jesse Juutinen',
    'jeka@nrep.com': 'Jesper Kaas Nielsen',
    'jekg@nrep.com': 'Jesper Kyhl Gudmann',
    'jekm@nrep.com': 'Jens Kramer Mikkelsen',
    'jepu@nrep.com': 'Jesse Pulli',
    'jesa@nrep.com': 'Jesse Shapins',
    'jesc@nrep.com': 'Jeppe Schack',
    'jesh@nrep.com': 'Jesper Hauch',
    'jest@nrep.com': 'Jens Stender',
    'jesw@nrep.com': 'Jesper Saugmann Wimmer',
    'jewi@nrep.com': 'Jesper Widfeldt',
    'jlau@nrep.com': 'Joonas Laurikainen',
    'jnie@nrep.com': 'Jonas Niebling',
    'joah@nrep.com': 'Jonathan Ahrens',
    'joal@nrep.com': 'Johannes Alanne',
    'joba@nrep.com': 'Jon Black Andersen',
    'jobah@nrep.com': 'Johanna Baastrup Henriksen',
    'jobc@nrep.com': 'Johan Bäcklund',
    'jobdk@nrep.com': 'jobdk',
    'jobe@nrep.com': 'Joanna Berg',
    'job@nrep.com': 'Job Nrep',
    'joch@nrep.com': 'Jonas Christensen',
    'jowo@nrep.com': 'Johanna Wollgård',
    'joha@nrep.com': 'Johan Håkansson',
    'johah@nrep.com': 'Johan Ahlstrand',
    'johl@nrep.com': 'Johanna Hollgren',
    'johr@nrep.com': 'Josephine Harrit',
    'JOKE@nrep.com': 'Jonas Kennerhed',
    'joku@nrep.com': 'Johanna Kull',
    'joky@nrep.com': 'Johannes Kyriakidis',
    'jola@nrep.com': 'Johanna Laine',
    'JOLE@nrep.com': 'Joonas Lemström',
    'joma@nrep.com': 'Jon Malmsten',
    'jomo@nrep.com': 'Josephine Møller',
    'jono@nrep.com': 'Jørgen Bråten Nordby',
    'jony@nrep.com': 'Johannes Nyman',
    'jora@nrep.com': 'Josephine C. Raffel',
    'jorm@nrep.com': 'Johan Hallgren Madsen',
    'jory@nrep.com': 'Johan Rylander',
    'josn@nrep.com': 'Jonas Snefjellå Løvås',
    'joti@nrep.com': 'John Tiab',
    'jova@nrep.com': 'Johanna Vahlund',
    'jovai@nrep.com': 'Jonas Väisänen',
    'jovi@nrep.com': 'Joonas Viitanen',
    'jowi@nrep.com': 'Josefin Winkler',
    'juaa@nrep.com': 'Juuso Aarni',
    'jual@nrep.com': 'Julius Alanne',
    'juch@nrep.com': 'Juntao Chen',
    'juha@nrep.com': 'Junehee Han',
    'juhe@nrep.com': 'Julius Hemmilä',
    'juja@nrep.com': 'Juhana Jäppilä',
    'juka@nrep.com': 'Juha Kaura',
    'juku@nrep.com': 'Juho Kuronen',
    'juma@nrep.com': 'Julia Manai-Queyras',
    'jutr@nrep.com': 'Julie Trangbæk',
    'juva@nrep.com': 'Juha-Matti Varjonen',
    'juvo@nrep.com': 'Julia Vogelweith',
    'kaan@nrep.com': 'Katarzyna Antoszewska',
    'kagr@nrep.com': 'Karol Grudziak',
    'kaha@nrep.com': 'Katelyn Halldorson',
    'kaml@nrep.com': 'Kathrine Louise Schøn Mollerup',
    'kamo@nrep.com': 'Kasper Mortensen',
    'kanu@nrep.com': 'Kalle Nurmos',
    'karik@nrep.com': 'Kati Rikala',
    'kaso@nrep.com': 'Kasper Juulsgaard Sørensen',
    'kath@nrep.com': 'Katrine Thorning',
    'kato@nrep.com': 'Karoline Tolsøe',
    'kave@nrep.com': 'Kasper Vejgaard',
    'kawa@nrep.com': 'Karl Wallén',
    'kayl@nrep.com': 'Karoliina Ylisaukko-oja',
    'kebi@nrep.com': 'Kenneth Bisgaard',
    'kelm@nrep.com': 'Kell Møller',
    'kema@nrep.com': 'Kenneth Marsner',
    'keni@nrep.com': 'Kent Juhl Nielsen',
    'kewi@nrep.com': 'Kennet Fredebo Wilken',
    'kipe@nrep.com': 'Kim Pedersen',
    'kjan@nrep.com': 'Kjartan Christensen Andersen',
    'klah@nrep.com': 'Klaus Ahm',
    'klbr@nrep.com': 'Klaus Brødsgaard',
    'klha@nrep.com': 'Klaus Gorm Hansen',
    'klje@nrep.com': 'Klaus Landberg Jensen',
    'klni@nrep.com': 'Kristian Lynnerup Nielsen',
    'knbe@nrep.com': 'Knut Benson',
    'kraa@nrep.com': 'Kristian Aarflot-Nyrud',
    'krhv@nrep.com': 'Kristina Hvitved',
    'krno@nrep.com': 'Kristoffer Nøhr',
    'krra@nrep.com': 'Kristoffer Rask',
    'krsa@nrep.com': 'Kristoffer Sandberg',
    'krst@nrep.com': 'Kristian Stelzig',
    'krth@nrep.com': 'Kristoffer Thygesen',
    'ksda@nrep.com': 'Ksenia Davydov-Jeppesen',
    'labo@nrep.com': 'Lars Bock',
    'lace@nrep.com': 'Lars Cederberg-Krohn',
    'LAHA@nrep.com': 'Lars Hansen',
    'lahe@nrep.com': 'Lærke Hein',
    'lahn@nrep.com': 'Lars Henriksen',
    'laho@nrep.com': 'Lars Kjøller Holm',
    'laja@nrep.com': 'Lauri Jaakkola',
    'LALY@nrep.com': 'Lars Lysdahl',
    'lamo@nrep.com': 'Laura Mörsky',
    'lano@nrep.com': 'Lars Nørrevang',
    'lasernettest@nrep.com': 'Lasernettest',
    'laug@nrep.com': 'Lauri Ganszauge',
    'lazo@nrep.com': 'Lasse Zobel',
    'leaseadmFI@nrep.com': 'leaseadmFI@nrep.com',
    'legal-students@nrep.com': 'legal-students',
    'legal@nrep.com': 'Legal',
    'lejo@nrep.com': 'Lena Lee Jørgensen',
    'leka@nrep.com': 'Lene Marie Kappel',
    'lekn@nrep.com': 'Leena Kankare',
    'lela@nrep.com': 'Lesley Lanefelt',
    'lich@nrep.com': 'Line Maria Christensen',
    'lifr@nrep.com': 'Linnéa Fristam',
    'liho@nrep.com': 'Lirion Hoxha',
    'likl@nrep.com': 'Lisa Kleist',
    'liko@nrep.com': 'Lilli Konttinen',
    'lipe@nrep.com': 'Lina Persson',
    'lite@nrep.com': 'Lina Persson Test',
    'loam@nrep.com': 'Louise Stabell Amdisen',
    'loba@nrep.com': 'Louise Panum Baastrup',
    'lobl@nrep.com': 'Louise Blom-Hansen',
    'lode@nrep.com': 'Louise De Geer',
    'lofa@nrep.com': 'Louise Fabricius-Franck',
    'lont@nrep.com': 'Loannis Ntzouvaras',
    'losc@nrep.com': 'Lotte Schultz',
    'LOSJ@nrep.com': 'Lotta Sjöberg',
    'losv@nrep.com': 'Louise Svensson',
    'luek@nrep.com': 'Lukas Ekman',
    'lula@nrep.com': 'Luka Lahtinen',
    'luly@nrep.com': 'Luka Lyhty',
    'luol@nrep.com': 'Lucas Klitgaard Olsen',
    'lusa@nrep.com': 'Luca Sára Nagy',
    'luxinvoicing@nrep.com': 'NREP Lux Invoices',
    'lyle@nrep.com': 'Lydia Leslie',
    'maaf@nrep.com': 'Mads Fjeldhoff',
    'maan@nrep.com': 'Maria Kaa Andersen',
    'mabe@nrep.com': 'Marianne Bech Rasmussen',
    'mabec@nrep.com': 'Mathias Elkjer Beck',
    'mabo@nrep.com': 'Marius Brøchner-Mortensen',
    'mabr@nrep.com': 'Martine Britz',
    'mach@nrep.com': 'Marcell Christensen',
    'mada@nrep.com': 'Mads Dalgaard Hansen',
    'Madj@nrep.com': 'Marie Dela Johnsen',
    'madu@nrep.com': 'Maggi du Toit',
    'mady@nrep.com': 'Malin Dybeck',
    'maee@nrep.com': 'Marte Ekere',
    'maek@nrep.com': 'Mats Eklund',
    'mafa@nrep.com': 'Marija Fadula',
    'mafi@nrep.com': 'Mattias Fischer',
    'mafo@nrep.com': 'Maja Forsner',
    'magr@nrep.com': 'Mads Greve',
    'maha@nrep.com': 'Mathilda Hansson',
    'mahe@nrep.com': 'Marcus Hedman',
    'mahi@nrep.com': 'Mads Hvidberg',
    'mahj@nrep.com': 'Marcus F. Hjort',
    'maho@nrep.com': 'Mark Houston',
    'mahof@nrep.com': 'Marianne Hoffman',
    'mahv@nrep.com': 'Marcus Holmgren Vigsø',
    'mais@nrep.com': 'Martin Isaksson',
    'maiv@nrep.com': 'Magnus Iversen',
    'maje@nrep.com': 'Malene Hvid Jensen',
    'majn@nrep.com': 'Magnus Hejbøl Jensen',
    'majo@nrep.com': 'Martine Elisabeth Stø Johansen',
    'maka@nrep.com': 'Matti Kaurola',
    'make@nrep.com': 'Matthias Kettelhoit',
    'makn@nrep.com': 'Marie Kjærgaard Knudsen',
    'makoo@nrep.com': 'Martijn Kooman',
    'makr@nrep.com': 'Marta Karteczka',
    'MAKY@nrep.com': 'Martta Kyllönen',
    'mala@nrep.com': 'Martina Lagerros',
    'malip@nrep.com': 'Marco Lippi',
    'malo@nrep.com': 'Malin Lorentzon',
    'malv@nrep.com': 'Marja Levy',
    'maly@nrep.com': 'Martin Preben Lyngbæk',
    'mama@nrep.com': 'Marko Määttä',
    'mamak@nrep.com': 'Matias Mäkelä',
    'mamc@nrep.com': 'Martin Michalski',
    'mame@nrep.com': 'Marcus Fischer Mellbin',
    'Mami@nrep.com': 'Matias Miettunen',
    'mamo@nrep.com': 'Mads Møgelvang Nielsen',
    'mamt@nrep.com': 'Maria Motta',
    'mana@nrep.com': 'Maja Natorff',
    'mane@nrep.com': 'Magnus Nelmark',
    'mani@nrep.com': 'Martin Nielsen',
    'many@nrep.com': 'Mads Nysten',
    'mape@nrep.com': 'Marianne Holst Pedersen',
    'mapet@nrep.com': 'Mark Petersen',
    'mapo@nrep.com': 'Martin Frederik Poulsen',
    'MARA@nrep.com': 'Mark Raine',
    'marl@nrep.com': 'Martin Lauridsen',
    'marm@nrep.com': 'Marie Møller',
    'marn@nrep.com': 'Maria Neovius',
    'maro@nrep.com': 'Markus Rönnberg',
    'mars@nrep.com': 'Mareike Sauerwein',
    'mase@nrep.com': 'Marcus Selléus',
    'masn@nrep.com': 'Martin Schultz-Nielsen',
    'maso@nrep.com': 'Mads Sønderby',
    'mass@nrep.com': 'Mathias Sachse Skrubbeltrang',
    'mast@nrep.com': 'Mariya Tsvetkova',
    'math@nrep.com': 'Mads Thorning',
    'mato@nrep.com': 'Marcus Tobiasson',
    'matr@nrep.com': 'Magdalena Terefenko',
    'mhul@nrep.com': 'Mads Holm Ullum',
    'mawi@nrep.com': 'Maibritt Tengberg Wittrock',
    'mbde@nrep.com': 'Mads Birkedahl Dehlbæk',
    'mcie@nrep.com': 'Michał Cieślikowski',
    'mdvb@nrep.com': 'Minette Donner von Bonsdorff',
    'meba@nrep.com': 'Mette Bach',
    'meku@nrep.com': 'Mette Borella Kuhlmann',
    'meno@nrep.com': 'Mette Nordmann',
    'mesj@nrep.com': 'Melissa Johansen',
    'mewo@nrep.com': 'Mette Westh Gotthardt',
    'mfatest@nrep.com': 'MFA Test',
    'mhil@nrep.com': 'Mikko Hiltunen',
    'MIAN@nrep.com': 'Michael Andersen',
    'mibe@nrep.com': 'Michael Bernfeld',
    'mico@nrep.com': 'Mikkel Hørlyk Cordtz',
    'mifa@nrep.com': 'Mikael Fastén',
    'mife@nrep.com': 'Mikkel Friis Estrup',
    'mifr@nrep.com': 'Michala Charlotte Frøslev',
    'mige@nrep.com': 'Milan Gerdesics',
    'miha@nrep.com': 'Mike Stensgård Holm Hasselgren',
    'MCHE@nrep.com': 'Michael Christian Helbo',
    'mikai@nrep.com': 'Mikko Kaisko',
    'mike@nrep.com': 'Milla Kettunen',
    'mikm@nrep.com': 'Mikael Komulainen',
    'miko@nrep.com': 'Michael Kongsbak',
    'mikr@nrep.com': 'Michael Krogh',
    'milb@nrep.com': 'Mie Lysemose Buch',
    'MILE@nrep.com': 'Mikkel Bülow-Lehnsby',
    'milem@nrep.com': 'Milja Lempinen',
    'milma@nrep.com': 'Milsa Malin',
    'mima@nrep.com': 'Miia Mäkinen',
    'miml@nrep.com': 'Michał Malinowski',
    'miol@nrep.com': 'Mikael Sahlin Olesen',
    'mipa@nrep.com': 'Mikael Pasila',
    'MIRA@nrep.com': 'Mikko Räsänen',
    'mirp@nrep.com': 'Mie Rasmussen Pelle (HR)',
    'mist@nrep.com': 'Michael Stroh',
    'mitr@nrep.com': 'Michael Troelsen',
    'miyr@nrep.com': 'Mikko Yrtti',
    'mnie@nrep.com': 'Markus Nieminen',
    'moaw@nrep.com': 'Mohamad Awad',
    'mobi@nrep.com': 'Morten Graven Bilde',
    'modp@nrep.com': 'Monica Due-Pedersen',
    'monf@nrep.com': 'Morten Norup Fassov',
    'moni@nrep.com': 'Morten Meincke Nielsen',
    'mopo@nrep.com': 'Morten Bøgedal Poulsen',
    'mosl@nrep.com': 'Mohamed Soliman',
    'moso@nrep.com': 'Morten Sønderskov',
    'mpio@nrep.com': 'Maciej Piotrowicz',
    'mrih@nrep.com': 'Mikael Rihto',
    'msah@nrep.com': 'Maria Sahlén',
    'mueg@nrep.com': 'Mutinta Mooya Eglund',
    'nada@nrep.com': 'Nanna Daugaard',
    'nama@nrep.com': 'Na Ma',
    'nani@nrep.com': 'Natalia Nikola',
    'naya@nrep.com': 'Nanna Yang-Sook Larsen',
    'nbso@nrep.com': 'Nicolai Bruhn Sørensen',
    'neho@nrep.com': 'Neal Scott Hollingsworth',
    'neva@nrep.com': 'Nea Vaalavirta',
    'niar@nrep.com': 'Nicolas Arppe',
    'nibg@nrep.com': 'Niklas Brøndt Glarkrog',
    'nica@nrep.com': 'Nikki Callum',
    'nied@nrep.com': 'Niels-Peter Edvardsen',
    'niej@nrep.com': 'Nicolai Vinding Juul Ejlersen',
    'nien@nrep.com': 'Niklas Engberg',
    'nies@nrep.com': 'Nicole Espelund',
    'nifa@nrep.com': 'Nicolai Ekström Falkenham',
    'niko@nrep.com': 'Nikolaj Ecksteen Kofoed',
    'nime@nrep.com': 'Nick Melgaard',
    'nipi@nrep.com': 'Nicklas Pihlmann',
    'niso@nrep.com': 'Niklas Sontag',
    'nith@nrep.com': 'Ninna Therkelsen',
    'nitu@nrep.com': 'Niklas Tuominen',
    'niwa@nrep.com': 'Nicky Wagner',
    'noch@nrep.com': 'Nicole Chia Ching Chuang',
    'NOparking1100@nrep.com': 'NO Parking Spot 1100 (el)',
    'NOparking1066@nrep.com': 'NO Parking Spot 1066',
    'NOparking1067@nrep.com': 'NO Parking Spot 1067',
    'NOparking1101@nrep.com': 'NO Parking Spot 1101 (el)',
    'NOParkingSpot6@nrep.com': 'NO Parking Spot 6 – Pecunia parking garage',
    'noreply@nrep.com': 'noreply',
    'nrep.coinvest@nrep.com': 'NREP Coinvest',
    'nrep@nrep.com': 'Nrep',
    'AnnualInvestorMeeting@nrep.com': 'NREP AIM',
    'NREP.external.accounting@nrep.com': 'NREP external accounting',
    'nrepfilounge@nrep.com': 'NREP FI Lounge',
    'nreplearningspace@nrep.com': 'NREP Learning Space',
    'NREPLuxCoSecTeamInvites@nrep.com': 'NREPLuxCoSecTeamInvites',
    'news@nrep.com': 'NREP News',
    'nsfvfundcontrolling@nrep.com': 'NSF V Fund Controlling',
    'olbe@nrep.com': 'Olle Berglund',
    'olfi@nrep.com': 'Olli Fischer',
    'olli@nrep.com': 'Olle Lignell',
    'olol@nrep.com': 'Olli Olkkonen',
    'Onboarding@nrep.com': 'Onboarding NREP',
    'onsitesweden@nrep.com': 'On-site Sweden',
    'onvi@nrep.com': 'Onni Vikström',
    'osbl@nrep.com': 'Oskar Block',
    'osfo@nrep.com': 'Oscar Foss',
    'oskutesti@nrep.com': 'Oskun Testi Boxi',
    'osma@nrep.com': 'Oscar Månsson',
    'osna@nrep.com': 'Oskar Natt och dag',
    'pali@nrep.com': 'Patrick Lilius',
    'pape@nrep.com': 'Päivi Pekkala',
    'patul@nrep.com': 'Pauliina Tulokas',
    'pava@nrep.com': 'Pål Valgermo',
    'payrollFI@nrep.com': 'payroll FI',
    'PayrollSE@nrep.com': 'PayrollSE',
    'PEAH@nrep.com': 'Petri Ahola',
    'peau@nrep.com': 'Petter Oscar Aunaas',
    'pebr@nrep.com': 'Pernille Bratved',
    'peho@nrep.com': 'Per Holm',
    'peli@nrep.com': 'Peter Lind',
    'perc@nrep.com': 'Peter Riber',
    'peri@nrep.com': 'Petrus Riikonen',
    'perr@nrep.com': 'Perttu Rönkkö',
    'peru@nrep.com': 'Pekka Ruokoniemi',
    'peso@nrep.com': 'Peter Søgaard',
    'pesu@nrep.com': 'Petteri Suonio',
    'pete@nrep.com': 'Pernilla Tellberger',
    'petj@nrep.com': 'Per Tjernström',
    'pets@nrep.com': 'Peter Stüven',
    'pval@nrep.com': 'Petri Valkama',
    'pewo@nrep.com': 'Peter Wohlstrand',
    'peze@nrep.com': 'Peter Zekovic',
    'phgu@nrep.com': 'Philip Gustafsson',
    'phno@nrep.com': 'Philip Norup Valery',
    'phte@nrep.com': 'Photo Test1',
    'phtes@nrep.com': 'Photo Test2',
    'phun@nrep.com': 'Philip Højberg Unger',
    'pibo@nrep.com': 'Piotr Boruc',
    'pmtoolbox@nrep.com': 'pmtoolbox',
    'Polycom-FI-Trio@nrep.com': 'Polycom-FI-Trio',
    'preciofishbone@nrep.com': 'Precio Fishbone ADMIN',
    'prep@nrep.com': 'PC PrepDK',
    'present@nrep.com': 'Presentation Summersummit',
    'Procurement@nrep.com': 'NREP Procurement Team',
    'prod_moje@nrep.com': 'prod_moje',
    'Q_AND_A@nrep.com': 'Q_AND_A',
    'qutr@nrep.com': 'Quyen Tran',
    'raan@nrep.com': 'Rasmus Andersen',
    'raca@nrep.com': 'Raquel Martins',
    'raga@nrep.com': 'Rasmus Jacques Henri Gaudin',
    'ragu@nrep.com': 'Radhika Gupta',
    'raje@nrep.com': 'Rasmus Lercke Jensen',
    'rakr@nrep.com': 'Rasmus Kring',
    'rame@nrep.com': 'Rasmus Meyhoff',
    'RANO@nrep.com': 'Rasmus Nørgaard',
    'raol@nrep.com': 'Rasmus Grosen Olsen',
    'rara@nrep.com': 'Rasmus Kristian Pejter Rasmussen',
    'raro@nrep.com': 'Rasmus Rønsholt',
    'rass@nrep.com': 'Rasmus Schiller Soelberg',
    'rath@nrep.com': 'Rasmus Willy Thoning',
    'rato@nrep.com': 'Rasmus Touborg',
    'ravi@nrep.com': 'Raminta Virzintaite',
    'rean@nrep.com': 'Renoy Antony',
    'reax@nrep.com': 'Rebecca Axelsson',
    'reception.stockholm@nrep.com': 'Reception Stockholm',
    'receptiondk@nrep.com': 'NREP Front Desk DK',
    'receptionfi@nrep.com': 'ReceptionFI',
    'redhousesocialcommittee@nrep.com': 'Red House Social Committee',
    'reka@nrep.com': 'Reni Kangas',
    'rekr@nrep.com': 'Rekruttering NREP',
    'rekrytointi@nrep.com': 'Rekrytointi Nordic Real Estate Partners',
    'remo@nrep.com': 'René Kastrup Mortensen',
    'reny@nrep.com': 'Renaity Nyarkoa',
    'repguest@nrep.com': 'guest reception',
    'reportingandanalytic@nrep.com': 'Reporting and analytic team',
    'reports@nrep.com': 'reports NREP',
    'reta@nrep.com': 'Rebekah Taxgaard',
    'Retreat2021@nrep.com': 'Retreat 2021',
    'revi@nrep.com': 'Receptions vikarie 2',
    'rickard.dahlberg@nrep.com': 'Rickard Svensson-Dahlberg',
    'ride@nrep.com': 'Rikke Ulslev Degn',
    'ridr@nrep.com': 'Richard Drougge',
    'rigl@nrep.com': 'Rie Creasy Glentemose',
    'riho@nrep.com': 'Risto Hollmén',
    'rila@nrep.com': 'Rickard Langerfors',
    'rime@nrep.com': 'Riku Meriläinen',
    'riny@nrep.com': 'Riko Nyberg',
    'rjny@nrep.com': 'Rasmus Juul-Nyholm',
    'ropu@nrep.com': 'Roosa Puro',
    'roth@nrep.com': 'Robin Thorup',
    'ruca@nrep.com': 'Ruben Carpens',
    'RUKO@nrep.com': 'Rune Kock',
    'rukr@nrep.com': 'Ruben Krantz Kringstad',
    'rult@nrep.com': 'Ruben Lathuy',
    'saar@nrep.com': 'Sara Arnesen',
    'saba@nrep.com': 'Samuel Bäcklund',
    'saha@nrep.com': 'Sara Hall',
    'saja@nrep.com': 'Samu Järvelä',
    'sakh@nrep.com': 'Sandra Kohl',
    'sako@nrep.com': 'Sanna Koponen',
    'sala@nrep.com': 'Saskia Lammi',
    'sama@nrep.com': 'Saana Markko',
    'samar@nrep.com': 'Sara Martti',
    'saml@nrep.com': 'Sami Leinonen',
    'sanr@nrep.com': 'Sanna Ruokonen',
    'sardiniamoments@nrep.com': 'Sardinia Moments',
    'sasu@nrep.com': 'Saana Suomela',
    'sats@nrep.com': 'Sarangua Tsogtoo',
    'savik@nrep.com': 'Sanna Vikström',
    'sawe@nrep.com': 'Sanna Weiss',
    'sawi@nrep.com': 'Sarah Maria Birch Wiingaard',
    'scanfromnrep@nrep.com': 'Scan from NREP',
    'scanomat@nrep.com': 'Scanomat',
    'scantomail@nrep.com': 'ScanToMail',
    'socialcommitteedenmark@nrep.com': 'Social Committee Denmark',
    'sean@nrep.com': 'Sebastian Andersen',
    'security@nrep.com': 'Security',
    'seha@nrep.com': 'Sebastian Behn Hansen',
    'sekj@nrep.com': 'Sebastian Kjær',
    'seminars@nrep.com': 'Seminars',
    'SERedovisning@nrep.com': 'SERedovisning',
    'Serviced.Living@nrep.com': 'Nelio Concepts Group Calendar',
    'sd@nrep.com': 'NREP Servicedesk',
    'sest@nrep.com': 'Sebastian Stockenhed',
    'seste@nrep.com': 'Sean Stenson',
    'seva@nrep.com': 'Sebastian Vallgårda',
    'sica@nrep.com': 'Simon Campbell',
    'sifl@nrep.com': 'Sille Nørgaard Flindt',
    'signatures@nrep.com': 'NREP Signatures',
    'signaturesFI@nrep.com': 'signaturesFI',
    'sigr@nrep.com': 'Sidsel Green',
    'siha@nrep.com': 'Simon Hansen',
    'sihm@nrep.com': 'Sivert Christoffer Hammer',
    'sihp@nrep.com': 'Simon Hasselberg Pedersen',
    'sika@nrep.com': 'Simon Nykjær Kastberg',
    'sila@nrep.com': 'Simone Lambert',
    'siljangaderapportering@nrep.com': 'Siljangade Rapportering',
    'sima@nrep.com': 'Simon Daugbjerg Madsen',
    'sive@nrep.com': 'Sisse Veje',
    'Sjbs@nrep.com': 'Stian Juul Bøe Sørensen',
    'soba@nrep.com': 'Sofie Balslev-Olesen',
    'sobh@nrep.com': 'Sofia Bredberg Hanser',
    'socialcommitteefinland@nrep.com': 'Social Committee Finland',
    'sohe@nrep.com': 'Sofia Hernesniemi Knudsen',
    'sohi@nrep.com': 'Sonja Hirvonen',
    'soja@nrep.com': 'Sofie Jansson',
    'soje@nrep.com': 'Sofie Jensen',
    'soke@nrep.com': 'Sorja Keto',
    'soly@nrep.com': 'Søren Lykke',
    'soso@nrep.com': 'Sofie Sommer',
    'staldhusene@nrep.com': 'Staldhusene',
    'stjo@nrep.com': 'Steen Jønsson',
    'stpr@nrep.com': 'Stefan Prucha',
    'STSO@nrep.com': 'Steen Sønderby',
    'stsw@nrep.com': 'Stine Seneberg Winkel',
    'studentdi@nrep.com': 'studentdi',
    'stva@nrep.com': 'Stine Valeur Brøndum Bøegh',
    'stwa@nrep.com': 'Stefan Wallander',
    'suil@nrep.com': 'Sune Ingemann Larsen',
    'survey@nrep.com': 'survey',
    'susc@nrep.com': 'Susse Schaumann',
    'svc_adaptive_idp@nrep.com': 'svc_adaptive_idp',
    'svc_d365fo@nrep.com': 'D365FO Information do not reply',
    'svc_MFA_admin@nrep.com': 'svc_MFA_admin for RDS',
    'svc_NetWrix_Auditor@nrep.com': 'svc_NetWrix_Auditor',
    'svc_polycomteams@nrep.com': 'svc_PolycomTeams',
    'svc_PowerAutomateFlows@nrep.com': 'svc_ PowerAutomateFlows',
    'svc_sharepointaccess@nrep.com': 'Sharepoint Access',
    'timextender@nrep.com': 'svc_TimeXtender',
    'svkh@nrep.com': 'Sven-Åke Khezri Kraim',
    'sweden_et@nrep.com': 'sweden et',
    'tahi@nrep.com': 'Tapio Hietanen',
    'tama@nrep.com': 'Tanja Malinen',
    'TAVU@nrep.com': 'Tanja Vucetic',
    'taxFI@nrep.com': 'taxFI',
    'tele@nrep.com': 'Teemu Leskinen',
    'telehuset@nrep.com': 'Telehuset User',
    'Temporary_JEKG@nrep.com': 'Temporary_JEKG',
    'test_moje@nrep.com': 'test_moje',
    'testtesttest@nrep.com': 'test test test test',
    'thbo@nrep.com': 'Thomas Bonde',
    'thbu@nrep.com': 'Thomas Buddén',
    'thel@nrep.com': 'Theo Lauriette',
    'thgr@nrep.com': 'Thomas Wenzell Gram-Olesen',
    'thhe@nrep.com': 'Thomas Heegaard Jensen',
    'thja@nrep.com': 'Thomas Riise-Jakobsen',
    'thkr@nrep.com': 'Thure Krarup',
    'thla@nrep.com': 'Thomas Lauge Lybecker Larsen',
    'thpe@nrep.com': 'Thomas Perslund',
    'thpet@nrep.com': 'Thomas Petersen',
    'thre@nrep.com': 'Thyge Ryom Enggaard',
    'thso@nrep.com': 'Thomas Solem',
    'thwi@nrep.com': 'Thomas Vulff Wilhelmsen',
    'TIAN@nrep.com': 'Tina Brix Andersen',
    'Tiet@nrep.com': 'Timo Etelämäki',
    'tihu@nrep.com': 'Timo-Pekka Huhtinen',
    'tiko@nrep.com': 'Tia Koisti',
    'tilo@nrep.com': 'Tina Lodahl',
    'tima@nrep.com': 'Tine Skov Madsen',
    'timesheet.denmark@nrep.com': 'timesheet denmark',
    'tingbjerg@nrep.com': 'Tingbjerg Team',
    'tina.lorenzen@nrep.com': 'Tina Lorenzen',
    'TISA@nrep.com': 'Timur Saadetdin',
    'tism@nrep.com': 'Tim Stolbin Madsen',
    'tivu@nrep.com': 'Tiia Vuolle',
    'tobi@nrep.com': 'Tobias Løvkvist Bidstrup',
    'tocl@nrep.com': 'Toke Sundenæs Clausen',
    'togo@nrep.com': 'Tobias Gotfredsen',
    'tohi@nrep.com': 'Tomi Hiirsalmi',
    'tohu@nrep.com': 'Tommi Huoponen',
    'toma@nrep.com': 'Tom Martonen',
    'transactions.se@nrep.com': 'Transactions SE',
    'treasury@nrep.com': 'Treasury',
    'trmj@nrep.com': 'Trine Mindedahl Jespersen',
    'tuso@nrep.com': 'Turid Ania Sørensen',
    'tyro@nrep.com': 'Tytti Roos',
    'ulzu@nrep.com': 'Ula Zubkeviciute',
    'urmi@nrep.com': 'Uriah Oschlag-Michael',
    'Vacation@nrep.com': 'Vacation',
    'vaha@nrep.com': 'Valdemar Liesk Hansen',
    'valu@nrep.com': 'Valtteri Luoma',
    'vare@nrep.com': 'Valentína Remeková',
    'vean@nrep.com': 'Vebjørn Antonio Solstad',
    'veka@nrep.com': 'Veera Kampman',
    'vele@nrep.com': 'Verna Leskinen',
    'Vendor.invoice.FI@nrep.com': 'Vendor Invoice FI',
    'Vendor.invoice.LU@nrep.com': 'Vendor Invoice LU',
    'Vendor.invoice.NO@nrep.com': 'Vendor Invoice NO',
    'Vendor.invoice.SE@nrep.com': 'Vendor Invoice SE',
    'Vendor.invoice.DK@nrep.com': 'Vendor Invoice DK',
    'veth@nrep.com': 'Vedant Thakkar',
    'veto@nrep.com': 'Veronica Törnbom',
    'vewa@nrep.com': 'Veera Wasenius',
    'vigy@nrep.com': 'Viktor Gyllenhammar',
    'viho@nrep.com': 'Victor Holmqvist',
    'viit@nrep.com': 'Viivi Tuhkala',
    'vika@nrep.com': 'Viktor Karlsson',
    'viod@nrep.com': 'Victoria Ödmark',
    'vipa@nrep.com': 'Vincent Pang',
    'visc@nrep.com': 'Vibeke Schmidt',
    'vivl@nrep.com': 'Viktor Valkeapää',
    'waro@nrep.com': 'Waltteri Rossi',
    'wecz@nrep.com': 'Wera Czernek',
    'welcomeredhouse@nrep.com': 'Welcome RedHouse',
    'xt_aada@nrep.com': 'Åsa Davidsson',
    'xt_aaho@nrep.com': 'Aapo Horsma',
    'xt_abal@nrep.com': 'Abdulkafi Al-Imam',
    'xt_abna@nrep.com': 'Abhijit Nath',
    'xt_acti@nrep.com': 'Achraf Tikhlal',
    'xt_adgh@nrep.com': 'Adrien Ghamo',
    'xt_agcy@nrep.com': 'Agnieszka Cygan',
    'xt_agda@nrep.com': 'Agnieszka Danielak',
    'xt_agdi@nrep.com': 'Agris Diure',
    'xt_agno@nrep.com': 'Agne Noreikaite',
    'xt_aipd@nrep.com': 'Aiste Pdvilikyte',
    'xt_ajdo@nrep.com': 'Ajay Christy Dominic',
    'xt_ajpe@nrep.com': 'Ajesh B Peruparambil',
    'xt_akba@nrep.com': 'Akvile Barkute',
    'xt_akjo@nrep.com': 'Åke Johannesson',
    'xt_akpe@nrep.com': 'Akseli Peltoniemi',
    'xt_albi@nrep.com': 'Alicjia Bielak',
    'xt_albj@nrep.com': 'Alexander Bjerregaard',
    'xt_alma@nrep.com': 'Aleksandra Maslovskaya',
    'xt_alme@nrep.com': 'Albin Mellgren',
    'xt_alpl@nrep.com': 'Alicja Ploskon',
    'xt_alsi@nrep.com': 'Alec Sirelius',
    'xt_alva@nrep.com': 'Alex Valma',
    'xt_amda@nrep.com': 'Amit Kumar Das',
    'xt_amni@nrep.com': 'Amanda Nilsson',
    'xt_amri@nrep.com': 'Amanda Richter',
    'xt_amum@nrep.com': 'Amager Umeus',
    'xt_anad@nrep.com': 'Annija Admidina',
    'xt_anag@nrep.com': 'Ankita Agarwal',
    'xt_anbf@nrep.com': 'Anja Borreskov Frandsen',
    'xt_anbo@nrep.com': 'Anders Borgel',
    'xt_anda@nrep.com': 'Anniina Dahlman',
    'xt_andh@nrep.com': 'Anders Gøttrup Hansen',
    'xt_andi@nrep.com': 'Anders Dissing Test',
    'xt_anfi@nrep.com': 'Andreas Fisker',
    'xt_anha@nrep.com': 'Anna Haapala',
    'xt_anhj@nrep.com': 'Anders Hjalmarsson',
    'xt_anjr@nrep.com': 'Annett Jørgensen',
    'xt_ankn@nrep.com': 'Anne Balsby Knudsen',
    'xt_ankr@nrep.com': 'Angelique Krebs',
    'xt_anln@nrep.com': 'Anton Lindfors',
    'xt_anlp@nrep.com': 'Anni Lipitsä-Pentikäinen',
    'xt_anma@nrep.com': 'Anitha Manimaran',
    'xt_annf@nrep.com': 'Ann Frederiksen',
    'anni@nrep.com': 'Andreas Nisser',
    'xt_anpi@nrep.com': 'Anu Pitkänen',
    'xt_anpu@nrep.com': 'Anttoni Puntala',
    'xt_anra@nrep.com': 'Anne V. Rasmussen',
    'xt_anrs@nrep.com': 'Anders Rasmussen',
    'ansi@nrep.com': 'Andreas Axel Sindberg',
    'xt_ansn@nrep.com': 'Anna Maria Sundlöf',
    'ansu@nrep.com': 'Anders Sundin',
    'xt_ansv@nrep.com': 'Andreas Svensson',
    'xt_anth@nrep.com': 'Ananias Theodoridis',
    'xt_anti@nrep.com': 'Annika Tigersted',
    'xt_antu@nrep.com': 'Annastina Tuovinen',
    'xt_anvah@nrep.com': 'Antti Vähäsarja',
    'xt_anvu@nrep.com': 'Anne Vuorenpää',
    'xt_apmu@nrep.com': 'Apoorva Dhaval Mulay',
    'XT_arga@nrep.com': 'Arkadiusz Gargas',
    'xt_arja@nrep.com': 'Arindam Jaiswal',
    'xt_armv@nrep.com': 'Arthur Mvele',
    'xt_arph@nrep.com': 'Arun V. Philip',
    'xt_arsp@nrep.com': 'Arta Spilberga',
    'xt_arte@nrep.com': 'Armen Ter-Minasyan',
    'xt_asan@nrep.com': 'Åsa Andersson',
    'xt_aval@nrep.com': 'Anders Valentinsen',
    'xt_baaz@nrep.com': 'Babak Azmoudeh',
    'xt_bawa@nrep.com': 'Bastian Wagner',
    'xt_bbte@nrep.com': 'BBH external test user',
    'xt_bebe@nrep.com': 'Beata Wojciechowska',
    'xt_bebo@nrep.com': 'Benjamin Borchorst',
    'xt_beca@nrep.com': 'Bekhan Casson',
    'xt_bese@nrep.com': 'Benedicte Rønbøg Secher',
    'xt_besi@nrep.com': 'Beate Silina',
    'xt_betr@nrep.com': 'Beate Trama',
    'xt_bhad@nrep.com': 'Bhavya D',
    'xt_bhas@nrep.com': 'Bharath s',
    'xt_bhba@nrep.com': 'Bhaskar Banerjee',
    'xt_bhku@nrep.com': 'Bhagawath Kunigal Venkataram',
    'xt_bisl@nrep.com': 'Birgitte Sloth Jacobsen',
    'xt_bjpa@nrep.com': 'Bjørn Harald Paulsen',
    'xt_boba@nrep.com': 'Bozena Batkowska',
    'xt_boch@nrep.com': 'Bo Christiansen',
    'xt_brad@nrep.com': 'Brenda Adhiambo',
    'xt_brev@nrep.com': 'Brian Evje',
    'xt_caka@nrep.com': 'Canan Karatas',
    'xt_calo@nrep.com': 'Carl Lohmander',
    'xt_cape@nrep.com': 'Caroline Peters',
    'xt_casa@nrep.com': 'Caroline Bøegh Salomonsen',
    'xt_cate@nrep.com': 'Carl-Magnus Tegner',
    'xt_cath@nrep.com': 'Catharina Thürmer',
    'xt_cavi@nrep.com': 'Carina Vinsløv',
    'xt_cawh@nrep.com': 'Catherine White',
    'xt_cefe@nrep.com': 'Cecilie Feldt',
    'xt_chan@nrep.com': 'Christian Maar Andersen',
    'xt_chas@nrep.com': 'Chandana S',
    'xt_chbe@nrep.com': 'Christian Be Aben',
    'xt_chde@nrep.com': 'Charlotte Persson Delus',
    'xt_chja@nrep.com': 'Christoffer Jagd',
    'xt_chni@nrep.com': 'Christoffer Nielsen',
    'xt_chrs@nrep.com': 'Christian Steffensen',
    'xt_chst@nrep.com': 'Charlotte Stavehauge',
    'xt_civa@nrep.com': 'Cindy Van Buggenhout',
    'xt_cote@nrep.com': 'Cobblestone External Test User',
    'xt_cyro@nrep.com': 'Cynthia Robberechts',
    'xt_daba@nrep.com': 'Danil Baksheev',
    'xt_daca@nrep.com': 'David Carnvik',
    'dace@nrep.com': 'Daniel Cedås',
    'xt_dacr@nrep.com': 'David G. Carlsson',
    'xt_dafi@nrep.com': 'David Fisher',
    'xt_dale@nrep.com': 'Dan Leonhardt',
    'xt_dano@nrep.com': 'David Norman',
    'xt_dapu@nrep.com': 'Daniel Pustulka',
    'xt_depa@nrep.com': 'Deepak Kumar Pathak',
    'xt_dera@nrep.com': 'Devendra Rathod',
    'deso@nrep.com': 'Dennis Söderlund',
    'xt_desv@nrep.com': 'Desha Svenneborg',
    'xt_didi@nrep.com': 'Divya Divya',
    'xt_didz@nrep.com': 'Diana Dzene',
    'xt_dijo@nrep.com': 'Diana Johns',
    'xt_dimu@nrep.com': 'Dibyendu Mukherjee',
    'xt_divi@nrep.com': 'Dileep Vijayan',
    'xt_divp@nrep.com': 'Divakara P',
    'xt_doad@nrep.com': 'Dovile Adomaityte',
    'xt_dorpe@nrep.com': 'Dorthe Pedersen',
    'xt_doza@nrep.com': 'Dovile Zaliecke',
    'xt_dozd@nrep.com': 'Dorota Zadlo',
    'xt_duus@nrep.com': 'dubex user',
    'xt_edda@nrep.com': 'Eduard Dani',
    'xt_eemi@nrep.com': 'Eetu Mikkonen',
    'xt_ejej@nrep.com': 'Ejaj Aslam',
    'xt_elhu@nrep.com': 'Elias Hurskainen',
    'xt_elma@nrep.com': 'Elina Marttala',
    'xt_elna@nrep.com': 'Elisabeta Neagu',
    'xt_elpo@nrep.com': 'Elof Port',
    'xt_elso@nrep.com': 'Ella Soininen',
    'xt_elst@nrep.com': 'Ella Stenroos',
    'xt_emha@nrep.com': 'Emil Løth Hansen',
    'xt_emhe@nrep.com': 'Emma Hellberg',
    'emki@nrep.com': 'Emelie Kilpelä Lindstrand',
    'xt_erha@nrep.com': 'Erica Halvarsson',
    'erhi@nrep.com': 'Erlendur Hilmarsson',
    'xt_esti@nrep.com': 'Esa Tirkkonen',
    'xt_evgr@nrep.com': 'Evelina Gredzena',
    'xt_evka@nrep.com': 'Eveliina Kaunisto',
    'xt_evlu@nrep.com': 'Evelina Lukjanec',
    'xt_evre@nrep.com': 'Eva Remay',
    'xt_evrk@nrep.com': 'Evgeniia Rekhlova',
    'xt_ewbe@nrep.com': 'Ewelina Bednarczyk',
    'xt_ewgr@nrep.com': 'Ewa Gruszecka',
    'xt_feno@nrep.com': 'Felicia Nørgaard',
    'xt_feok@nrep.com': 'Ferit Ok',
    'xt_fimo@nrep.com': 'Filip Molin',
    'xt_fris@nrep.com': 'Frida Stender',
    'xt_frpe@nrep.com': 'Fredrik Pettersen',
    'xt_frst@nrep.com': 'Fredrik Stenvinkel',
    'xt_gime@nrep.com': 'Giuliana Medici',
    'xt_gimn@nrep.com': 'Giorgia Meneguzzo',
    'xt_giri@nrep.com': 'Gitte Rindbøl',
    'xt_gojo@nrep.com': 'Göran Johansson',
    'xt_gusj@nrep.com': 'Gustav Sjalin',
    'xt_gust@nrep.com': 'Guste Statulevičiūtė',
    'xt_hahi@nrep.com': 'Halldor Hilmarsson',
    'xt_hajo@nrep.com': 'Hanna Jokitie',
    'xt_hapa@nrep.com': 'Hanna Paulamäki',
    'xt_hata@nrep.com': 'Hanna Talvinen',
    'xt_hato@nrep.com': 'Hanna Tolke',
    'xt_hedi@nrep.com': 'Hendrik Dijkstra',
    'xt_hedv@nrep.com': 'Henriette Davidsen',
    'xt_heen@nrep.com': 'Henrik Engsner',
    'xt_hene@nrep.com': 'Heidi Neulanen',
    'xt_heno@nrep.com': 'Henrik Nordlöf',
    'xt_heny@nrep.com': 'Henrik Nyrhinen',
    'xt_heol@nrep.com': 'Hege Olsen',
    'xt_hepr@nrep.com': 'Heidi V. Priskorn',
    'xt_hera@nrep.com': 'Heli Rantakallio',
    'xt_heva@nrep.com': 'Henrikas Vasiliauskas',
    'xt_hoph@nrep.com': 'Hoang Phan',
    'xt_idgr@nrep.com': 'Ida Grek',
    'xt_idka@nrep.com': 'Ida Katajarinne',
    'xt_idsu@nrep.com': 'Ida Sulasalmi',
    'xt_iege@nrep.com': 'Ieva Germane',
    'xt_iegr@nrep.com': 'Ieva Grava',
    'xt_igge@nrep.com': 'Ignas Gedvilas',
    'xt_ilbu@nrep.com': 'Illona Burchard',
    'xt_iljk@nrep.com': 'Ilona Järventaus-Kiiski',
    'xt_ilju@nrep.com': 'Ilona Jussila',
    'xt_isit@nrep.com': 'Isabella Itkonen',
    'xt_iwma@nrep.com': 'Iwona Matras',
    'xt_izro@nrep.com': 'Izabela Rozek',
    'xt_ja29@nrep.com': 'Jaytest29',
    'xt_ja33@nrep.com': 'Jaytest 33',
    'xt_ja34@nrep.com': 'Jaytest 34',
    'xt_ja35@nrep.com': 'Jaytest 35',
    'xt_ja39@nrep.com': 'Jaytest 39',
    'xt_jaan@nrep.com': 'Javier Pastor Angulo',
    'xt_jabr@nrep.com': 'Jacob Bro',
    'xt_jaca@nrep.com': 'Jackson A',
    'xt_jacl@nrep.com': 'Jacob Lorentzen',
    'xt_jaey@nrep.com': 'Jaytest EY',
    'xt_jagu@nrep.com': 'Jaya Prakas Gummagattu',
    'xt_jahn@nrep.com': 'James Henderson',
    'xt_jake@nrep.com': 'Jacob Kennerhed',
    'xt_jale@nrep.com': 'Jan Levin',
    'xt_jamy@nrep.com': 'Jaakko Myllymäki',
    'xt_japa@nrep.com': 'Janaani Parthasarthy',
    'xt_jara@nrep.com': 'Jannick Raunow',
    'xt_jark@nrep.com': 'Jacek Rokosz',
    'xt_jasc@nrep.com': 'Jacob Schramm',
    'xt_jast@nrep.com': 'Jacob Stein Andersen',
    'xt_jata@nrep.com': 'Jalal Tariq',
    'xt_jath@nrep.com': 'Jacob Lykke Thomsen',
    'xt_java@nrep.com': 'Jarkko Vartiainen',
    'xt_jawa@nrep.com': 'Jakub Wasyl',
    'xt_jehe@nrep.com': 'Jenna Helander',
    'xt_jeja@nrep.com': 'Jens Jansson',
    'xt_jepe@nrep.com': 'Jesper Persson',
    'xt_jepr@nrep.com': 'Jeevan Prakash',
    'xt_jerm@nrep.com': 'Jenny Rammo',
    'xt_jers@nrep.com': 'Jesper Smith Rasmussen',
    'xt_jesm@nrep.com': 'Jenna Siimesjärvi',
    'xt_jeso@nrep.com': 'Jesper Sørensen',
    'xt_jest@nrep.com': 'Jesper Stening',
    'xt_jeto@nrep.com': 'Jesper Toft',
    'xt_jeve@nrep.com': 'Jenna Vehka-aho',
    'jima@nrep.com': 'Jim Martin',
    'xt_jiok@nrep.com': 'Jimmi Omø Kristensen',
    'xt_jipe@nrep.com': 'Jimmy Pekkala',
    'xt_joas@nrep.com': 'Johan Asklund',
    'xt_jobh@nrep.com': 'Jonas Bladt Hansen',
    'xt_jobi@nrep.com': 'Johan Broberg Binder',
    'xt_joda@nrep.com': 'Joanna Danda',
    'xt_jodj@nrep.com': 'Johan Djurberg',
    'xt_joei@nrep.com': 'Johnny Eike',
    'xt_joho@nrep.com': 'Jørgen Høholt',
    'xt_johp@nrep.com': 'Jonas Hagh Petersen',
    'johy@nrep.com': 'Joon Hyon',
    'xt_joka@nrep.com': 'Jonas O Kaysen',
    'xt_joko@nrep.com': 'Jonathan Korte',
    'xt_jope@nrep.com': 'Jonathan Søgaard Petersen',
    'xt_jopt@nrep.com': 'Joel Pettersson',
    'xt_jory@nrep.com': 'Johan Ryden',
    'xt_josa@nrep.com': 'Joonas Sammalinen',
    'xt_jova@nrep.com': 'Johannes Vallgårda',
    'xt_jowu@nrep.com': 'Johanna Wunsch',
    'xt_juje@nrep.com': 'Julie Stubkjær Jegstrup',
    'xt_jujl@nrep.com': 'Julianna Jalovaara',
    'xt_jujr@nrep.com': 'Julia Jordan',
    'xt_juni@nrep.com': 'Julia Nilsson',
    'xt_jupa@nrep.com': 'Juho Parantainen',
    'xt_juto@nrep.com': 'Julia Toppi',
    'xt_juvi@nrep.com': 'Justinas Visockas',
    'xt_kaan@nrep.com': 'Kasper Strømme Andersen',
    'xt_kach@nrep.com': 'Kasper Christensen',
    'xt_kads@nrep.com': 'Kannan Das',
    'xt_kaga@nrep.com': 'Kai Gamre',
    'xt_kahm@nrep.com': 'Karishma Harihar Manohar',
    'xt_kahy@nrep.com': 'Katri Hyvönen',
    'xt_kakr@nrep.com': 'Katrine Kristensen',
    'xt_kaku@nrep.com': 'Katarzyna Kus',
    'xt_kamo@nrep.com': 'Karen Mosbech',
    'xt_kapd@nrep.com': 'Karoline Pedersen',
    'xt_kape@nrep.com': 'Kasper A. Pedersen',
    'xt_kapi@nrep.com': 'Kanstantsin Piatrouski',
    'xt_kasa@nrep.com': 'Kanduri Sai Krishna',
    'xt_kase@nrep.com': 'Kamilė Sedlikovska',
    'xt_kasu@nrep.com': 'Kavita Surana',
    'xt_kawo@nrep.com': 'Karolina Wojcikiewicz',
    'xt_kawy@nrep.com': 'Kamila Wyszynska-Ciampoli',
    'xt_kaya@nrep.com': 'Karthik Yadalam',
    'xt_keil@nrep.com': 'Kerttu Ilmonen',
    'xt_keje@nrep.com': 'Kenni Svanholm Jensen',
    'xt_kema@nrep.com': 'Kenneth Madsen',
    'xt_kibh@nrep.com': 'Kinga Bherwani',
    'xt_khso@nrep.com': 'Kim Søndergaard',
    'kjbe@nrep.com': 'Kjersti Berge',
    'xt_kjeu@nrep.com': 'Kjell Euren',
    'xt_klsz@nrep.com': 'Klaudia Szymanska',
    'xt_kocs@nrep.com': 'Kornelia Csikos',
    'xt_koku@nrep.com': 'Kornelia Kucharska',
    'xt_koma@nrep.com': 'Konrad Mazurkiewicz',
    'xt_krjo@nrep.com': 'Krister Johansson',
    'xt_krsi@nrep.com': 'Krista K Simanovica',
    'xt_krva@nrep.com': 'Krisztina Vágó',
    'xt_laal@nrep.com': 'Lara Alazawi',
    'xt_labr@nrep.com': 'Lars Brandt',
    'xt_lach@nrep.com': 'Lasse  Stächer Christensen',
    'xt_lada@nrep.com': 'Lasse Dam',
    'xt_ladi@nrep.com': 'Laisve Diciunaite',
    'xt_lago@nrep.com': 'Lauma Gobina',
    'lahj@nrep.com': 'Lars Hjorth',
    'xt_laju@nrep.com': 'Laura Juknyte',
    'xt_laka@nrep.com': 'Laura Karhu',
    'xt_lako@nrep.com': 'Laura Koivisto',
    'xt_lami@nrep.com': 'Laura Mikkola',
    'xt_lamu@nrep.com': 'Lasse Højgaard Munck',
    'xt_lara@nrep.com': 'Laura Ramula',
    'xt_laro@nrep.com': 'Lasse Ronnenberg',
    'xt_late@nrep.com': 'Langham Hall test user',
    'xt_lati@nrep.com': 'Laura Tiensuu',
    'xt_latr@nrep.com': 'Lars Hansen Training user',
    'xt_ledau@nrep.com': 'Leo Dauguet',
    'xt_lele@nrep.com': 'Léna Le Gal',
    'xt_lemo@nrep.com': 'Lene Mørch',
    'xt_lepe@nrep.com': 'Lena Dalsbo Petersen',
    'xt_lian@nrep.com': 'Linda Ansala',
    'xt_lija@nrep.com': 'Linda Jankowska',
    'xt_lila@nrep.com': 'Liga Laizane',
    'xt_lipr@nrep.com': 'Liga Purmale',
    'xt_loni@nrep.com': 'Louise Åberg Nilsson',
    'lool@nrep.com': 'Louise Ollivier',
    'xt_loth@nrep.com': 'Louise Thomsen',
    'xt_lumi@nrep.com': 'Ludwig Mild',
    'xt_maad@nrep.com': 'Marc Adalete',
    'xt_maba@nrep.com': 'Martin Bach',
    'xt_mabe@nrep.com': 'Matthijs Bekaert',
    'xt_mabh@nrep.com': 'Maya Bohra',
    'xt_mabl@nrep.com': 'Markus Bley',
    'xt_mabr@nrep.com': 'Martha Berge',
    'xt_mack@nrep.com': 'Maciej Kowalski',
    'xt_mags@nrep.com': 'Markus Gustavsson',
    'xt_magut@nrep.com': 'Marianne Gutfeld Telling',
    'xt_mahl@nrep.com': 'Marc Lellek Holm',
    'xt_main@nrep.com': 'Maria Iniesta',
    'xt_maja@nrep.com': 'Martin Rosenvinge Janner',
    'xt_makn@nrep.com': 'Mads Knutzon',
    'xt_makos@nrep.com': 'Matti Koskinen',
    'xt_male@nrep.com': 'Mattias Lundgren',
    'xt_maml@nrep.com': 'Marta Malodzinska',
    'xt_mamo@nrep.com': 'Martin Mollerberg',
    'xt_mamz@nrep.com': 'Mateusz Miazek',
    'xt_mant@nrep.com': 'Markus Niittymäki',
    'xt_many@nrep.com': 'Markus Niittymäki',
    'xt_mapd@nrep.com': 'Maria Irming Pedersen',
    'xt_maped@nrep.com': 'Martin M Pedersen',
    'xt_mapl@nrep.com': 'Marianne Pelto',
    'xt_mard@nrep.com': 'Marek Dabrowski',
    'xt_marj@nrep.com': 'Martin Raymond Jensen',
    'xt_marka@nrep.com': 'Maria Karlbom',
    'xt_masc@nrep.com': 'Maria Scheutz',
    'xt_mast@nrep.com': 'Mads Steenstup Hannibal',
    'xt_masy@nrep.com': 'Maija Syranen',
    'xt_mato@nrep.com': 'Mats Törnqvist',
    'xt_matu@nrep.com': 'Marko Tutavac',
    'xt_mawa@nrep.com': 'Marcus Wallin',
    'xt_mawi@nrep.com': 'Matilda Widén',
    'xt_meba@nrep.com': 'Melissa Bastos',
    'xt_megu@nrep.com': 'Megha A Gupta',
    'mehj@nrep.com': 'Merethe Hjelmås',
    'xt_mena@nrep.com': 'Meda Nagarjuna',
    'xt_mesc@nrep.com': 'Merlin Schleicher',
    'xt_miha@nrep.com': 'Miko Hassinen',
    'xt_mihi@nrep.com': 'Mikael Hillsten',
    'xt_mihj@nrep.com': 'Michael Hegnet Jensen',
    'mija@nrep.com': 'Mika Jäsperlä',
    'xt_mije@nrep.com': 'Michael Jensen',
    'xt_miku@nrep.com': 'Michelle Kusmic',
    'xt_milei@nrep.com': 'Mikko Leinola',
    'xt_mini@nrep.com': 'Miia Niemi',
    'xt_miol@nrep.com': 'Michael Olschansky Larsen',
    'xt_mipa@nrep.com': 'Minna Pajuvesi',
    'xt_mipl@nrep.com': 'Mirjana Plavic',
    'xt_mise@nrep.com': 'Milda Serpytiene',
    'xt_misk@nrep.com': 'Michal Skawinski',
    'xt_miso@nrep.com': 'Mikko Salo',
    'xt_mita@nrep.com': 'Milda Tarasenko',
    'xt_mitu@nrep.com': 'Milla Tuurnala',
    'xt_miva@nrep.com': 'Minh Van',
    'xt_mivo@nrep.com': 'Mikael Von Scholten',
    'mlan@nrep.com': 'Malin Andersson',
    'xt_mmit@nrep.com': 'Maryia Mitskevich',
    'xt_mofa@nrep.com': 'Morten Fagerli',
    'xt_moje@nrep.com': 'Morten Jensen',
    'mola@nrep.com': 'Morten Larsen',
    'xt_moma@nrep.com': 'Moazzem Mallick',
    'xt_mone@nrep.com': 'Monica Neesgaard',
    'xt_mora@nrep.com': 'Morteza Rakhshandehkhoo',
    'xt_naja@nrep.com': 'Nathalie Jarsfelt',
    'xt_namo@nrep.com': 'Nanna Mourujärvi',
    'xt_nesu@nrep.com': 'Neena Sukumaran',
    'xt_nete@nrep.com': 'Nexudus Test',
    'xt_neui@nrep.com': 'Nexudus UI',
    'xt_niah@nrep.com': 'Nigar Ahmadli',
    'niau@nrep.com': 'Nina Ausland',
    'xt_nifr@nrep.com': 'Nicolai Friis-Hasché',
    'xt_nigu@nrep.com': 'Nidhi Gupta',
    'xt_niku@nrep.com': 'Nikita Kulkarni',
    'xt_nimo@nrep.com': 'Nicolo Monti',
    'xt_nipm@nrep.com': 'Nithin P Mathew',
    'xt_noum@nrep.com': 'Nordhavn Umeus',
    'xt_nuro@nrep.com': 'Nuno Romao',
    'olbu@nrep.com': 'Olle Burell',
    'xt_oldz@nrep.com': 'Oleksii Dzhura',
    'xt_olga@nrep.com': 'Oleksii Gavrylov',
    'xt_olpa@nrep.com': 'Oliver Paulsen',
    'xt_olsk@nrep.com': 'Ole Skanning',
    'xt_oltv@nrep.com': 'Ole Tveit',
    'xt_ooes@nrep.com': 'Oona Eskoli',
    'xt_oshe@nrep.com': 'Osku Heikkinen',
    'xt_osla@nrep.com': 'Oscar Larsson',
    'xt_pagr@nrep.com': 'Paula Grønlund',
    'paiv@nrep.com': 'Päivi Värjä',
    'xt_pame@nrep.com': 'Parvathy S Menon',
    'xt_paru@nrep.com': 'Pauli Ruotsalainen',
    'xt_pean@nrep.com': 'Pernilla Andersson',
    'xt_pefr@nrep.com': 'Pernille Seeberg Friis',
    'xt_peso@nrep.com': 'Pernille Søholm',
    'xt_pest@nrep.com': 'Peder Steinskog',
    'xt_peve@nrep.com': 'Petra Vertanen',
    'xt_pewa@nrep.com': 'Peter Wallqvist',
    'xt_pewal@nrep.com': 'Peter Wallin',
    'xt_phse@nrep.com': 'Philip Seer',
    'xt_pino@nrep.com': 'Piotr Nowak',
    'xt_pisa@nrep.com': 'Pia Samuelson',
    'xt_priv@nrep.com': 'Priyanka V',
    'xt_prsh@nrep.com': 'Preethi Shankarnarayana',
    'xt_raam@nrep.com': 'Rakesh A M',
    'xt_raar@nrep.com': 'Ramon Ardila Bazo',
    'xt_raba@nrep.com': 'Ramiz Basheer',
    'xt_raca@nrep.com': 'Ramesha Chandrappa',
    'xt_rach@nrep.com': 'Ralph Chreskjær',
    'xt_radr@nrep.com': 'Ralf Dreger',
    'xt_raeh@nrep.com': 'Rasmus Ehlers',
    'xt_ragu@nrep.com': 'Rahul Gupta',
    'xt_rahm@nrep.com': 'Rahul P M',
    'xt_raja@nrep.com': 'Rasmus Jakobsen',
    'xt_raki@nrep.com': 'Rasmu Kinnunen',
    'xt_rakm@nrep.com': 'Rajesh Kumar B',
    'xt_rakp@nrep.com': 'Raman Kopats',
    'xt_raku@nrep.com': 'Raghavendra Kurdekar',
    'xt_ralw@nrep.com': 'Rafal lwanko',
    'xt_ramp@nrep.com': 'Rami Peltola',
    'xt_rano@nrep.com': 'Rasmus Nordskilde',
    'xt_rapa@nrep.com': 'Rafal Partyka',
    'xt_rapr@nrep.com': 'Ram Prakash Selvaraj',
    'xt_raras@nrep.com': 'Rasmus Rask',
    'xt_rasc@nrep.com': 'Ramona Schroeder',
    'xt_rasu@nrep.com': 'Ratchanida Suraphut',
    'xt_rave@nrep.com': 'Ramachandran Venkatasubramanian',
    'xt_rawo@nrep.com': 'Rasmus Wolffhechel',
    'xt_raza@nrep.com': 'Rasa Zalense',
    'xt_rbte@nrep.com': 'RBSI test user',
    'xt_rebr@nrep.com': 'Renaud Breyer',
    'xt_receptionvikarie@nrep.com': 'Reception Vikarie',
    'xt_reha@nrep.com': 'Remi Hamant',
    'xt_resc@nrep.com': 'Reinhard Scheck',
    'xt_resi@nrep.com': 'Reda Siozinyte',
    'xt_rige@nrep.com': 'Rita Genyte',
    'xt_rika@nrep.com': 'Rishin Kapur',
    'xt_risi@nrep.com': 'Richa Singh',
    'xt_roos@nrep.com': 'Robert Österberg',
    'xt_rose@nrep.com': 'Roberta Semionova',
    'xt_sabo@nrep.com': 'Sailesh Bombhore',
    'xt_sacs@nrep.com': 'Sajith Charuvil Sadasivan',
    'xt_sagu@nrep.com': 'Sandra Gudaityte',
    'xt_sahp@nrep.com': 'Sahana P',
    'xt_sahy@nrep.com': 'Sampo Hyvönen',
    'xt_sako@nrep.com': 'Sauli Koskinen',
    'xt_sakr@nrep.com': 'Sachin Krishna',
    'xt_saku@nrep.com': 'Santhosha Kumar',
    'xt_sali@nrep.com': 'Sara Lindqvist',
    'xt_salo@nrep.com': 'Saara Luoma',
    'xt_saman@nrep.com': 'Sanna Mäntynen',
    'xt_sano@nrep.com': 'Sanita Novika-Alošina',
    'xt_sasu@nrep.com': 'Sathya Suresh Babu',
    'xt_sath@nrep.com': 'Saritha Thamban',
    'xt_satk@nrep.com': 'Sathyanadhan k',
    'xt_seaa@nrep.com': 'Sebastian Aagaard',
    'xt_seas@nrep.com': 'Senja Assinen',
    'xt_shch@nrep.com': 'Shinu Chacko',
    'xt_shcl@nrep.com': 'Shyju CL',
    'xt_shhd@nrep.com': 'Shrinidhi Hegde',
    'shhe@nrep.com': 'Shila Henriksen',
    'xt_shru@nrep.com': 'Shali Rubio',
    'xt_siag@nrep.com': 'Simran Agarwal',
    'xt_siha@nrep.com': 'Simon Mathias Hansen',
    'xt_sile@nrep.com': 'Signe Lejina',
    'xt_sino@nrep.com': 'Sirisa Nookala',
    'xt_sita@nrep.com': 'Sirpa Tamminen',
    'xt_smar@nrep.com': 'Saroj Mandal',
    'xt_soso@nrep.com': 'Soumya R',
    'xt_sowu@nrep.com': 'Sofie Wu',
    'xt_sral@nrep.com': 'Sreelakshmi Alayath Mohanan',
    'xt_srva@nrep.com': 'Srinidhi Varna',
    'xt_stha@nrep.com': 'Stiina Hakkarainen',
    'xt_stki@nrep.com': 'Stephanie Kiffer',
    'xt_suag@nrep.com': 'Sumit Agrawal',
    'xt_suna@nrep.com': 'Sushmitha Ramesh Nair',
    'xt_sunc@nrep.com': 'Sunil c',
    'xt_supe@nrep.com': 'Susanne Petersen',
    'xt_sura@nrep.com': 'Susanna Rantala',
    'xt_suva@nrep.com': 'sundara varadhan srinivasan',
    'xt_syhu@nrep.com': 'Syed Hussain',
    'xt_takh@nrep.com': 'Tatiana Khozhovets',
    'xt_tako@nrep.com': 'Tayfur Kocaaga',
    'xt_talh@nrep.com': 'Taija Lahtinen',
    'xt_tatu@nrep.com': 'Taina Tuominen',
    'xt_teha@nrep.com': 'Terhi Hannikainen',
    'xt_test23@nrep.com': 'Andreas',
    'xt_teta@nrep.com': 'Testing Taurus',
    'xt_thme@nrep.com': 'Thibault Merlet',
    'xt_thro@nrep.com': 'Thomas Rønnebek',
    'thsj@nrep.com': 'Thomas Sjödén',
    'xt_thsm@nrep.com': 'Thomas Smit',
    'xt_tija@nrep.com': 'Tina Jacobsen',
    'xt_tikr@nrep.com': 'Tine Kragh',
    'xt_time@nrep.com': 'Tiina Merilainen',
    'xt_toau@nrep.com': 'Tore Austheim',
    'xt_toeg@nrep.com': 'Toms Eglitis',
    'xt_togh@nrep.com': 'Tobias Gheorghe',
    'xt_togr@nrep.com': 'Tomasz Grygier',
    'xt_toko@nrep.com': 'Toni Koski',
    'xt_toni@nrep.com': 'Tommy Nielsen',
    'xt_toxh@nrep.com': 'Tonje K Holen',
    'xt_trhe@nrep.com': 'Trine Henriksen',
    'xt_tura@nrep.com': 'Tuomas Rautiainen',
    'xt_tuta@nrep.com': 'Tuomo Tanttu',
    'xt_ulbo@nrep.com': 'Ulrich Gerting Bojko',
    'xt_umsh@nrep.com': 'Umanath Shetty',
    'xt_uran@nrep.com': 'Uri Andersen',
    'xt_vapi@nrep.com': 'Valentina Pindric',
    'xt_vask@nrep.com': 'Vaida Skardziuviene',
    'xt_vasm@nrep.com': 'Vaida Smaguriene',
    'xt_veka@nrep.com': 'Vegard Karlsen',
    'xt_vepa@nrep.com': 'Vesa Päiväniemi',
    'xt_vepe@nrep.com': 'Veera Peltoniemi',
    'xt_vici@nrep.com': 'Viliune Cibulskaite',
    'xt_viki@nrep.com': 'Vibhu Kiran',
    'xt_vili@nrep.com': 'Victor Lindholm',
    'xt_vime@nrep.com': 'Ville Metsola',
    'xt_vimeu@nrep.com': 'Viviane Meunier',
    'xt_vimr@nrep.com': 'Victor Marcu',
    'xt_vipe@nrep.com': 'Vilija Petraskaite',
    'xt_visw@nrep.com': 'Vian Swedin',
    'xt_visy@nrep.com': 'Viktor Sylvén',
    'xt_vito@nrep.com': 'Ville Toivanen',
    'xt_vitr@nrep.com': 'Vitalii Trefianchyn',
    'xt_vitrold@nrep.com': 'Vitalii Trefianchyn OLD',
    'xt_ymbs@nrep.com': 'Yme Brosens',
    'xt_zasd@nrep.com': 'Zanete Sederaviciute',
    'yaha@nrep.com': 'Yasser Handaj',
    'yammerjaytest26@nrep.com': 'yammerjaytest26 26',
    'yona@nrep.com': 'Yousuf Nazir',
    'zeek@nrep.com': 'Zenia Ekdal',
};
