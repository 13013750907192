import { PointTypeEnum } from '../apis/mapNotesApi';

export default function PointTypeSVG({
    mapPointType,
}: {
    mapPointType: PointTypeEnum;
}): JSX.Element {
    const fill =
        mapPointType === 'active'
            ? 'lightgreen'
            : mapPointType === 'killed_or_lost'
            ? 'lightpink'
            : 'palegoldenrod';
    const stroke =
        mapPointType === 'active'
            ? 'green'
            : mapPointType === 'killed_or_lost'
            ? 'red'
            : 'darkorange';
    const pathD =
        mapPointType === 'active'
            ? 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
            : mapPointType === 'killed_or_lost'
            ? 'M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636'
            : 'M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z';

    return (
        <svg
            className="w-6 h-6"
            fill={fill}
            width="60"
            height="60"
            stroke={stroke}
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d={pathD}
            />
        </svg>
    );
}
