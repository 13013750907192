export const msalConfig = {
    auth: {
        clientId:
            process.env.REACT_APP_MSAL_CLIENT_ID ||
            '5158df3a-7855-4fad-bfcb-d0625683ee5f',
        authority: `https://login.microsoftonline.com/${
            process.env.REACT_APP_MSAL_TENANT_ID ||
            '36ac9e62-8883-4e41-bd87-c11cba476d5c'
        }`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri:
            process.env.REACT_APP_MSAL_REDIRECT_URI || 'http://localhost:3000/',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        `api://${process.env.REACT_APP_MSAL_CLIENT_ID}/user_impersonation`,
        'User.Read',

        // 'User.ReadBasic.All',
        // 'https://graph.microsoft.com/.default',
    ],
};
