import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ErrorNotificationContext } from 'components/providers/errorNotification';
import { useContext } from 'react';

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export default function SignOutButton() {
    const { instance } = useMsal();

    const { addError } = useContext(ErrorNotificationContext);

    const handleLogout = (msalInstance: IPublicClientApplication) => {
        msalInstance.logoutRedirect().catch(() => {
            addError('Logout failed');
        });
    };
    return (
        <button
            type="button"
            className="w-full block py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            onClick={() => handleLogout(instance)}
        >
            Sign out
        </button>
    );
}
