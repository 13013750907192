import { AccountInfo } from '@azure/msal-browser';
import { Dropdown, Navbar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';
import SignOutButton from './authentication/SignOutButton';

interface CustomNavbarProps {
    account: AccountInfo;
}

const NavLinkActiveStyleHandler = ({
    isActive,
}: {
    isActive: boolean;
}): string =>
    isActive
        ? 'block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white'
        : 'block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700';

export default function CustomNavbar({
    account,
}: CustomNavbarProps): JSX.Element {
    return (
        <Navbar fluid rounded className="border-b">
            <Navbar.Brand href="/">
                <img
                    src="/android-chrome-512x512.png"
                    className="mr-3 h-6 sm:h-9"
                    alt="NREP Map Notes logo"
                />
                <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                    Map Notes{' '}
                    <span className="text-sm font-light text-gray-500">
                        by NREP AI Labs
                    </span>
                </span>
            </Navbar.Brand>
            <div className="flex md:order-2 items-center z-20">
                <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                        <div className="bg-gray-600 border rounded-full">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="white"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                    }
                >
                    <Dropdown.Header>
                        <span className="block text-sm text-gray-900 dark:text-white">
                            {account.name}
                        </span>
                        <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
                            {account.username}
                        </span>
                    </Dropdown.Header>

                    <Dropdown.Item>
                        <SignOutButton />
                    </Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                    <li>
                        <NavLink to="/" className={NavLinkActiveStyleHandler}>
                            Map
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/table"
                            className={NavLinkActiveStyleHandler}
                        >
                            Table
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/point_groups"
                            className={NavLinkActiveStyleHandler}
                        >
                            Point Groups
                        </NavLink>
                    </li>
                </ul>
            </Navbar.Collapse>
        </Navbar>
    );
}
