import Dropdown from 'components/Dropdown';
import { MapLayerType } from 'types';

export default function MapLayerSelector({
    mapLayer,
    mapLayers,
    setMapLayer,
}: {
    mapLayer: MapLayerType | undefined;
    mapLayers: (MapLayerType | undefined)[];
    setMapLayer: (newMapLayer: MapLayerType | undefined) => void;
}): JSX.Element {
    return (
        <div className="flex flex-col">
            <label
                htmlFor="mapboxstyle"
                className="flex-shrink-0 mr-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
            >
                Layer
            </label>
            <Dropdown
                value={mapLayer}
                values={mapLayers}
                onChange={setMapLayer}
                renderItem={(value) =>
                    value ? (
                        <span>{value}</span>
                    ) : (
                        <span className="italic text-center">None</span>
                    )
                }
                getKey={(value) => value ?? 'none_selected'}
            />
        </div>
    );
}
