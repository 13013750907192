export default function MapUserInstructionsAlert(): JSX.Element {
    return (
        <div className="flex flex-col justify-center items-center space-y-2 w-full pt-2 pr-4">
            <div
                className="flex items-center p-4 w-full max-w-lg text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                role="alert"
            >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-6 h-6 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
                    <svg
                        className="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Fire icon</span>
                </div>
                <div className="ml-3 text-sm font-normal w-64">
                    Click on the map to add a new point or click on existing
                    points to view notes
                </div>
            </div>
        </div>
    );
}
