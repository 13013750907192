import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import ErrorNotificationProvider from 'components/providers/errorNotification';
import ErrorModal from 'components/ErrorModal';
import { Route, Routes } from 'react-router-dom';
import PointsTable from 'PointsTable';
// import { useEffect, useState } from 'react';
// import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { nrepUsers } from 'users';
import PointGroups from 'PointGroups';
import { useState } from 'react';
import { PointFilterType } from 'types';
import LoadingSpinner from 'components/LoadingSpinner';
import PointsMap from './PointsMap';
import CustomNavbar from './components/CustomNavbar';
import SignIn from './components/authentication/SignIn';

interface MSGraphAPIUser {
    displayName: string | null;
    givenName: string | null;
    mail: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchAllNREPUsersRecursively = async (accessToken: string) => {
    const fetchUsers = async (url: string): Promise<MSGraphAPIUser[]> => {
        try {
            const usersResponse = await fetch(url, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            if (usersResponse.ok) {
                const usersResponseData = await usersResponse.json();

                const followingUsers = usersResponseData['@odata.nextLink']
                    ? await fetchUsers(usersResponseData['@odata.nextLink'])
                    : [];

                return [
                    ...usersResponseData.value.filter(
                        (user: MSGraphAPIUser) =>
                            user.mail && user.mail.includes('nrep.com'),
                    ),
                    ...followingUsers,
                ];
            }
            return [];
        } catch {
            return [];
        }
    };
    return fetchUsers(
        'https://graph.microsoft.com/v1.0/users?$top=999&$select=mail,displayName,givenName',
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapNrepUsersListToMailNameDict = (
    users: MSGraphAPIUser[],
): { [key: string]: string } =>
    users.reduce((dict: { [key: string]: string }, user: MSGraphAPIUser) => {
        if (user.mail && user.mail !== null) {
            return {
                ...dict,
                [user.mail]: user.displayName || user.givenName,
            } as { [key: string]: string };
        }
        return dict;
    }, {});

function MainContent({
    account,
}: // msalInstance,
{
    account: AccountInfo;
    // msalInstance: IPublicClientApplication;
}): JSX.Element {
    // TODO: This is all disabled until Azure AD admin consent is given.

    // const [users, setUsers] = useState<{ [string: string]: string }>(nrepUsers);

    // const fetchNREPUsers = async () => {
    //     const request = {
    //         scopes: ['User.Read', 'User.ReadBasic.All'],
    //         account,
    //     };
    //     try {
    //         const tokenResponse = await msalInstance.acquireTokenSilent(
    //             request,
    //         );

    //         const fetchedUsersDict = mapNrepUsersListToMailNameDict(
    //             await fetchAllNREPUsersRecursively(tokenResponse.accessToken),
    //         );

    //         if (fetchedUsersDict && Object.keys(fetchedUsersDict).length > 0) {
    //             setUsers(fetchedUsersDict);
    //         }
    //     } catch {
    //         const tokenResponse = await msalInstance.acquireTokenPopup(request);
    //         const fetchedUsersDict = mapNrepUsersListToMailNameDict(
    //             await fetchAllNREPUsersRecursively(tokenResponse.accessToken),
    //         );
    //         if (fetchedUsersDict && Object.keys(fetchedUsersDict).length > 0) {
    //             setUsers(fetchedUsersDict);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     fetchNREPUsers();
    // }, []);

    const [pointFilter, setPointFilter] = useState<PointFilterType>({
        point_group_uuids: [],
        point_types: [],
        owner_usernames: [],
    });

    return (
        <Routes>
            <Route
                path="/table"
                element={
                    <PointsTable
                        account={account}
                        users={nrepUsers}
                        pointFilter={pointFilter}
                    />
                }
            />
            <Route
                path="/"
                element={
                    <PointsMap
                        account={account}
                        users={nrepUsers}
                        pointFilter={pointFilter}
                        setPointFilter={setPointFilter}
                    />
                }
            />
            <Route
                path="/point_groups"
                element={<PointGroups account={account} users={nrepUsers} />}
            />
        </Routes>
    );
}

const mapInProgressStateToReadableText = (
    inProgress: Omit<InteractionStatus, 'none'>,
) => {
    switch (inProgress) {
        case 'startup':
            return 'Starting up...';
        case 'login':
            return 'Logging in...';
        case 'logout':
            return 'Logging out...';
        case 'acquireToken':
            return 'Authenticating...';
        case 'handleRedirect':
            return 'Handling authentication redirect...';
        default:
            return 'Loading...';
    }
};

export default function App() {
    const isAuthenticated = useIsAuthenticated();
    // const { instance, accounts } = useMsal();
    const { accounts, inProgress } = useMsal();

    return (
        <ErrorNotificationProvider>
            {isAuthenticated && accounts && accounts[0] ? (
                <div>
                    <CustomNavbar account={accounts[0]} />
                    <MainContent
                        account={accounts[0]}
                        // msalInstance={instance}
                    />
                </div>
            ) : inProgress === 'none' ? (
                <SignIn />
            ) : (
                <div className="flex w-full h-full justify-center items-center mt-[20vh]">
                    <LoadingSpinner
                        text={mapInProgressStateToReadableText(inProgress)}
                    />
                </div>
            )}
            <ErrorModal />
        </ErrorNotificationProvider>
    );
}
