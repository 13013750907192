import { useState } from 'react';

interface NewPointNoteProps {
    onPointNoteAdd: (text: string) => void;
    buttonType: 'save' | 'add';
}

export default function NewNote({
    onPointNoteAdd,
    buttonType,
}: NewPointNoteProps): JSX.Element {
    const [newNoteText, setNewNoteText] = useState('');
    const [clicked, setClicked] = useState(false);
    return (
        <div className="flex flex-row items-start space-x-2 ">
            <textarea
                id="newNote"
                className={`block ${
                    clicked ? 'h-48' : 'h-9'
                }  pr-20  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="New note"
                value={newNoteText}
                onClick={() => setClicked(true)}
                onChange={(e) => setNewNoteText(e.target.value)}
            />
            <button
                type="button"
                onClick={() => {
                    onPointNoteAdd(newNoteText);
                    setNewNoteText('');
                }}
                className={
                    buttonType === 'add'
                        ? 'text-white right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                        : 'text-white right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                }
            >
                {buttonType === 'add' ? 'Add' : 'Save'}
            </button>
        </div>
    );
}
