/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    getPointGroups,
    getPoints,
    PointGet,
    PointGroupGet,
    PointNoteGet,
    PointPatch,
    PointTypeEnum,
    updatePoint,
} from 'apis/mapNotesApi';
import { ErrorNotificationContext } from 'components/providers/errorNotification';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    CellContext,
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    RowData,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import {
    getPointGroupNamesByUuid,
    getPointTypeBackgroundColor,
    POINTTYPEREADABLENAMES,
} from 'helpers';
import { NumericFormat } from 'react-number-format';
import { PointFilterType } from 'types';
import NavLinkCell from 'components/table/NavLinkCell';
import PointNotesCell from 'components/table/PointNotesCell';
import SortIcon from 'components/icons/SortIcon';
import DoubleLeftChevronIcon from 'components/icons/DoubleLeftChevronIcon';
import DoubleRightChevronIcon from 'components/icons/DoubleRightChevronIcon';
import LeftChevronIcon from 'components/icons/LeftChevronIcon';
import RightChevronIcon from 'components/icons/RightChevronIcon';
import { AccountInfo } from '@azure/msal-browser';
import { useSendCalendarNotification } from 'hooks/CalendarNotification';
import SendCalendarEventModal from 'components/SendCalendarEventModal';
import LoadingSpinner from 'components/LoadingSpinner';

declare module '@tanstack/react-table' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        updateData: (
            rowIndex: number,
            columnId: string,
            value: unknown,
        ) => void;
    }
}

function EditableStringCell(
    info: CellContext<PointGet, string | null>,
): JSX.Element {
    const {
        getValue,
        row: { index },
        column: { id },
        table,
    } = info;
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <input
            className="block w-full p-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            value={value ?? ''}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
        />
    );
}

function EditableDateCell(
    info: CellContext<PointGet, string | null>,
): JSX.Element {
    const {
        getValue,
        row: { index },
        column: { id },
        table,
    } = info;

    const initialValue = getValue()?.split('T')[0] ?? null;

    return (
        <input
            className="block w-full p-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="date"
            value={initialValue ?? ''}
            onChange={(event) => {
                if (event.target.value !== initialValue) {
                    const { value } = event.target;
                    const formattedValue =
                        value === '' ? null : value.concat('T00:00:00');

                    table.options.meta?.updateData(index, id, formattedValue);
                }
            }}
        />
    );
}

function EditablePointTypeSelectCell(
    info: CellContext<PointGet, PointTypeEnum>,
): JSX.Element {
    const {
        getValue,
        row: { index },
        column: { id },
        table,
    } = info;
    const initialValue = getValue();

    return (
        <select
            className="block w-full p-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={initialValue}
            onChange={(e) => {
                table.options.meta?.updateData(
                    index,
                    id,
                    e.target.value as PointTypeEnum,
                );
            }}
        >
            {Object.keys(POINTTYPEREADABLENAMES).map((pointType) => (
                <option key={pointType} value={pointType}>
                    {
                        POINTTYPEREADABLENAMES[
                            pointType as keyof typeof POINTTYPEREADABLENAMES
                        ]
                    }
                </option>
            ))}
        </select>
    );
}

const EditableStringSelectCellFactory = (options: Record<string, string>) =>
    function EditablePointGroupSelectCell(
        info: CellContext<PointGet, string>,
    ): JSX.Element {
        const {
            getValue,
            row: { index },
            column: { id },
            table,
        } = info;
        const initialValue = getValue();

        return (
            <select
                className="block w-full p-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={initialValue}
                onChange={(e) => {
                    table.options.meta?.updateData(index, id, e.target.value);
                }}
            >
                {Object.keys(options).map((pointGroupUuid) => (
                    <option key={pointGroupUuid} value={pointGroupUuid}>
                        {options[pointGroupUuid]}
                    </option>
                ))}
            </select>
        );
    };

function EditableNumberCell(
    info: CellContext<PointGet, number | null>,
): JSX.Element {
    const {
        getValue,
        row: { index },
        column: { id },
        table,
    } = info;
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <NumericFormat
            // type="number"
            className="block w-full py-1 px-2 text-gray-900 border border-gray-300 rounded-md bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={typeof value === 'number' ? value.toString() : ''}
            onValueChange={(newValue) => {
                setValue(newValue.floatValue ?? null);
            }}
            onBlur={onBlur}
            allowNegative={false}
            decimalScale={3}
        />
    );
}

function useSkipper(): readonly [boolean, () => void] {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
        shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
        shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
}

export default function PointsTable({
    account,
    users,
    pointFilter,
}: {
    account: AccountInfo;
    users: { [key: string]: string };
    pointFilter: PointFilterType;
    // setPointFilter: React.Dispatch<React.SetStateAction<PointFilterType>>;
}) {
    const [editMode, setEditMode] = useState(false);
    const [points, setPoints] = useState<PointGet[]>([]);
    const { addError } = useContext(ErrorNotificationContext);
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

    useEffect(() => {
        getPoints()
            .then((response) => {
                if (response.status === 200) {
                    setPoints(response.data);
                } else {
                    addError(
                        `Error getting points. Error code: ${response.status}`,
                    );
                }
            })
            .catch(() => {
                addError(
                    "Network or server error! Check your internet connection. Couldn't get points.",
                );
            });
    }, []);

    const [pointGroups, setPointGroups] = useState<PointGroupGet[]>([]);

    useEffect(() => {
        getPointGroups()
            .then((response) => {
                if (response.status === 200) {
                    setPointGroups(response.data);
                } else {
                    addError(
                        `Error getting point groups. Error code: ${response.status}`,
                    );
                }
            })
            .catch(() => {
                addError(
                    "Network or server error! Check your internet connection. Couldn't get point groups.",
                );
            });
    }, []);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const pointGroupNamesByUuid = useMemo(
        () => getPointGroupNamesByUuid(pointGroups),
        [pointGroups],
    );

    const EditablePointGroupSelectCell = useMemo(
        () => EditableStringSelectCellFactory(pointGroupNamesByUuid),
        [pointGroupNamesByUuid],
    );

    const EditableOwnerUsernameSelectCell = useMemo(
        () => EditableStringSelectCellFactory(users),
        [users],
    );

    const [
        calendarEventToBeSent,
        setCalendarEventToBeSent,
        setCalendarEventToBeSentByTemplate,
        calendarEventSendingInProgress,
        sendCalendarEvent,
    ] = useSendCalendarNotification(account, users);

    const columnHelper = createColumnHelper<PointGet>();

    const columnDefs = useMemo(
        () => [
            columnHelper.accessor('uuid', {
                header: 'Map link',

                cell: NavLinkCell,
                enableColumnFilter: false,
                enableSorting: false,
            }),

            columnHelper.accessor('name', {
                header: 'Name',
                cell: editMode
                    ? EditableStringCell
                    : (info) => info.cell.getValue(),
                filterFn: (row, columnId, filterValue) => {
                    const value = row.getValue(columnId);

                    return (
                        typeof value === 'string' &&
                        value.toLowerCase().includes(filterValue.toLowerCase())
                    );
                },
            }),

            columnHelper.accessor('address', {
                header: 'Address',
            }),

            columnHelper.accessor('size', {
                header: 'Size',
                cell: editMode
                    ? EditableNumberCell
                    : (info) => info.cell.getValue(),
                filterFn: (row, columnId, filterValue) =>
                    typeof row.getValue(columnId) === 'number' &&
                    row.getValue(columnId) === parseInt(filterValue, 10),
            }),

            columnHelper.accessor('owner_username', {
                id: 'owner_username',
                header: 'NREP Owner',

                cell: editMode
                    ? EditableOwnerUsernameSelectCell
                    : (info) => {
                          const value = info.cell.getValue();
                          return value !== null
                              ? `${users[value] || ''} (${value})`
                              : null;
                      },
                filterFn: (row, columnId, filterValue) => {
                    const value = row.getValue(columnId);
                    return (
                        typeof value === 'string' &&
                        `${users[value] || ''} (${row.getValue(columnId)})`
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                    );
                },
            }),

            columnHelper.accessor('contact_person', {
                header: 'Seller / Contact Person',
                cell: editMode
                    ? EditableStringCell
                    : (info) => info.cell.getValue(),
                filterFn: (row, columnId, filterValue) => {
                    const value = row.getValue(columnId);

                    return (
                        typeof value === 'string' &&
                        value.toLowerCase().includes(filterValue.toLowerCase())
                    );
                },
            }),

            columnHelper.accessor('follow_up_timestamp', {
                header: 'Follow up date',

                filterFn: (row, columnId, filterValue) =>
                    typeof row.getValue(columnId) === 'string' &&
                    new Date(`${row.getValue(columnId)}Z`)
                        .toLocaleDateString('fi-FI')
                        .includes(filterValue),
                cell: editMode
                    ? EditableDateCell
                    : (info) => {
                          if (info.cell.getValue() === null) {
                              return null;
                          }
                          const date = new Date(`${info.cell.getValue()}Z`);
                          return `${date.toLocaleDateString('fi-FI')}`;
                      },
            }),

            columnHelper.accessor('point_type', {
                header: 'Point Type',

                cell: editMode
                    ? EditablePointTypeSelectCell
                    : (info) =>
                          POINTTYPEREADABLENAMES[
                              info.cell.getValue() as PointTypeEnum
                          ],
                filterFn: (row, columnId, filterValue) =>
                    POINTTYPEREADABLENAMES[
                        row.getValue(columnId) as PointTypeEnum
                    ]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()),
            }),

            columnHelper.accessor('point_group_uuid', {
                header: 'Point Group',

                filterFn: (row, columnId, filterValue) => {
                    const value = row.getValue(columnId);
                    return (
                        typeof value === 'string' &&
                        pointGroupNamesByUuid[value]
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                    );
                },
                cell: editMode
                    ? EditablePointGroupSelectCell
                    : (info) => pointGroupNamesByUuid[info.cell.getValue()],
            }),

            columnHelper.accessor('created_timestamp', {
                header: 'Created at',

                filterFn: (row, columnId, filterValue) =>
                    new Date(`${row.getValue(columnId)}Z`)
                        .toLocaleDateString('fi-FI')
                        .includes(filterValue),
                cell: (info) => {
                    const date = new Date(`${info.cell.getValue()}Z`);
                    return `${date.toLocaleDateString(
                        'fi-FI',
                    )} (${date.toLocaleTimeString('fi-FI')})`;
                },
            }),

            columnHelper.accessor('creator_username', {
                header: 'Created by',
                cell: (info) =>
                    `${
                        users[info.cell.getValue()] || ''
                    } (${info.cell.getValue()})`,
                filterFn: (row, columnId, filterValue) => {
                    const value = row.getValue(columnId);

                    return (
                        typeof value === 'string' &&
                        `${users[value] || ''} (${row.getValue(columnId)})`
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                    );
                },
            }),

            columnHelper.accessor('updated_timestamp', {
                header: 'Last action at',
                cell: (info) => {
                    const updatedTimeStamps = info.row.original.point_notes
                        .map((note) => note.updated_timestamp)
                        .filter((timestamp) => timestamp !== null) as string[];
                    const createdTimeStamps = info.row.original.point_notes.map(
                        (note) => note.created_timestamp,
                    );

                    const allTimeStamps = [
                        ...updatedTimeStamps,
                        ...createdTimeStamps,
                        ...(info.row.original.updated_timestamp
                            ? [info.row.original.updated_timestamp]
                            : []),
                        info.row.original.created_timestamp,
                    ] as string[];

                    // sort timestamps in descending order
                    allTimeStamps.sort((a, b) => {
                        if (a > b) {
                            return -1;
                        }
                        if (a < b) {
                            return 1;
                        }
                        return 0;
                    });

                    const date = new Date(`${allTimeStamps[0]}Z`);
                    return `${date.toLocaleDateString(
                        'fi-FI',
                    )} (${date.toLocaleTimeString('fi-FI')})`;
                },
            }),
            columnHelper.accessor('point_notes', {
                header: 'Notes',

                cell: PointNotesCell,
                filterFn: (row, columnId, filterValue) => {
                    if (typeof filterValue === 'string') {
                        if (filterValue.length === 0) {
                            return false;
                        }
                        const notesArray = row.getValue(
                            columnId,
                        ) as PointNoteGet[];
                        for (let i = 0; i < notesArray.length; i += 1) {
                            if (
                                notesArray[i].text
                                    .toLowerCase()
                                    .includes(filterValue.toLowerCase())
                            ) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return false;
                },
            }),
        ],
        [pointGroupNamesByUuid, editMode, users],
    );
    const [sorting, setSorting] = useState<SortingState>([]);
    const table = useReactTable({
        data: points,
        columns: columnDefs,

        state: {
            sorting,
            columnFilters,
        },
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 25,
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetPageIndex,
        meta: {
            updateData: (rowIndex, columnId, value) => {
                // Skip age index reset until after next rerender
                skipAutoResetPageIndex();

                const pointData = points[rowIndex];
                const oldValue = pointData[columnId as keyof PointGet];

                // If old value is not equal to new value, update the point
                if (oldValue !== value) {
                    const oldPoints = [...points];
                    setPoints((prevPoints) =>
                        prevPoints.map((row, index) => {
                            if (index === rowIndex) {
                                return {
                                    ...prevPoints[rowIndex],
                                    [columnId]: value,
                                    updated_timestamp: new Date()
                                        .toISOString()
                                        .replace('Z', ''),
                                    updater_username: account.username,
                                };
                            }
                            return row;
                        }),
                    );

                    updatePoint(pointData.uuid, {
                        [columnId as keyof PointPatch]: value,
                        updater_username: account.username,
                    })
                        .then((updatePointResponse) => {
                            if (updatePointResponse.status === 200) {
                                if (
                                    columnId === 'follow_up_timestamp' ||
                                    columnId === 'owner_username'
                                ) {
                                    const ownerUsername =
                                        columnId === 'owner_username'
                                            ? (value as string)
                                            : pointData.owner_username;

                                    const followUpTimeStamp =
                                        columnId === 'follow_up_timestamp'
                                            ? (value as string)
                                            : pointData.follow_up_timestamp;

                                    if (ownerUsername && followUpTimeStamp) {
                                        setCalendarEventToBeSentByTemplate(
                                            followUpTimeStamp,
                                            ownerUsername,
                                            pointData,
                                        );
                                    }
                                }
                            } else {
                                setPoints(oldPoints);
                                addError(
                                    `Couldn't update the point! Error code: ${updatePointResponse.status}`,
                                );
                            }
                        })
                        .catch(() => {
                            addError(
                                "Network or server error! Check your internet connection! Couldn't update the point.",
                            );
                        });
                }
            },
        },
    });

    useEffect(() => {
        table.setColumnFilters([
            ...(pointFilter.owner_usernames.length > 0
                ? [
                      {
                          id: 'owner_username',
                          value: users[pointFilter.owner_usernames[0]],
                      },
                  ]
                : []),

            ...(pointFilter.point_group_uuids.length > 0
                ? [
                      {
                          id: 'point_type',
                          value: POINTTYPEREADABLENAMES[
                              pointFilter.point_types[0]
                          ],
                      },
                  ]
                : []),

            ...(pointFilter.point_group_uuids.length > 0
                ? [
                      {
                          id: 'point_group_uuid',
                          value: pointGroupNamesByUuid[
                              pointFilter.point_group_uuids[0]
                          ],
                      },
                  ]
                : []),
        ]);
    }, [pointFilter, pointGroupNamesByUuid]);

    return (
        <div className="my-4 mx-4">
            {calendarEventSendingInProgress && (
                <div className="flex justify-center items-center mt-2">
                    <LoadingSpinner text="Creating calendar event..." />
                </div>
            )}

            <div className="my-2">
                <label className="inline-flex relative items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={editMode}
                        className="sr-only peer"
                        onChange={() => setEditMode(!editMode)}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Edit mode {editMode ? 'on' : 'off'}
                    </span>
                </label>
            </div>

            <div className="shadow-md overflow-x-auto sm:rounded-lg px-2 pt-4 bg-slate-50 dark:bg-slate-700 dark:text-slate-400">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div className="mb-2 flex flex-col justify-between h-16">
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? 'flex cursor-pointer select-none'
                                                                : 'flex',
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    <div className="flex items-center mb-1 mr-2">
                                                        <span className="w-full break-all min-w-[40px] max-h-8 overflow-y-hidden">
                                                            {flexRender(
                                                                // @ts-ignore
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext(),
                                                            )}
                                                        </span>
                                                        {header.column.getCanSort() ? (
                                                            <SortIcon
                                                                order={header.column.getIsSorted()}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>

                                                {header.column.getCanFilter() ? (
                                                    <div className="pr-2">
                                                        <input
                                                            className="w-full text-xs py-1 px-2 bg-gray-100 rounded-lg shadow-md"
                                                            type="text"
                                                            placeholder={`Search... (${
                                                                header.column.getFacetedUniqueValues()
                                                                    .size
                                                            })`}
                                                            value={
                                                                (header.column.getFilterValue() ??
                                                                    '') as string
                                                            }
                                                            onChange={(e) => {
                                                                header.column.setFilterValue(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                className={`border-b ${
                                    row.original
                                        ? getPointTypeBackgroundColor(
                                              row.original,
                                          )
                                        : ''
                                }`}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-3 py-1">
                                        {flexRender(
                                            // @ts-ignore
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex mt-2 items-center gap-2">
                    <button
                        type="button"
                        className={`${
                            !table.getCanPreviousPage()
                                ? 'cursor-not-allowed text-blue-400 border-blue-400 hover:bg-blue-400'
                                : 'text-blue-700 border-blue-700 hover:bg-blue-700'
                        }  border  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800`}
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <DoubleLeftChevronIcon />
                    </button>
                    <button
                        type="button"
                        className={`${
                            !table.getCanPreviousPage()
                                ? 'cursor-not-allowed text-blue-400 border-blue-400 hover:bg-blue-400'
                                : 'text-blue-700 border-blue-700 hover:bg-blue-700'
                        }  border  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800`}
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <LeftChevronIcon />
                    </button>
                    <button
                        type="button"
                        className={`${
                            !table.getCanNextPage()
                                ? 'cursor-not-allowed text-blue-400 border-blue-400 hover:bg-blue-400'
                                : 'text-blue-700 border-blue-700 hover:bg-blue-700'
                        }  border  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800`}
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <RightChevronIcon />
                    </button>
                    <button
                        type="button"
                        className={`${
                            !table.getCanNextPage()
                                ? 'cursor-not-allowed text-blue-400 border-blue-400 hover:bg-blue-400'
                                : 'text-blue-700 border-blue-700 hover:bg-blue-700'
                        }  border  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800`}
                        onClick={() =>
                            table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                    >
                        <DoubleRightChevronIcon />
                    </button>
                    <span className="mx-4 flex items-center gap-1 text-sm">
                        <div>Page</div>
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{' '}
                            {table.getPageCount()}
                        </strong>
                    </span>

                    <span className="flex items-center gap-1 text-sm">
                        Go to page:
                        <input
                            type="number"
                            defaultValue={
                                table.getState().pagination.pageIndex + 1
                            }
                            onChange={(e) => {
                                const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                table.setPageIndex(page);
                            }}
                            className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block py-1 pl-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                    </span>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block py-1 pl-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                        {[25, 50, 100, 200, 500].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 mt-2 text-xs">
                    Showing {table.getRowModel().rows.length} rows
                </div>
            </div>
            <SendCalendarEventModal
                open={
                    !!calendarEventToBeSent && !calendarEventSendingInProgress
                }
                calendarEventInfo={calendarEventToBeSent}
                onClose={() => setCalendarEventToBeSent(null)}
                onConfirm={sendCalendarEvent}
            />
        </div>
    );
}
