import { PointGet, PointGroupGet, PointTypeEnum } from 'apis/mapNotesApi';
import { MAPPOINTTYPES, POINTTYPEREADABLENAMES } from 'helpers';
import Select from 'react-select';
import { PointFilterType } from 'types';

interface MapFiltersProps {
    users: { [key: string]: string };
    pointGroupNamesByUuid: { [key: string]: string };
    allPoints: PointGet[];
    pointGroups: PointGroupGet[];
    pointFilter: PointFilterType;
    setPointFilter: React.Dispatch<React.SetStateAction<PointFilterType>>;
    setSelectedPointGroup: React.Dispatch<
        React.SetStateAction<PointGroupGet | undefined>
    >;
    setNewMapPointPointType: React.Dispatch<
        React.SetStateAction<PointTypeEnum>
    >;
}

export default function MapFilters({
    users,
    pointGroupNamesByUuid,
    allPoints,
    pointGroups,
    pointFilter,
    setPointFilter,
    setSelectedPointGroup,
    setNewMapPointPointType,
}: MapFiltersProps): JSX.Element {
    return (
        <>
            <Select
                className="mr-2 mt-1"
                options={pointGroups.map((pointGroup) => ({
                    label: pointGroup.name,
                    value: pointGroup.uuid,
                }))}
                noOptionsMessage={(inputValue) =>
                    `No point groups found with name ${inputValue}`
                }
                isMulti
                isSearchable={false}
                onChange={(newValueArray) => {
                    const newPointGroupUuids = newValueArray.map(
                        (value) => value.value,
                    );

                    setPointFilter((oldPointFilter) => ({
                        ...oldPointFilter,
                        point_group_uuids: newPointGroupUuids,
                    }));
                    if (newPointGroupUuids.length > 0) {
                        setSelectedPointGroup(
                            pointGroups.filter((pointGroup) =>
                                newPointGroupUuids.includes(pointGroup.uuid),
                            )[0],
                        );
                    }
                }}
                placeholder="Filter by point group"
                value={pointFilter.point_group_uuids.map((pointGroupUuid) => ({
                    value: pointGroupUuid,
                    label: pointGroupNamesByUuid[pointGroupUuid],
                }))}
            />

            <Select
                className="mr-2 mt-1"
                options={MAPPOINTTYPES.map((pointType) => ({
                    label: POINTTYPEREADABLENAMES[pointType],
                    value: pointType,
                }))}
                noOptionsMessage={() => 'No point types found'}
                isMulti
                isSearchable={false}
                onChange={(newValueArray) => {
                    const newPointTypeValues = newValueArray.map(
                        (value) => value.value,
                    );
                    setPointFilter((oldPointFilter) => ({
                        ...oldPointFilter,
                        point_types: newPointTypeValues,
                    }));
                    if (newValueArray.length > 0) {
                        setNewMapPointPointType(newPointTypeValues[0]);
                    }
                }}
                placeholder="Filter by point type"
                value={pointFilter.point_types.map((pointType) => ({
                    value: pointType,
                    label: POINTTYPEREADABLENAMES[pointType],
                }))}
            />

            <Select
                className="mr-2 mt-1"
                options={[
                    ...new Set(allPoints.map((point) => point.owner_username)),
                ]
                    .filter((ownerUsername) => ownerUsername !== null)
                    .map((ownerUsername) => ({
                        label:
                            users[ownerUsername as string] ||
                            ownerUsername ||
                            '',
                        value: ownerUsername as string,
                    }))}
                noOptionsMessage={() => 'No user found with that name'}
                isMulti
                onChange={(newValueArray) => {
                    setPointFilter((oldPointFilter) => ({
                        ...oldPointFilter,
                        owner_usernames: newValueArray.map(
                            (value) => value.value as PointTypeEnum,
                        ),
                    }));
                }}
                placeholder="Filter by owner"
                value={pointFilter.owner_usernames.map((ownerUsername) => ({
                    label: users[ownerUsername] || ownerUsername || '',
                    value: ownerUsername,
                }))}
            />
        </>
    );
}
