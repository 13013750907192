import { useEffect, useRef, useState } from 'react';
import { Dictionary } from 'types';

interface DropdownProps<T> {
    value: T;
    values: T[];
    onChange: (value: T) => void;
    renderItem?: (value: T) => JSX.Element;
    getKey: (value: T) => string;
    renderAddItem?: () => JSX.Element;
}

export default function Dropdown<T extends string | Dictionary<T>>({
    value,
    values,
    onChange,
    renderItem,
    getKey,
    renderAddItem,
}: DropdownProps<T>): JSX.Element {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (
                isMenuOpen &&
                ref.current &&
                !ref.current.contains(e.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener

            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <div ref={ref}>
            <button
                className="flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                type="button"
                onClick={() => setIsMenuOpen((isOpen) => !isOpen)}
            >
                {renderItem ? (
                    renderItem(value)
                ) : typeof value === 'string' ? (
                    <div className="inline-flex items-center">{value}</div>
                ) : (
                    'Value is not string and renderItem is not defined'
                )}

                <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {isMenuOpen ? (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 15l7-7 7 7"
                        />
                    ) : (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        />
                    )}
                </svg>
            </button>

            {isMenuOpen && (
                <div className="absolute bg-white text-base z-50 divide-y divide-gray-100 rounded shadow my-4">
                    <div className="py-1 text-sm text-gray-700 dark:text-gray-200 flex flex-col">
                        {values.map((option) => (
                            <button
                                key={getKey(option)}
                                type="button"
                                className="inline-flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => {
                                    setIsMenuOpen(false);
                                    onChange(option);
                                }}
                            >
                                {renderItem ? (
                                    renderItem(option)
                                ) : typeof option === 'string' ? (
                                    <div className="inline-flex items-center">
                                        {option}
                                    </div>
                                ) : (
                                    'Value is not string and renderItem is not defined'
                                )}
                            </button>
                        ))}
                        {renderAddItem && renderAddItem()}
                    </div>
                </div>
            )}
        </div>
    );
}
