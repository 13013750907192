import { Cell } from '@tanstack/react-table';
import { PointGet } from 'apis/mapNotesApi';
import { NavLink } from 'react-router-dom';

export default function NavLinkCell({
    cell,
}: {
    cell: Cell<PointGet, string>;
}): JSX.Element {
    return (
        <NavLink
            to={`/?point=${cell.getValue()}`}
            className="hover:text-blue-500"
        >
            <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
            </svg>
        </NavLink>
    );
}
