import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import {
    AuthenticationResult,
    InteractionRequiredAuthError,
    PublicClientApplication,
} from '@azure/msal-browser';

import './index.css';
import { URL } from 'url';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'flowbite';

import { loginRequest, msalConfig } from './authConfig';
import { defaults as mapNotesApiDefaults } from './apis/mapNotesApi';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const msalInstance = new PublicClientApplication(msalConfig);

mapNotesApiDefaults.baseUrl =
    process.env.NODE_ENV === 'production'
        ? 'https://map-notes-api.azurewebsites.net'
        : 'http://localhost:8000';

const getAccessTokenResponseHandler =
    (url: RequestInfo | URL, options: RequestInit | undefined) =>
    (tokenResponse: AuthenticationResult) => {
        if (options !== undefined) {
            const { headers, ...otherOptions } = options;
            return fetch(url, {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${tokenResponse.accessToken}`,
                },
                ...otherOptions,
            });
        }
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${tokenResponse.accessToken}`,
            },
        });
    };

export const fetchWithMSAccessToken = (
    url: RequestInfo | globalThis.URL,
    options?: RequestInit | undefined,
    tokenOptions?: { scopes: string[] },
) => {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
        ...(tokenOptions ?? loginRequest),
        account,
    };
    return msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(getAccessTokenResponseHandler(url, options))
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                return msalInstance
                    .acquireTokenPopup(accessTokenRequest)
                    .then(getAccessTokenResponseHandler(url, options));
            }
            throw error;
        });
};

mapNotesApiDefaults.fetch = fetchWithMSAccessToken;

root.render(
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
