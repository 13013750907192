export type MapBoxPlaceType =
    | 'address'
    | 'postcode'
    | 'district'
    | 'place'
    | 'country';

export default function MapBoxPlaceTypeToSVG(props: {
    placeType: MapBoxPlaceType;
}): JSX.Element {
    const { placeType } = props;

    const svgBaseProps = {
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        x: '0px',
        y: '0px',
        xmlSpace: 'preserve',
        className: 'w-6 h-6',
    };

    return (
        <div>
            {placeType === 'address' ? (
                <svg {...svgBaseProps} viewBox="0 0 512 512">
                    <g transform="translate(0 -1)">
                        <polygon
                            style={{ fill: '#464F5D' }}
                            points="0,371.759 512,371.759 512,142.241 0,142.241 	"
                        />
                        <path
                            style={{ fill: '#FFFFFF' }}
                            d="M102.78,265.83H68.732c-4.882,0-8.828-3.946-8.828-8.828s3.946-8.828,8.828-8.828h34.048
                   c4.873,0,8.828,3.946,8.828,8.828S107.653,265.83,102.78,265.83 M187.905,265.83h-34.048c-4.882,0-8.828-3.946-8.828-8.828
                   s3.946-8.828,8.828-8.828h34.048c4.873,0,8.828,3.946,8.828,8.828S192.778,265.83,187.905,265.83 M273.029,265.83h-34.057
                   c-4.873,0-8.828-3.946-8.828-8.828s3.955-8.828,8.828-8.828h34.057c4.873,0,8.828,3.946,8.828,8.828S277.902,265.83,273.029,265.83
                    M358.145,265.83h-34.048c-4.873,0-8.828-3.946-8.828-8.828s3.955-8.828,8.828-8.828h34.048c4.873,0,8.828,3.946,8.828,8.828
                   S363.018,265.83,358.145,265.83 M443.269,265.83h-34.048c-4.873,0-8.828-3.946-8.828-8.828s3.955-8.828,8.828-8.828h34.048
                   c4.882,0,8.828,3.946,8.828,8.828S448.151,265.83,443.269,265.83"
                        />
                    </g>
                </svg>
            ) : placeType === 'district' ? (
                <svg {...svgBaseProps} viewBox="0 0 495 495">
                    <g>
                        <path
                            d="M473.025,347.268l-39.01-66.418H303.509L264.5,347.268v112.107h-34V347.268l-39.009-66.418H60.984l-39.009,66.418v112.107
		H0v30h495v-30h-21.975V347.268z M416.844,310.85l14.976,25.497H305.706l14.975-25.497H416.844z M294.5,366.347h148.525v93.028
		h-59.263v-58.028h-30v58.028H294.5V366.347z M174.319,310.85l14.975,25.497H63.181l14.975-25.497H174.319z M51.975,366.347H200.5
		v93.028h-59.263v-58.028h-30v58.028H51.975V366.347z"
                        />
                        <path
                            d="M473.025,184.15V72.043l-39.01-66.418H303.509L264.5,72.043V184.15h-34V72.043L191.491,5.625H60.984L21.975,72.043V184.15
		H0v30h495v-30H473.025z M416.844,35.625l14.976,25.498H305.706l14.975-25.498H416.844z M294.5,91.123h148.525v93.027h-59.263
		v-58.027h-30v58.027H294.5V91.123z M174.319,35.625l14.975,25.498H63.181l14.975-25.498H174.319z M51.975,91.123H200.5v93.027
		h-59.263v-58.027h-30v58.027H51.975V91.123z"
                        />
                    </g>
                </svg>
            ) : placeType === 'country' ? (
                <svg {...svgBaseProps} viewBox="0 0 60 60">
                    <g>
                        <path
                            d="M51.371,3.146c-0.459-0.185-11.359-4.452-19.84,0.045C24.811,6.758,13.015,4.082,10,3.308V1c0-0.553-0.447-1-1-1
	S8,0.447,8,1v3c0,0.014,0.007,0.026,0.008,0.04C8.008,4.052,8,4.062,8,4.074V33v1.074V59c0,0.553,0.447,1,1,1s1-0.447,1-1V35.375
	c2.273,0.567,7.227,1.632,12.368,1.632c3.557,0,7.2-0.511,10.101-2.049c7.652-4.061,18.056,0.004,18.16,0.045
	c0.309,0.124,0.657,0.086,0.932-0.102C51.835,34.716,52,34.406,52,34.074v-30C52,3.665,51.751,3.298,51.371,3.146z M50,32.665
	c-3.26-1.038-11.646-3.096-18.469,0.525C24.812,36.756,13.02,34.082,10,33.308V33V5.375c3.853,0.961,15.381,3.343,22.469-0.417
	C39.035,1.475,47.627,3.973,50,4.777V32.665z"
                        />
                    </g>
                </svg>
            ) : placeType === 'postcode' ? (
                <svg {...svgBaseProps} viewBox="0 0 41.72 41.72">
                    <g>
                        <path
                            style={{ fill: '#010002' }}
                            d="M41.72,36.484V5.236c0-0.345-0.28-0.625-0.625-0.625H0.625C0.28,4.611,0,4.891,0,5.236v31.248
		c0,0.345,0.28,0.625,0.625,0.625h40.47C41.44,37.109,41.72,36.829,41.72,36.484z M40.47,35.859H1.25V5.861h39.22V35.859z"
                        />
                        <path
                            style={{ fill: '#010002' }}
                            d="M3.785,21.484h16.52c0.345,0,0.625-0.28,0.625-0.625V9.846c0-0.345-0.28-0.625-0.625-0.625H3.785
		c-0.345,0-0.625,0.28-0.625,0.625V20.86C3.16,21.204,3.441,21.484,3.785,21.484z M4.41,10.471h15.27v9.764H4.41
		C4.41,20.235,4.41,10.471,4.41,10.471z"
                        />
                        <rect
                            x="3.66"
                            y="23.693"
                            style={{ fill: '#010002' }}
                            width="34.401"
                            height="1.25"
                        />
                        <rect
                            x="3.66"
                            y="28.004"
                            style={{ fill: '#010002' }}
                            width="34.401"
                            height="1.25"
                        />
                    </g>
                </svg>
            ) : placeType === 'place' ? (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    xmlSpace="preserve"
                    className="w-8 h-8"
                    viewBox="0 0 512.001 512.001"
                    // @ts-ignore
                    // style={{ enableBackground: 'new 0 0 512.001 512.001' }}
                >
                    <g>
                        <polygon
                            style={{ fill: '#1D1D1B' }}
                            points="0,511.951 15.695,511.951 15.695,207.762 176.021,207.762 176.021,511.951 191.716,511.951 
		191.716,192.067 0,192.067 	"
                        />
                        <polygon
                            style={{ fill: '#1D1D1B' }}
                            points="343.733,0.046 144.017,0.046 144.017,183.913 159.712,183.913 159.712,15.741 
		328.038,15.741 328.038,248.073 288.032,248.073 288.032,511.951 303.727,511.951 303.727,263.768 432.048,263.768 
		432.048,511.951 447.744,511.951 447.744,248.073 343.733,248.073 	"
                        />
                        <rect
                            x="23.849"
                            y="224.073"
                            style={{ fill: '#1D1D1B' }}
                            width="144.018"
                            height="15.695"
                        />
                        <rect
                            x="167.864"
                            y="32.049"
                            style={{ fill: '#1D1D1B' }}
                            width="152.022"
                            height="15.695"
                        />
                        <rect
                            x="40.001"
                            y="263.928"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="88.007"
                            y="263.928"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="136.013"
                            y="263.928"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="40.001"
                            y="327.932"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="88.007"
                            y="327.932"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="136.013"
                            y="327.932"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="40.001"
                            y="391.936"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="88.007"
                            y="391.936"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="136.013"
                            y="391.936"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="40.001"
                            y="455.941"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="88.007"
                            y="455.941"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="136.013"
                            y="455.941"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <path
                            style={{ fill: '#1D1D1B' }}
                            d="M311.728,64.053H176.021v55.7h135.708V64.053z M296.033,104.057H191.716V79.749h104.318V104.057z"
                        />
                        <rect
                            x="176.025"
                            y="135.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="135.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="135.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="296.029"
                            y="135.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="191.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="191.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="247.919"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="256.028"
                            y="247.919"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="216.026"
                            y="303.929"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="303.929"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="359.929"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="359.929"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="415.939"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="415.939"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="216.026"
                            y="471.949"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="256.028"
                            y="471.949"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="296.029"
                            y="191.908"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="311.881"
                            y="280.083"
                            style={{ fill: '#1D1D1B' }}
                            width="112.01"
                            height="15.695"
                        />
                        <rect
                            x="328.037"
                            y="311.933"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="200.017"
                        />
                        <rect
                            x="360.044"
                            y="311.933"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="200.017"
                        />
                        <rect
                            x="392.041"
                            y="311.933"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="200.017"
                        />
                        <path
                            style={{ fill: '#1D1D1B' }}
                            d="M511.742,31.417l-40.051,32.541l0.056-8.006l-15.695-0.111l-0.146,20.941l-16.163,13.132v-66.02
		h-15.695v78.773l-16.308,13.251V71.901h-15.695v56.771l-24.003,19.503v91.746h15.695v-72.162h48.312v16.002h64.081l0.176,328.195
		l15.695-0.008L511.742,31.417z M432.048,152.063h-43.904l107.921-87.685l0.039,71.683H432.05v16.002H432.048z M447.744,168.064
		v-16.308h48.369l0.008,16.308H447.744z"
                        />
                        <rect
                            x="464.05"
                            y="255.923"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="464.05"
                            y="311.933"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="464.05"
                            y="367.933"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="464.05"
                            y="423.944"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="464.05"
                            y="479.943"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.003"
                        />
                        <rect
                            x="464.05"
                            y="199.913"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="432.053"
                            y="199.913"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                        <rect
                            x="400.045"
                            y="199.913"
                            style={{ fill: '#1D1D1B' }}
                            width="15.695"
                            height="32.004"
                        />
                    </g>
                </svg>
            ) : (
                placeType
            )}
        </div>
    );
}
