/**
 * map-notes-api
 * 0.1.0-alpha
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from 'oazapfts/lib/runtime';
import * as QS from 'oazapfts/lib/runtime/query';
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type PointTypeEnum = 'active' | 'on_hold' | 'killed_or_lost';
export type PointNoteGet = {
    uuid: string;
    point_uuid: string;
    text: string;
    created_timestamp: string;
    creator_username: string;
    updated_timestamp: string | null;
    updater_username: string | null;
};
export type PointGet = {
    uuid: string;
    point_group_uuid: string;
    contact_person: string | null;
    address: string | null;
    name: string | null;
    point_type: PointTypeEnum;
    lat: number;
    lng: number;
    created_timestamp: string;
    creator_username: string;
    follow_up_timestamp: string | null;
    owner_username: string | null;
    size: number | null;
    updated_timestamp: string | null;
    updater_username: string | null;
    point_notes: PointNoteGet[];
};
export type PointPost = {
    point_group_uuid: string;
    contact_person?: string;
    address?: string;
    name?: string;
    point_type: PointTypeEnum;
    lat: number;
    lng: number;
    creator_username: string;
    follow_up_timestamp?: string;
    owner_username?: string;
    size?: number;
};
export type ValidationError = {
    loc: (string | number)[];
    msg: string;
    type: string;
};
export type HttpValidationError = {
    detail?: ValidationError[];
};
export type PointPatch = {
    point_group_uuid?: string;
    contact_person?: string | null;
    address?: string;
    name?: string | null;
    point_type?: PointTypeEnum;
    lat?: number;
    lng?: number;
    follow_up_timestamp?: string | null;
    owner_username?: string | null;
    size?: number | null;
    updater_username: string;
};
export type PointGroupGetWithPointCount = {
    name: string;
    uuid: string;
    creator_username: string | null;
    created_timestamp: string;
    updated_timestamp: string | null;
    updater_username: string | null;
    point_count: number;
};
export type PointGroupPost = {
    name: string;
    creator_username: string;
};
export type PointGroupGet = {
    name: string;
    uuid: string;
    creator_username: string | null;
    created_timestamp: string;
    updated_timestamp: string | null;
    updater_username: string | null;
};
export type ErrorResponse = {
    detail: string;
};
export type PointGroupPatch = {
    name?: string;
    updater_username: string;
};
export type PointNotePost = {
    point_uuid: string;
    text: string;
    creator_username: string;
};
export type PointNotePatch = {
    text?: string;
    updater_username: string;
};
export type Polygon = {
    type?: string;
    coordinates: (any[] | any[])[][];
};
export type PolygonPropertiesGet = {
    uuid: string;
    point_uuid: string;
    created_timestamp: string;
    creator_username: string;
    updated_timestamp: string | null;
    updater_username: string | null;
};
export type PolygonFeatureGet = {
    type: 'Feature';
    geometry: Polygon;
    properties: PolygonPropertiesGet;
};
export type PolygonGeoJsonGet = {
    type: 'FeatureCollection';
    features: PolygonFeatureGet[];
};
export type PolygonPropertiesPost = {
    point_uuid: string;
    creator_username: string;
};
export type PolygonFeaturePost = {
    type: 'Feature';
    geometry: Polygon;
    properties: PolygonPropertiesPost;
};
export type PolygonGeoJsonPost = {
    type: 'FeatureCollection';
    features: PolygonFeaturePost[];
};
export type Valuation = {
    name?: string;
    created_timestamp: string;
    quarter?: string;
    valuation_id: string;
    owner: string;
    modified_timestamp: string;
    market_value?: number;
    yield_initial_value?: number;
    yield_exit_value?: number;
    longterm_vacancy_rate?: number;
    terminal_value?: number;
    vacancy_rate?: number;
    weighted_average_remaining_leasetime?: number;
    previous_valuation?: number;
    area_commercial_sqm?: number;
    noi_year_1?: number;
    noi_year_2?: number;
    noi_year_3?: number;
    noi_year_4?: number;
    noi_year_5?: number;
    discount_rate_cashflow?: number;
    discount_rate_exit_value?: number;
    capex_year_1?: number;
    capex_year_2?: number;
    capex_year_3?: number;
    capex_year_4?: number;
    capex_year_5?: number;
    current_situation?: string;
    relevant_rental_information?: string;
    valuar?: string;
    valuation_currency?: string;
    currency?: string;
    area_residential_sqm?: number;
    current_yield?: number;
    current_noi?: number;
    stable_yield?: number;
};
export type Asset = {
    asset_id: string;
    link: string;
    name: string;
    status_code: string;
    municipality?: string;
    country: string;
    fund_name?: string;
    latitude: number;
    longitude: number;
    building_type: string;
    building_subtype?: string;
    deal_owner?: string;
    transaction_currency: string;
    asset_manager?: string;
    brandname?: string;
    created_timestamp: string;
    created_by: string;
    market_value_from_related_valuations: number;
    total_investment_cost_total?: number;
    stable_noi_yield_ic_approved?: number;
    asset_stage_value_name?: string;
    valuations: Valuation[];
    equity?: number;
};
export type NrepAssets = {
    assets: Asset[];
};
/**
 * Get Points
 */
export function getPoints(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PointGet[];
    }>('/points', {
        ...opts,
    });
}
/**
 * Create Point
 */
export function createPoint(pointPost: PointPost, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        '/points',
        oazapfts.json({
            ...opts,
            method: 'POST',
            body: pointPost,
        }),
    );
}
/**
 * Delete Point
 */
export function deletePoint(pointUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<
        | {
              status: 204;
          }
        | {
              status: 404;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(`/points/${pointUuid}`, {
        ...opts,
        method: 'DELETE',
    });
}
/**
 * Update Point
 */
export function updatePoint(
    pointUuid: string,
    pointPatch: PointPatch,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        `/points/${pointUuid}`,
        oazapfts.json({
            ...opts,
            method: 'PATCH',
            body: pointPatch,
        }),
    );
}
/**
 * Get Point Notes Of Point
 */
export function getPointNotesOfPoint(
    pointUuid: string,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointNoteGet[];
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(`/points/${pointUuid}/point_notes`, {
        ...opts,
    });
}
/**
 * Get Point Groups
 */
export function getPointGroups(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PointGroupGetWithPointCount[];
    }>('/point_groups', {
        ...opts,
    });
}
/**
 * Create Point Group
 */
export function createPointGroup(
    pointGroupPost: PointGroupPost,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointGroupGet;
          }
        | {
              status: 409;
              data: ErrorResponse;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        '/point_groups',
        oazapfts.json({
            ...opts,
            method: 'POST',
            body: pointGroupPost,
        }),
    );
}
/**
 * Delete Point Group
 */
export function deletePointGroup(
    pointGroupUuid: string,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 204;
          }
        | {
              status: 404;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(`/point_groups/${pointGroupUuid}`, {
        ...opts,
        method: 'DELETE',
    });
}
/**
 * Update Point Group
 */
export function updatePointGroup(
    pointGroupUuid: string,
    pointGroupPatch: PointGroupPatch,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointGroupGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        `/point_groups/${pointGroupUuid}`,
        oazapfts.json({
            ...opts,
            method: 'PATCH',
            body: pointGroupPatch,
        }),
    );
}
/**
 * Create Point Note
 */
export function createPointNote(
    pointNotePost: PointNotePost,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointNoteGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        '/point_notes',
        oazapfts.json({
            ...opts,
            method: 'POST',
            body: pointNotePost,
        }),
    );
}
/**
 * Delete Point Note
 */
export function deletePointNote(
    pointNoteUuid: string,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 204;
          }
        | {
              status: 404;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(`/point_notes/${pointNoteUuid}`, {
        ...opts,
        method: 'DELETE',
    });
}
/**
 * Update Point Note
 */
export function updatePointNote(
    pointNoteUuid: string,
    pointNotePatch: PointNotePatch,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PointNoteGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        `/point_notes/${pointNoteUuid}`,
        oazapfts.json({
            ...opts,
            method: 'PATCH',
            body: pointNotePatch,
        }),
    );
}
/**
 * Get Polygons
 */
export function getPolygons(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PolygonGeoJsonGet;
    }>('/polygons', {
        ...opts,
    });
}
/**
 * Create Polygons
 */
export function createPolygons(
    polygonGeoJsonPost: PolygonGeoJsonPost,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 200;
              data: PolygonGeoJsonGet;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(
        '/polygons',
        oazapfts.json({
            ...opts,
            method: 'POST',
            body: polygonGeoJsonPost,
        }),
    );
}
/**
 * Delete Polygon
 */
export function deletePolygon(
    polygonUuid: string,
    opts?: Oazapfts.RequestOpts,
) {
    return oazapfts.fetchJson<
        | {
              status: 204;
          }
        | {
              status: 404;
          }
        | {
              status: 422;
              data: HttpValidationError;
          }
    >(`/polygons/${polygonUuid}`, {
        ...opts,
        method: 'DELETE',
    });
}
/**
 * Get Nrep Assets
 */
export function getNrepAssets(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: NrepAssets;
    }>('/nrep_assets', {
        ...opts,
    });
}
