/* eslint-disable max-len */

import { useContext, useEffect, useMemo, useState } from 'react';
import DeckGL, {
    GeoJsonLayer,
    TextLayer,
    IconLayer,
    ScatterplotLayer,
    FlyToInterpolator,
} from 'deck.gl';
import Map from 'react-map-gl';
import { EditableGeoJsonLayer, DrawPolygonMode } from 'nebula.gl';
import mapboxgl from 'mapbox-gl';
import { AccountInfo } from '@azure/msal-browser';
import { ErrorNotificationContext } from 'components/providers/errorNotification';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';

import { MVTLayer, TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { nanoid } from 'nanoid';
import { useSendCalendarNotification } from 'hooks/CalendarNotification';
import SendCalendarEventModal from 'components/SendCalendarEventModal';
import {
    GeoCodingSearch,
    GeoCodingSearchResult,
} from 'components/GeoCodingSearch';
import BaseMapStyleSelector from 'components/map/BaseMapStyleSelector';
import MapLayerSelector from 'components/map/MapLayerSelector';
import MapFilters from 'components/map/MapFilters';
import CreatePointGroupAlert from 'components/map/CreatePointGroupAlert';
import MapUserInstructionsAlert from 'components/map/MapUserInstructionsAlert';
import ClosePointInfoButton from 'components/map/ClosePointInfoButton';
// import { v4 as uuidv4 } from 'uuid';
import {
    MapBoxStyle,
    MapLayerType,
    MapLocation,
    // MapPolygonFeature,
    // MapPolygonFeatureCollection,
    PointFilterType,
} from './types';
import PointInfo from './components/PointInfo';

import { MapBoxReverseGeocodingFeatureCollection } from './apis/mapboxApiTypes';
import {
    createGeoCodingSearchResultIconAsString,
    createSVGIconAsString,
    getPointGroupNamesByUuid,
    MAPBOXSTYLES,
    MAPPOINTTYPES,
    objectIsMapPolygonFeature,
    objectIsPointGet,
    svgToDataURL,
} from './helpers';
import MapLocationInfo from './components/MapLocationInfo';
import PointGroupCreateModal from './components/PointGroupCreateModal';
import {
    Asset,
    createPoint,
    createPointGroup,
    createPointNote,
    createPolygons,
    deletePoint,
    deletePolygon,
    getNrepAssets,
    getPointGroups,
    getPoints,
    getPolygons,
    NrepAssets,
    PointGet,
    PointGroupGet,
    PointPatch,
    PointTypeEnum,
    PolygonFeatureGet,
    PolygonGeoJsonGet,
    updatePoint,
} from './apis/mapNotesApi';

// This mapboxgl hack is to fix transpilation error in the mapbox-gl for production build
// @ts-ignore
mapboxgl.workerClass =
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

interface PointsMapProps {
    account: AccountInfo;
    users: { [key: string]: string };
    pointFilter: PointFilterType;
    setPointFilter: React.Dispatch<React.SetStateAction<PointFilterType>>;
}

type ViewStateType = {
    longitude: number;
    latitude: number;
    zoom: number;
    pitch: number;
    bearing: number;
    transitionDuration?: number;
    transitionInterpolator?: FlyToInterpolator;
};

const HELSINKITILELAYERPROPS = {
    width: 256,
    height: 256,
};

function PointsMap({
    account,
    users,
    pointFilter,
    setPointFilter,
}: PointsMapProps): JSX.Element {
    const [mapBoxStyle, setMapBoxStyle] = useState<MapBoxStyle>(
        'satellite-streets-v9',
    );

    const [searchParams, setSearchParams] = useSearchParams();

    const [viewState, setViewState] = useState<ViewStateType>({
        longitude: 24.9352005,
        latitude: 60.1734655,
        zoom: 13,
        pitch: 0,
        bearing: 0,
    });

    const [pointGroups, setPointGroups] = useState<PointGroupGet[]>([]);
    const [geoCodingSearchResult, setGeoCodingSearchResult] = useState<
        GeoCodingSearchResult | undefined
    >(undefined);
    const [openAddPointGroupModal, setOpenAddPointGroupModal] = useState(false);

    const [selectedPointGroup, setSelectedPointGroup] = useState<
        PointGroupGet | undefined
    >();
    const [mapLayer, setMapLayer] = useState<MapLayerType | undefined>(
        undefined,
    );
    const [newMapPointPointType, setNewMapPointPointType] =
        useState<PointTypeEnum>('active');

    const [points, setPoints] = useState<PointGet[]>([]);

    const [selectedMapPointUuid, setSelectedMapPointUuid] = useState<
        string | null
    >(null);

    const selectedMapPoint = useMemo(
        () =>
            points.find((point) => point.uuid === selectedMapPointUuid) || null,
        [selectedMapPointUuid, points],
    );

    const [clickedMapLocation, setClickedMapLocation] =
        useState<MapLocation | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPointGroups, setIsLoadingPointGroups] = useState(true);

    const [allPoints, setAllPoints] = useState<PointGet[]>([]);

    const [unsavedPolygons, setUnsavedPolygons] = useState<
        GeoJSON.FeatureCollection<GeoJSON.Polygon>
    >({
        type: 'FeatureCollection',
        features: [],
    });

    const [polygons, setPolygons] = useState<PolygonGeoJsonGet>({
        type: 'FeatureCollection',
        features: [],
    });

    const [selectedPolygonUuid, setSelectedPolygonUuid] = useState<
        string | null
    >(null);

    const [nrepAssets, setNrepAssets] = useState<NrepAssets | undefined>(
        undefined,
    );

    const [nrepAssetsIsLoading, setNrepAssetsIsLoading] = useState(false);

    const [showNrepAssets, setShowNrepAssets] = useState(false);

    const [nrepAssetHoverInfo, setNrepAssetHoverInfo] = useState<
        { asset: Asset; x: number; y: number } | undefined
    >(undefined);

    // Console Log the first 10 assets in nrepAssets
    // console.log(nrepAssets?.assets?.slice(0, 10));

    const selectedPolygon = useMemo(
        () =>
            polygons.features.find(
                (polygon) => polygon.properties.uuid === selectedPolygonUuid,
            ) || null,
        [selectedPolygonUuid, polygons.features],
    );

    const [drawPolygonMode, setDrawPolygonMode] = useState(false);

    const [
        calendarEventToBeSent,
        setCalendarEventToBeSent,
        setCalendarEventToBeSentByTemplate,
        calendarEventSendingInProgress,
        sendCalendarEvent,
    ] = useSendCalendarNotification(account, users);

    const { addError } = useContext(ErrorNotificationContext);

    const changeSelectedPoint = useMemo(
        () => (pointUuid: string | null) => {
            if (pointUuid !== null) {
                setSelectedMapPointUuid(pointUuid);
                setSearchParams({ point: pointUuid });
            } else {
                setSelectedMapPointUuid(null);
                setSearchParams({});
            }
        },
        [setSelectedMapPointUuid, setSearchParams],
    );

    const fetchPointGroups = async () => {
        setIsLoadingPointGroups(true);
        try {
            const pointGroupResponse = await getPointGroups();
            if (pointGroupResponse.status === 200) {
                setPointGroups(pointGroupResponse.data);
                if (pointGroupResponse.data.length > 0) {
                    setSelectedPointGroup(pointGroupResponse.data[0]);
                }
            } else {
                addError(
                    `Error getting point groups. Error code: ${pointGroupResponse.status}`,
                );
            }
        } catch (error) {
            addError(
                "Network or server error! Check your internet connection. Couldn't get point groups.",
            );
        } finally {
            setIsLoadingPointGroups(false);
        }
    };

    const fetchPoints = async () => {
        setIsLoading(true);
        try {
            const getPointsResponse = await getPoints();
            if (getPointsResponse.status === 200) {
                setAllPoints(getPointsResponse.data);
                setPoints(getPointsResponse.data);
                setSelectedMapPointUuid(searchParams.get('point') ?? null);
                if (searchParams.get('point')) {
                    const matchedPoint = getPointsResponse.data.find(
                        (point) => point.uuid === searchParams.get('point'),
                    );
                    if (matchedPoint) {
                        setViewState({
                            ...viewState,
                            latitude: matchedPoint.lat,
                            longitude: matchedPoint.lng,
                        });
                    }
                }
            } else {
                addError(
                    `Error getting points. Error code: ${getPointsResponse.status}`,
                );
            }
        } catch {
            addError(
                "Network or server error! Check your internet connection. Couldn't get points.",
            );
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPolygons = async () => {
        setIsLoading(true);
        try {
            const getPolygonsResponse = await getPolygons();
            if (getPolygonsResponse.status === 200) {
                setPolygons(getPolygonsResponse.data);
            } else {
                addError(
                    `Error getting polygons. Error code: ${getPolygonsResponse.status}`,
                );
            }
        } catch {
            addError(
                "Network or server error! Check your internet connection. Couldn't get polygons.",
            );
        } finally {
            setIsLoading(false);
        }
    };

    const fetchNrepAssets = async () => {
        setNrepAssetsIsLoading(true);
        try {
            const getNrepAssetsResponse = await getNrepAssets();
            if (getNrepAssetsResponse.status === 200) {
                setNrepAssets(getNrepAssetsResponse.data);
            } else {
                addError(
                    `Error getting NREP assets. Error code: ${getNrepAssetsResponse.status}`,
                );
            }
        } catch {
            addError(
                "Network or server error! Check your internet connection. Couldn't get NREP assets.",
            );
        } finally {
            setNrepAssetsIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPointGroups();
        fetchPoints();
        fetchPolygons();
    }, []);

    useEffect(() => {
        if (
            pointFilter.point_group_uuids.length > 0 ||
            pointFilter.point_types.length > 0 ||
            pointFilter.owner_usernames.length > 0
        ) {
            setPoints(
                allPoints.filter(
                    (point) =>
                        (!(pointFilter.point_group_uuids.length > 0)
                            ? true
                            : pointFilter.point_group_uuids.includes(
                                  point.point_group_uuid,
                              )) &&
                        (!(pointFilter.point_types.length > 0)
                            ? true
                            : pointFilter.point_types.includes(
                                  point.point_type,
                              )) &&
                        (!(pointFilter.owner_usernames.length > 0)
                            ? true
                            : point.owner_username !== null
                            ? pointFilter.owner_usernames.includes(
                                  point.owner_username,
                              )
                            : false),
                ),
            );
        } else {
            setPoints(allPoints);
        }
    }, [pointFilter, allPoints]);

    const onMapLocationSaveCallback = async ({
        mapLocation,
        newNoteText,
    }: {
        mapLocation: MapLocation;
        newNoteText: string;
    }) => {
        if (selectedPointGroup) {
            setIsLoading(true);
            try {
                const createPointResponse = await createPoint({
                    point_group_uuid: selectedPointGroup.uuid,

                    address: mapLocation.address || undefined,
                    point_type: newMapPointPointType,
                    lng: mapLocation.coordinates[0],
                    lat: mapLocation.coordinates[1],
                    creator_username: account.username,
                    owner_username: account.username,
                });

                if (createPointResponse.status === 200) {
                    setAllPoints([...allPoints, createPointResponse.data]);

                    if (newNoteText.length > 0) {
                        const createPointNoteResponse = await createPointNote({
                            point_uuid: createPointResponse.data.uuid,
                            text: newNoteText,
                            creator_username: account.username,
                        });

                        if (createPointNoteResponse.status === 200) {
                            changeSelectedPoint(createPointResponse.data.uuid);
                        } else {
                            addError(
                                `Couldn't create a point note! Error code: ${createPointNoteResponse.status}`,
                            );
                        }
                    } else {
                        changeSelectedPoint(createPointResponse.data.uuid);
                    }
                } else {
                    addError(
                        `Couldn't create a point! Error code: ${createPointResponse.status}`,
                    );
                }
            } catch {
                addError(
                    "Network or server error! Check your internet connection! Couldn't create a point.",
                );
            } finally {
                setIsLoading(false);
            }
        }
        setClickedMapLocation(null);
    };

    const onAddPointGroupCallback = async (name: string) => {
        setOpenAddPointGroupModal(false);
        try {
            const createPointGroupResponse = await createPointGroup({
                name,
                creator_username: account.username,
            });
            if (createPointGroupResponse.status === 200) {
                await fetchPointGroups();
                setSelectedPointGroup(createPointGroupResponse.data);
            } else if (createPointGroupResponse.status === 409) {
                addError(
                    `Couldn't create a point group! ${createPointGroupResponse.data.detail}`,
                );
            } else {
                addError(
                    `Couldn't create a point group! Error code: ${createPointGroupResponse.status}`,
                );
            }
        } catch {
            addError(
                "Network or server error! Check your internet connection! Couldn't create a point group.",
            );
        }
    };

    const pointGroupNamesByUuid = useMemo(
        () => getPointGroupNamesByUuid(pointGroups),
        [pointGroups],
    );

    const mapPointIconUrlByType: { [K in PointTypeEnum]: string } = useMemo(
        () => ({
            active: svgToDataURL(createSVGIconAsString('active')),
            on_hold: svgToDataURL(createSVGIconAsString('on_hold')),
            killed_or_lost: svgToDataURL(
                createSVGIconAsString('killed_or_lost'),
            ),
        }),
        [createSVGIconAsString, svgToDataURL],
    );

    const onClickNewMapLocation = (
        address: string | null,
        coordinate: number[],
    ) => {
        changeSelectedPoint(null);
        setDrawPolygonMode(false);
        setSelectedPolygonUuid(null);
        setClickedMapLocation({
            address,
            coordinates: coordinate as [number, number],
        });
        setIsLoading(false);
    };

    const savePolygons = async (mapPointUuid: string) => {
        setIsLoading(true);
        try {
            const createPolygonsResponse = await createPolygons({
                ...unsavedPolygons,
                features: unsavedPolygons.features.map((polygon) => ({
                    ...polygon,
                    properties: {
                        ...polygon.properties,
                        point_uuid: mapPointUuid,
                        creator_username: account.username,
                    },
                })),
            });

            if (createPolygonsResponse.status === 200) {
                setPolygons({
                    ...polygons,
                    features: [
                        ...polygons.features,
                        ...createPolygonsResponse.data.features,
                    ],
                });
            } else {
                addError(
                    `Couldn't create polygons! Error code: ${createPolygonsResponse.status}`,
                );
            }
        } catch {
            addError(
                "Network or server error! Check your internet connection! Couldn't create polygons.",
            );
        } finally {
            setIsLoading(false);
        }

        setUnsavedPolygons({
            ...unsavedPolygons,
            features: [],
        });
    };

    const onPointDelete = async () => {
        if (selectedMapPoint) {
            setIsLoading(true);
            try {
                const deletePointResponse = await deletePoint(
                    selectedMapPoint.uuid,
                );

                if (deletePointResponse.status === 204) {
                    setAllPoints(
                        allPoints.filter(
                            (mapPoint) =>
                                mapPoint.uuid !== selectedMapPoint.uuid,
                        ),
                    );
                    changeSelectedPoint(null);
                    setDrawPolygonMode(false);
                    // if (selectedMapPoint) {
                    //     savePolygons(selectedMapPoint.uuid);
                    // }
                } else {
                    addError(
                        `Couldn't delete a point! Error code: ${deletePointResponse.status}`,
                    );
                }
            } catch {
                addError(
                    "Network or server error! Check your internet connection! Couldn't delete a point.",
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onPolygonDelete = async () => {
        if (selectedPolygon) {
            try {
                const deletePolygonResponse = await deletePolygon(
                    selectedPolygon.properties.uuid,
                );
                if (deletePolygonResponse.status === 204) {
                    setPolygons({
                        ...polygons,

                        features: polygons.features.filter(
                            (feature) =>
                                feature.properties.uuid !==
                                selectedPolygon.properties.uuid,
                        ),
                    });
                } else {
                    addError(
                        `Couldn't delete polygon. Error code: ${deletePolygonResponse.status}`,
                    );
                }
            } catch (error) {
                addError(
                    "Network or server error! Check your internet connection! Couldn't delete polygon.",
                );
            }
        }
    };

    const onPointUpdate = async (pointPatch: PointPatch) => {
        if (selectedMapPoint) {
            try {
                const updatePointResponse = await updatePoint(
                    selectedMapPoint.uuid,
                    pointPatch,
                );

                if (updatePointResponse.status === 200) {
                    setAllPoints(
                        allPoints.map((existingMapPoint) =>
                            existingMapPoint.uuid ===
                            updatePointResponse.data.uuid
                                ? updatePointResponse.data
                                : existingMapPoint,
                        ),
                    );
                    changeSelectedPoint(updatePointResponse.data.uuid);

                    if (
                        pointPatch?.follow_up_timestamp ||
                        pointPatch?.owner_username
                    ) {
                        const ownerUsername =
                            pointPatch?.owner_username ||
                            selectedMapPoint.owner_username;
                        const followUpTimeStamp =
                            pointPatch?.follow_up_timestamp ||
                            selectedMapPoint.follow_up_timestamp;

                        if (ownerUsername && followUpTimeStamp) {
                            setCalendarEventToBeSentByTemplate(
                                followUpTimeStamp,
                                ownerUsername,
                                selectedMapPoint,
                            );
                        }
                    }
                } else {
                    addError(
                        `Couldn't update a point! Error code: ${updatePointResponse.status}`,
                    );
                    fetchPoints();
                }
            } catch {
                addError(
                    "Network or server error! Check your internet connection! Couldn't update a point.",
                );
            }
        }
    };

    const layers = [
        mapLayer === 'helsinki-ajantasa-asemakaava'
            ? new TileLayer({
                  id: 'helsinki-ajantasa-asemakaava',
                  // @ts-ignore
                  getTileData: (tile) => {
                      const { west, south, east, north } = tile.bbox;
                      return `https://kartta.hel.fi/ws/geoserver/avoindata/wms?SERVICE=WMS&VERSION=1.1.0&REQUEST=GetMap&LAYERS=avoindata:Ajantasa_asemakaava&STYLES&BBOX=${west},${south},${east},${north}&WIDTH=${HELSINKITILELAYERPROPS.width}&HEIGHT=${HELSINKITILELAYERPROPS.height}&SRS=EPSG:4326&TRANSPARENT=true&FORMAT=image/png`;
                  },
                  renderSubLayers: (props) => {
                      const {
                          // eslint-disable-next-line react/prop-types
                          bbox: { west, south, east, north },
                          // eslint-disable-next-line react/prop-types
                      } = props.tile;

                      return new BitmapLayer(props, {
                          id: nanoid(),
                          data: null,
                          // eslint-disable-next-line react/prop-types
                          image: props.data,
                          bounds: [west, south, east, north],
                      });
                  },
              })
            : mapLayer === 'espoo-ajantasa-asemakaava'
            ? new TileLayer({
                  id: 'espoo-ajantasa-asemakaava',
                  // @ts-ignore
                  getTileData: (tile) => {
                      const { west, south, east, north } = tile.bbox;
                      return `https://kartat.espoo.fi/teklaogcweb/wms.ashx?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&LAYERS=Ajantasa_asemakaava_vektori&STYLES&BBOX=${west},${south},${east},${north}&WIDTH=${HELSINKITILELAYERPROPS.width}&HEIGHT=${HELSINKITILELAYERPROPS.height}&SRS=EPSG:4326&TRANSPARENT=true&FORMAT=image/png`;
                  },
                  renderSubLayers: (props) => {
                      const {
                          // eslint-disable-next-line react/prop-types
                          bbox: { west, south, east, north },
                          // eslint-disable-next-line react/prop-types
                      } = props.tile;

                      return new BitmapLayer(props, {
                          id: nanoid(),
                          data: null,
                          // eslint-disable-next-line react/prop-types
                          image: props.data,
                          bounds: [west, south, east, north],
                      });
                  },
              })
            : mapLayer === 'vantaa-ajantasa-asemakaava'
            ? new TileLayer({
                  id: 'vantaa-ajantasa-asemakaava',
                  // @ts-ignore
                  getTileData: (tile) => {
                      const { west, south, east, north } = tile.bbox;
                      return `https://gis.vantaa.fi/geoserver/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&LAYERS=kaava:asemakaava_mv&STYLES&BBOX=${west},${south},${east},${north}&WIDTH=${HELSINKITILELAYERPROPS.width}&HEIGHT=${HELSINKITILELAYERPROPS.height}&SRS=EPSG:4326&TILED=false&TRANSPARENT=true&FORMAT=image/png`;
                  },
                  renderSubLayers: (props) => {
                      const {
                          // eslint-disable-next-line react/prop-types
                          bbox: { west, south, east, north },
                          // eslint-disable-next-line react/prop-types
                      } = props.tile;

                      return new BitmapLayer(props, {
                          id: nanoid(),
                          data: null,
                          // eslint-disable-next-line react/prop-types
                          image: props.data,
                          bounds: [west, south, east, north],
                      });
                  },
              })
            : null,

        new MVTLayer({
            id: 'maanmittauslaitos-kiinteistot-mvt',

            data: 'https://avoin-karttakuva.maanmittauslaitos.fi/kiinteisto-avoin/v3/kiinteistojaotus/WGS84_Pseudo-Mercator/tilejson.json?api-key=5c4a3680-fe97-4d26-ac47-48964434c24d',
            // @ts-ignore
            pointType: 'text',
            minZoom: 12,
            maxZoom: 14,
            getLineColor: [256, 0, 0, 255],
            autoHighlight: true,
            binary: true,
            pickable: true,

            getLineWidth: (f: { properties: { layerName: string } }) => {
                switch (f.properties.layerName) {
                    case 'KiinteistotunnuksenSijaintitiedot':
                        return 1;
                    case 'MaaraalanOsanSijaintitiedot':
                        return 0;
                    case 'RajamerkinSijaintitiedot':
                        return 1;
                    case 'KiinteistorajanSijaintitiedot':
                        return 0.5;
                    case 'PalstanSijaintitiedot':
                        return 0;
                    default:
                        return 0;
                }
            },

            getText: (f: {
                properties: {
                    layerName: string;
                    kiinteistotunnuksenEsitysmuoto?: string;
                };
            }) => {
                if (
                    f.properties.layerName ===
                    'KiinteistotunnuksenSijaintitiedot'
                ) {
                    return f.properties.kiinteistotunnuksenEsitysmuoto;
                }
                return '';
            },
            getTextSize: 2,
            getTextColor: [255, 100, 0, 255],
            textOutlineColor: [0, 0, 0, 255],
            textOutlineWidth: 0.2,
            textSizeUnits: 'meters',
            textFontSettings: {
                sdf: true,
            },
            getFillColor: [0, 0, 0, 0],

            renderSubLayers: (props) =>
                new GeoJsonLayer({
                    ...props,
                    _subLayerProps: {
                        'points-text': {
                            autoHighlight: true,
                            pickable: true,
                        },
                        linestrings: {
                            autoHighlight: false,
                            pickable: false,
                        },
                    },
                }),
        }),
        showNrepAssets &&
            nrepAssets &&
            new ScatterplotLayer<Asset>({
                id: 'nrep-assets',
                data: nrepAssets.assets,
                getPosition: (asset: Asset) => [
                    asset.longitude,
                    asset.latitude,
                ],
                getFillColor: (asset) =>
                    asset.status_code === 'Inactive'
                        ? [255, 0, 0, 168]
                        : [0, 255, 0, 168],
                getRadius: 10,
                radiusScale: 2,
                radiusMinPixels: 5,
                radiusMaxPixels: 10,
                pickable: true,
                autoHighlight: true,
                highlightColor: [255, 255, 255, 255],
                onHover: ({ object, x, y }) => {
                    setNrepAssetHoverInfo({
                        asset: object,
                        x,
                        y,
                    });
                },
            }),

        selectedMapPoint &&
            drawPolygonMode &&
            new EditableGeoJsonLayer({
                id: 'editable-geojson-layer',
                data: unsavedPolygons,
                mode: DrawPolygonMode,
                selectedFeatureIndexes: [0],
                getFillColor: [0, 0, 100, 50],
                getLineColor: [0, 0, 128, 255],
                onEdit: ({ updatedData }) => {
                    setUnsavedPolygons(updatedData);
                },
            }),

        new GeoJsonLayer<PolygonFeatureGet>({
            id: 'created-polygons-layer',
            data: selectedMapPointUuid
                ? polygons.features.filter(
                      (polygon) =>
                          polygon.properties.point_uuid ===
                          selectedMapPointUuid,
                  )
                : polygons.features,
            filled: true,
            stroked: true,
            getFillColor: selectedMapPointUuid
                ? [0, 206, 209, 164]
                : [200, 200, 200, 100],
            getLineColor: selectedMapPoint
                ? [0, 206, 209, 196]
                : [0, 206, 209, 196],
            getLineWidth: 1,
            pickable: true,
            autoHighlight: !!selectedMapPoint,
            onClick: (info) => {
                if (info.object && selectedMapPointUuid) {
                    setSelectedPolygonUuid(info.object.properties.uuid);
                }
            },
        }),

        selectedPolygon &&
            new GeoJsonLayer<PolygonFeatureGet>({
                id: 'selected-created-polygon-layer',
                data: selectedPolygon,
                filled: false,
                stroked: true,
                getLineColor: [255, 0, 0, 255],
                getLineWidth: 4,
                pickable: false,
                autoHighlight: false,
            }),

        new TextLayer<PointGet>({
            id: 'map-point-group-text',
            fontSettings: {
                sdf: true,
            },
            data: points,
            pickable: false,
            getPosition: (point) => [point.lng, point.lat],
            getColor:
                mapBoxStyle === 'satellite-v9' ||
                mapBoxStyle === 'satellite-streets-v9'
                    ? [65, 255, 255]
                    : [255, 255, 255],
            getTextAnchor: 'start',
            getPixelOffset: [12, -12],

            backgroundPadding: [2, 1],
            outlineColor:
                mapBoxStyle === 'satellite-v9' ||
                mapBoxStyle === 'satellite-streets-v9'
                    ? [0, 0, 0]
                    : [0, 0, 0],
            outlineWidth: 0.5,
            getSize: 12,
            getText: (point) => pointGroupNamesByUuid[point.point_group_uuid],
        }),
        new IconLayer<PointGet>({
            id: 'map-note-points',
            data: points,
            pickable: true,
            getIcon: (point) => ({
                url: mapPointIconUrlByType[point.point_type],
                id: `map-point-${point.point_type}-icon`,
                width: 60,
                height: 60,
                anchorX: 29,
                anchorY: 29,
            }),

            getSize: 30,
            sizeScale: 1,
            autoHighlight: true,
            getPosition: (point) => [point.lng, point.lat],
            onClick: (info) => {
                if (!drawPolygonMode) {
                    changeSelectedPoint(info.object.uuid);
                }
            },
        }),

        selectedMapPoint &&
            new ScatterplotLayer<PointGet>({
                id: 'map-note-points-selected',
                data: [selectedMapPoint],
                pickable: false,
                filled: false,
                stroked: true,
                getPosition: (point) => [point.lng, point.lat],
                lineWidthMaxPixels: 6,
                lineWidthMinPixels: 6,
                radiusMaxPixels: 15,
                radiusMinPixels: 15,
                getLineColor: [0, 0, 0],
            }),

        clickedMapLocation &&
            new IconLayer<MapLocation>({
                id: 'clicked-map-location',
                data: [clickedMapLocation],
                pickable: false,
                getIcon: () => ({
                    url: mapPointIconUrlByType[newMapPointPointType],
                    id: `map-point-${newMapPointPointType}-icon`,
                    width: 60,
                    height: 60,
                    anchorX: 29,
                    anchorY: 29,
                }),
                getPosition: (mapLocation) => mapLocation.coordinates,
                getSize: 30,
                sizeScale: 1,
            }),

        clickedMapLocation &&
            new ScatterplotLayer<MapLocation>({
                id: 'clicked-map-location-highlighting',
                data: [clickedMapLocation],
                pickable: false,
                filled: false,
                stroked: true,
                getPosition: (point) => point.coordinates,
                lineWidthMaxPixels: 6,
                lineWidthMinPixels: 6,
                radiusMaxPixels: 15,
                radiusMinPixels: 15,
                getLineColor: [0, 191, 255],
            }),

        geoCodingSearchResult &&
            new IconLayer<GeoCodingSearchResult>({
                id: 'geocoding-search-result',
                data: [geoCodingSearchResult],
                pickable: false,
                getIcon: () => ({
                    url: svgToDataURL(
                        createGeoCodingSearchResultIconAsString(),
                    ),
                    id: `map-point-${newMapPointPointType}-icon`,
                    width: 60,
                    height: 60,
                    anchorX: 29,
                    anchorY: 60,
                }),
                getPosition: (mapLocation) => mapLocation.coordinates,
                getSize: 60,
                sizeScale: 1,
            }),
    ];

    return (
        <div className="flex flex-col">
            {calendarEventSendingInProgress && (
                <div className="flex justify-center items-center mt-2">
                    <LoadingSpinner text="Creating calendar event..." />
                </div>
            )}
            <div className="flex flex-wrap gap-2 mx-4 mt-2">
                <div className="flex-1 min-w-[200px]">
                    <GeoCodingSearch
                        setCoordinates={(searchResult) => {
                            setGeoCodingSearchResult(searchResult);
                            setSelectedMapPointUuid(null);
                            setViewState({
                                longitude: searchResult.coordinates[0],
                                latitude: searchResult.coordinates[1],
                                zoom:
                                    searchResult.placeType === 'address'
                                        ? 16
                                        : searchResult.placeType === 'country'
                                        ? 5
                                        : searchResult.placeType === 'postcode'
                                        ? 8
                                        : searchResult.placeType === 'place'
                                        ? 11
                                        : 14,
                                pitch: 0,
                                bearing: 0,
                                transitionDuration: 2000,
                                transitionInterpolator: new FlyToInterpolator(),
                            });
                        }}
                    />
                </div>
                {nrepAssetsIsLoading ? (
                    <LoadingSpinner
                        size="small"
                        text="Loading NREP assets..."
                    />
                ) : (
                    <div className="flex items-center mb-4">
                        <input
                            id="default-checkbox"
                            type="checkbox"
                            checked={showNrepAssets}
                            onChange={(e) => {
                                if (nrepAssets === undefined) {
                                    fetchNrepAssets();
                                }
                                setShowNrepAssets(e.target.checked);
                            }}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Show NREP assets
                        </label>
                    </div>
                )}
            </div>
            <div className="flex items-end mx-4 mt-1 mb-2 justify-between flex-wrap">
                <div className="flex space-x-2">
                    <BaseMapStyleSelector
                        mapBoxStyle={mapBoxStyle}
                        mapBoxStyles={MAPBOXSTYLES}
                        onChange={(newMapBoxStyle) =>
                            setMapBoxStyle(newMapBoxStyle)
                        }
                    />
                    <MapLayerSelector
                        mapLayer={mapLayer}
                        mapLayers={[
                            undefined,
                            'helsinki-ajantasa-asemakaava',
                            'espoo-ajantasa-asemakaava',
                            'vantaa-ajantasa-asemakaava',
                        ]}
                        setMapLayer={setMapLayer}
                    />
                </div>

                <div className="flex flex-wrap mt-1">
                    <MapFilters
                        users={users}
                        pointGroupNamesByUuid={pointGroupNamesByUuid}
                        allPoints={allPoints}
                        pointGroups={pointGroups}
                        pointFilter={pointFilter}
                        setPointFilter={setPointFilter}
                        setSelectedPointGroup={setSelectedPointGroup}
                        setNewMapPointPointType={setNewMapPointPointType}
                    />
                </div>

                <div className="mt-2">
                    {isLoadingPointGroups ? (
                        <LoadingSpinner
                            text="Fetching point groups..."
                            size="small"
                        />
                    ) : (
                        <button
                            type="button"
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-1 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            onClick={() => {
                                setOpenAddPointGroupModal(true);
                            }}
                        >
                            Create a new point group
                        </button>
                    )}
                </div>
            </div>

            <div
                className={`relative mx-4 my-2 ${
                    drawPolygonMode
                        ? 'outline-dashed outline-4 outline-purple-700 outline-offset-2'
                        : 'outline outline-2 outline-gray-300'
                }`}
            >
                <div className="relative h-[85vh]">
                    <DeckGL
                        initialViewState={viewState}
                        controller
                        layers={layers}
                        getCursor={({ isHovering, isDragging }) => {
                            if (isLoading) {
                                return 'progress';
                            }
                            if (isDragging) {
                                return 'move';
                            }
                            if (isHovering) {
                                return 'pointer';
                            }
                            return 'default';
                        }}
                        onClick={async ({ coordinate, layer, object }) => {
                            // Check if there is layer then it is not note point layer
                            if (coordinate && !drawPolygonMode) {
                                if (
                                    layer &&
                                    layer !== null &&
                                    layer.id === 'map-note-points' &&
                                    objectIsPointGet(object)
                                ) {
                                    changeSelectedPoint(object.uuid);
                                    setClickedMapLocation(null);
                                    setSelectedPolygonUuid(null);
                                } else if (
                                    layer &&
                                    layer !== null &&
                                    layer.id === 'created-polygons-layer' &&
                                    objectIsMapPolygonFeature(object) &&
                                    selectedMapPoint !== null &&
                                    !drawPolygonMode
                                ) {
                                    setSelectedPolygonUuid(
                                        object.properties.uuid,
                                    );
                                } else {
                                    setIsLoading(true);
                                    try {
                                        const mapBoxGeoCodingReponse =
                                            await fetch(
                                                `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinate[0]},${coordinate[1]}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
                                            );

                                        if (mapBoxGeoCodingReponse.ok) {
                                            const mapBoxData: MapBoxReverseGeocodingFeatureCollection =
                                                await mapBoxGeoCodingReponse.json();

                                            if (
                                                mapBoxData.features.length > 0
                                            ) {
                                                if (
                                                    mapBoxData.features[0]
                                                        .place_type[0] === 'poi'
                                                ) {
                                                    onClickNewMapLocation(
                                                        mapBoxData.features[0]
                                                            .properties
                                                            ?.address ||
                                                            mapBoxData
                                                                .features[0]
                                                                .place_name ||
                                                            null,
                                                        coordinate,
                                                    );
                                                } else {
                                                    onClickNewMapLocation(
                                                        mapBoxData.features[0]
                                                            .place_name || null,
                                                        coordinate,
                                                    );
                                                }
                                            }
                                        }
                                    } catch {
                                        onClickNewMapLocation(null, coordinate);
                                    }
                                }
                            }
                        }}
                    >
                        <Map
                            mapStyle={`mapbox://styles/mapbox/${mapBoxStyle}`}
                            mapboxAccessToken={
                                process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                            }
                        />

                        {nrepAssetHoverInfo?.asset && (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 5,
                                    left: nrepAssetHoverInfo.x,
                                    top: nrepAssetHoverInfo.y,
                                }}
                                className="flex flex-col space-y-1 p-2 bg-white"
                            >
                                {nrepAssetHoverInfo.asset &&
                                    (
                                        [
                                            // 'asset_id',
                                            'brandname',
                                            // 'link',
                                            'name',
                                            'stable_noi_yield_ic_approved',
                                            'status_code',
                                            'municipality',
                                            'country',
                                            'fund_name',
                                            'latitude',
                                            'longitude',
                                            'building_type',
                                            'building_subtype',
                                            'created_by',
                                            'created_timestamp',
                                        ] as Array<keyof Asset>
                                    ).map((key) => (
                                        <span key={key} className="text-sm">
                                            {`${key}: ${nrepAssetHoverInfo.asset[key]}`}
                                        </span>
                                    ))}
                            </div>
                        )}
                    </DeckGL>
                </div>
                <div className="absolute top-2 left-4">
                    {selectedMapPoint && (
                        <div className="flex space-x-1">
                            <button
                                type="button"
                                className="py-2 px-3 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => {
                                    setDrawPolygonMode(!drawPolygonMode);
                                    if (!drawPolygonMode === false) {
                                        savePolygons(selectedMapPoint.uuid);
                                    }
                                }}
                            >
                                {drawPolygonMode
                                    ? 'Stop drawing / Save polygons'
                                    : 'Draw polygons'}
                            </button>

                            {drawPolygonMode &&
                                unsavedPolygons.features.length > 0 && (
                                    <button
                                        type="button"
                                        className="py-2 px-3 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                        onClick={() => {
                                            setUnsavedPolygons({
                                                ...unsavedPolygons,
                                                features: [],
                                            });
                                        }}
                                    >
                                        Clear unsaved polygons (
                                        {unsavedPolygons.features.length})
                                    </button>
                                )}

                            {!drawPolygonMode && selectedPolygon && (
                                <button
                                    type="button"
                                    className="py-2 px-3 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                    onClick={onPolygonDelete}
                                >
                                    Delete selected polygon
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className="absolute max-w-[600px] top-2 right-4">
                    {isLoading ? (
                        <div className="flex flex-col justify-center items-center space-y-2 w-full pt-2 pr-4">
                            <div
                                className="flex items-center p-4 w-full max-w-lg text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className="flex justify-center w-full items-center">
                                    <LoadingSpinner text="Loading..." />
                                </div>
                            </div>
                        </div>
                    ) : !isLoading && pointGroups.length > 0 ? (
                        <div className="relative">
                            {(clickedMapLocation || selectedMapPoint) && (
                                <div className="absolute top-0 -left-6">
                                    <ClosePointInfoButton
                                        onClick={() => {
                                            setClickedMapLocation(null);
                                            changeSelectedPoint(null);
                                            setDrawPolygonMode(false);
                                            if (selectedMapPoint) {
                                                savePolygons(
                                                    selectedMapPoint.uuid,
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            <div className="space-y-1">
                                {!clickedMapLocation && !selectedMapPoint && (
                                    <MapUserInstructionsAlert />
                                )}

                                {clickedMapLocation && selectedPointGroup && (
                                    <MapLocationInfo
                                        mapLocation={clickedMapLocation}
                                        onMapLocationSave={
                                            onMapLocationSaveCallback
                                        }
                                        pointType={newMapPointPointType}
                                        setPointType={setNewMapPointPointType}
                                        pointTypes={
                                            pointFilter.point_types.length > 0
                                                ? pointFilter.point_types
                                                : MAPPOINTTYPES
                                        }
                                        pointGroup={selectedPointGroup}
                                        setPointGroup={setSelectedPointGroup}
                                        pointGroups={
                                            pointFilter.point_group_uuids
                                                .length > 0
                                                ? pointGroups.filter(
                                                      (pointGroup) =>
                                                          pointFilter.point_group_uuids.includes(
                                                              pointGroup.uuid,
                                                          ),
                                                  )
                                                : pointGroups
                                        }
                                    />
                                )}
                                {selectedMapPoint && (
                                    <PointInfo
                                        key={selectedMapPoint.uuid}
                                        users={users}
                                        point={selectedMapPoint}
                                        pointGroups={pointGroups}
                                        currentUsername={account.username}
                                        onPointDelete={onPointDelete}
                                        onPointUpdate={onPointUpdate}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <CreatePointGroupAlert />
                    )}
                </div>
            </div>

            <PointGroupCreateModal
                open={openAddPointGroupModal}
                onAddPointGroup={onAddPointGroupCallback}
                onClose={() => setOpenAddPointGroupModal(false)}
            />

            <SendCalendarEventModal
                open={
                    !!calendarEventToBeSent && !calendarEventSendingInProgress
                }
                calendarEventInfo={calendarEventToBeSent}
                onClose={() => setCalendarEventToBeSent(null)}
                onConfirm={sendCalendarEvent}
            />
        </div>
    );
}

export default PointsMap;
