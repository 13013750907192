import { PointTypeEnum } from 'apis/mapNotesApi';
import PointTypeSVG from 'components/PointTypeSVG';
import { POINTTYPEREADABLENAMES } from 'helpers';

export default function PointTypeItem(pointType: PointTypeEnum): JSX.Element {
    return (
        <div key={pointType} className="flex items-center space-x-2">
            <PointTypeSVG mapPointType={pointType} />{' '}
            <span className="font-light italic text-xs capitalize">
                {POINTTYPEREADABLENAMES[pointType]}
            </span>
        </div>
    );
}
