import { AccountInfo } from '@azure/msal-browser';
import { PointGet } from 'apis/mapNotesApi';
import { fetchWithMSAccessToken } from 'index';
import { useState } from 'react';

export function useSendCalendarNotification(
    account: AccountInfo,
    users: Record<string, string>,
) {
    const [calendarEventToBeSent, setCalendarEventToBeSent] = useState<{
        username: string;
        fullName: string | undefined;
        startDatetime: Date;
        endDatetime: Date;
        title: string;
        body: string;
        mapPointUuid: string;
        sendToSelf: boolean;
    } | null>(null);

    const [calendarEventSendingInProgress, setCalendarEventSendingInProgress] =
        useState(false);

    const sendCalendarEvent = async () => {
        if (calendarEventToBeSent) {
            setCalendarEventSendingInProgress(true);
            await fetchWithMSAccessToken(
                `https://graph.microsoft.com/v1.0/me/calendar/events`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        subject: calendarEventToBeSent.title,
                        body: {
                            contentType: 'HTML',
                            content: calendarEventToBeSent.body,
                        },
                        start: {
                            dateTime: calendarEventToBeSent.startDatetime
                                .toISOString()
                                .replace('Z', ''),
                            timeZone: 'UTC',
                        },
                        end: {
                            dateTime: calendarEventToBeSent.endDatetime
                                .toISOString()
                                .replace('Z', ''),
                            timeZone: 'UTC',
                        },
                        allowNewTimeProposals: true,
                        ...(calendarEventToBeSent.sendToSelf
                            ? {}
                            : {
                                  attendees: [
                                      {
                                          emailAddress: {
                                              address:
                                                  calendarEventToBeSent.username,
                                              name: calendarEventToBeSent.fullName,
                                          },
                                          type: 'required',
                                      },
                                  ],
                              }),
                    }),
                },
                {
                    scopes: ['Calendars.ReadWrite'],
                },
            );
            setCalendarEventToBeSent(null);
            setCalendarEventSendingInProgress(false);
        }
    };

    const setCalendarEventToBeSentByTemplate = (
        followUpTimeStamp: string,
        ownerUsername: string,
        point: PointGet,
    ) => {
        const startTime = new Date(`${followUpTimeStamp}Z`);
        const endTime = new Date(`${followUpTimeStamp}Z`);
        startTime.setHours(8);
        endTime.setHours(8, 30);
        const sendToSelf = account.username === ownerUsername;
        const title = sendToSelf
            ? `Map notes follow up`
            : `Map notes follow-up assigned to ${
                  users[ownerUsername as string]
              } by ${users[account.username as string]}`;

        setCalendarEventToBeSent({
            username: ownerUsername,
            fullName: users[ownerUsername as string],
            startDatetime: startTime,
            endDatetime: endTime,
            title: `${title}: ${point.name || point.address}`,
            body: `This is a follow-up event for a map note. Please check the map note for more details: ${window.location.origin}/?point=${point.uuid}`,
            mapPointUuid: point.uuid,
            sendToSelf,
        });
    };

    return [
        calendarEventToBeSent,
        setCalendarEventToBeSent,
        setCalendarEventToBeSentByTemplate,
        calendarEventSendingInProgress,
        sendCalendarEvent,
    ] as const;
}
