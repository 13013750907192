import { useState } from 'react';
import MapBoxPlaceTypeToSVG, { MapBoxPlaceType } from './MapBoxPlaceTypeToSVG';

export interface GeoCodingSearchResult {
    coordinates: [number, number];
    placeType: MapBoxPlaceType;
    placeName: string;
}

export function GeoCodingSearch(props: {
    setCoordinates: (props: GeoCodingSearchResult) => void;
}) {
    // Use mapbox geocoding api to search for a location with autocomplete suggestions and display them. Use debounce to limit the number of requests.
    const { setCoordinates } = props;

    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);

    const searchLocation = async (searchString: string) => {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true&types=address,postcode,district,place,country`,
        );
        const data = await response.json();

        setResults(data.features);
    };

    return (
        <div className="w-full">
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </div>
                <input
                    type="search"
                    id="default-search"
                    className="block w-full max-w-xl p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Addresses, Locations..."
                    onChange={(event) => {
                        setSearch(event.target.value);
                        searchLocation(event.target.value);
                    }}
                    value={search}
                />
            </div>

            {results.length > 0 && (
                <div className="absolute z-20 w-full max-w-xl bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-800 dark:divide-gray-700">
                    <div className="block py-2 px-4 font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-800 dark:text-white">
                        Locations/Addresses
                    </div>
                    <div className="divide-y divide-gray-100 dark:divide-gray-700">
                        {results.map(
                            (result: {
                                place_name: string;
                                id: string;
                                place_type: MapBoxPlaceType[];
                                center: [number, number];
                            }) => (
                                <button
                                    type="button"
                                    key={result.id}
                                    className="flex w-full py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={() => {
                                        setCoordinates({
                                            coordinates: result.center as [
                                                number,
                                                number,
                                            ],
                                            placeName: result.place_name,
                                            placeType: result.place_type[0],
                                        });
                                        setSearch(result.place_name);
                                        setResults([]);
                                    }}
                                >
                                    <div className="flex flex-row space-x-2">
                                        <MapBoxPlaceTypeToSVG
                                            placeType={result.place_type[0]}
                                        />
                                        <div>{result.place_name}</div>
                                    </div>
                                </button>
                            ),
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
