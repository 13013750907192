import { Modal } from 'flowbite-react';

export default function SendCalendarEventModal({
    open,
    calendarEventInfo,
    onConfirm,
    onClose,
}: {
    open: boolean;
    calendarEventInfo: {
        username: string;
        fullName: string | undefined;
        startDatetime: Date;
        endDatetime: Date;
        sendToSelf: boolean;
    } | null;
    onConfirm: () => void;
    onClose: () => void;
}): JSX.Element {
    return (
        <Modal show={open} onClose={onClose} size="lg">
            {calendarEventInfo && (
                <>
                    <Modal.Header>
                        {calendarEventInfo.sendToSelf
                            ? 'Do you want to create a calendar event for yourself?'
                            : `Do you want to create a calendar event with ${calendarEventInfo.fullName}?`}
                    </Modal.Header>

                    <Modal.Body>
                        <div className="flex flex-col px-6 space-y-6 ">
                            {!calendarEventInfo.sendToSelf && (
                                <span>
                                    <span className="font-bold">Attendee:</span>{' '}
                                    {calendarEventInfo.fullName} (
                                    {calendarEventInfo.username})
                                </span>
                            )}
                            {calendarEventInfo.startDatetime.toDateString() ===
                            calendarEventInfo.endDatetime.toDateString() ? (
                                <div className="flex space-x-2">
                                    <span>
                                        <span className="font-bold">
                                            Date:{' '}
                                        </span>
                                        {calendarEventInfo.startDatetime.toDateString()}
                                    </span>
                                    <span>
                                        <span className="font-bold">
                                            Time:{' '}
                                        </span>
                                        {calendarEventInfo.startDatetime.toLocaleTimeString()}{' '}
                                        -{' '}
                                        {calendarEventInfo.endDatetime.toLocaleTimeString()}
                                    </span>
                                </div>
                            ) : (
                                <>
                                    <span>
                                        Start date:{' '}
                                        {calendarEventInfo.startDatetime.toLocaleString()}
                                    </span>
                                    <span>
                                        End date:{' '}
                                        {calendarEventInfo.endDatetime.toLocaleString()}
                                    </span>
                                </>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={onConfirm}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
}
