import { useState } from 'react';

import { Modal } from 'flowbite-react';

export default function PointGroupCreateModal({
    open,
    onAddPointGroup,
    onClose,
}: {
    open: boolean;
    onAddPointGroup: (name: string) => void;
    onClose: () => void;
}): JSX.Element {
    const [pointGroupName, setPointGroupName] = useState('');
    return (
        <Modal show={open} onClose={onClose} size="md">
            <Modal.Header>Create a new point group</Modal.Header>
            <Modal.Body>
                <div className=" px-6 lg:px-8 space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Point group name
                        </label>
                        <input
                            type="text"
                            name="pointGroupName"
                            value={pointGroupName}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            onChange={(e) => setPointGroupName(e.target.value)}
                        />
                    </div>

                    <button
                        type="button"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => {
                            if (pointGroupName) {
                                const perse = pointGroupName;
                                setPointGroupName('');
                                onAddPointGroup(perse);
                            }
                        }}
                    >
                        Create point group
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
