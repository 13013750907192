import { useMemo, useState, createContext } from 'react';

export const ErrorNotificationContext = createContext<{
    error: string | null;
    addError: (error: string | null) => void;
    removeError: () => void;
}>({
    error: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addError: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeError: () => {},
});

export default function ErrorNotificationProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [error, setError] = useState<string | null>(null);

    const removeError = () => {
        setError(null);
    };

    const addError = (errorText: string | null) => {
        setError(errorText);
    };

    const contextValue = useMemo(
        () => ({
            error,
            addError: (errorText: string | null) => addError(errorText),

            removeError: () => removeError(),
        }),
        [error, addError, removeError],
    );

    return (
        <ErrorNotificationContext.Provider value={contextValue}>
            {children}
        </ErrorNotificationContext.Provider>
    );
}
