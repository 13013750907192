export default function CreatePointGroupAlert(): JSX.Element {
    return (
        <div>
            <div
                id="alert-additional-content-4"
                className="p-4 mb-4 bg-yellow-100 rounded-lg dark:bg-yellow-200"
                role="alert"
            >
                <div className="flex items-center">
                    <svg
                        className="mr-2 w-5 h-5 text-yellow-700 dark:text-yellow-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <h3 className="text-lg font-medium text-yellow-700 dark:text-yellow-800">
                        Create a new point group first!
                    </h3>
                </div>
                <div className="mt-2 mb-4 text-sm text-yellow-700 dark:text-yellow-800">
                    You need to create a point group first before you can add a
                    point.
                </div>
            </div>
        </div>
    );
}
