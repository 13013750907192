import { MapBoxStyle, MapPolygonFeature } from 'types';
import { PointGet, PointGroupGet, PointTypeEnum } from './apis/mapNotesApi';

export const createSVGIconAsString = (mapPointType: PointTypeEnum): string => {
    const svgIconsByType: { [K in PointTypeEnum]: string } = {
        active: `<svg fill="lightgreen" width="60" height="60" stroke="green" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`,
        on_hold: `<svg fill="palegoldenrod" stroke="darkorange" width="60" height="60" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`,
        killed_or_lost: `<svg fill="lightpink" stroke="red" width="60" height="60" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path></svg>`,
    };

    return svgIconsByType[mapPointType];
};

export const createGeoCodingSearchResultIconAsString = (): string =>
    `<svg width="60" height="60" fill="#DE3163" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>`;

export function svgToDataURL(svg: string) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

function hasOwnProperty<X extends object, Y extends PropertyKey>(
    obj: X,
    prop: Y,
): obj is X & Record<Y, unknown> {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(prop);
}

export const objectIsPointGet = (object: unknown): object is PointGet =>
    typeof object === 'object' &&
    object !== null &&
    'uuid' in object &&
    'lat' in object &&
    'lng' in object &&
    'created_timestamp' in object &&
    'point_type' in object;

export const objectIsMapPolygonFeature = (
    object: unknown,
): object is MapPolygonFeature =>
    typeof object === 'object' &&
    object !== null &&
    hasOwnProperty(object, 'type') &&
    hasOwnProperty(object, 'geometry') &&
    hasOwnProperty(object, 'properties') &&
    typeof object.properties === 'object' &&
    object.properties !== null &&
    hasOwnProperty(object.properties, 'uuid') &&
    hasOwnProperty(object.properties, 'point_uuid');

export const getPointGroupNamesByUuid = (
    pointGroups: PointGroupGet[],
): { [key: string]: string } =>
    pointGroups.reduce(
        (acc, pointGroup) => ({
            ...acc,
            [pointGroup.uuid]: pointGroup.name,
        }),
        {},
    );

export const getPointTypeBackgroundColor = (point: PointGet) =>
    point.point_type === 'active'
        ? 'bg-green-100'
        : point.point_type === 'on_hold'
        ? 'bg-orange-100'
        : 'bg-red-100';

export const POINTTYPEREADABLENAMES: { [K in PointTypeEnum]: string } = {
    active: 'Active',
    killed_or_lost: 'Killed/Lost',
    on_hold: 'On Hold',
};

export const MAPPOINTTYPES: PointTypeEnum[] = [
    'active',
    'on_hold',
    'killed_or_lost',
];

export const MAPBOXSTYLES: MapBoxStyle[] = [
    'light-v9',
    'dark-v9',
    'streets-v9',
    'satellite-streets-v9',
    'satellite-v9',
];
