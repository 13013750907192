import { ValueProps, ValueSnapshot } from 'react-select-search';

export default function getRenderSearchSelectValue(label: string) {
    return function renderSearchSelectValue(
        valueProps: ValueProps,
        snapshot: ValueSnapshot,
    ) {
        return (
            <>
                {/* @ts-ignore */}
                <input
                    type="text"
                    id="floating_nrep_owner"
                    className="block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    {...valueProps}
                    value={
                        snapshot.focus ? snapshot.search : snapshot.displayValue
                    }
                    placeholder=" "
                />
                <label
                    htmlFor="floating_nrep_owner"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    {/* NREP owner */}
                    {label}
                </label>
            </>
        );
    };
}
