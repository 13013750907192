import { PointGroupGet, PointTypeEnum } from 'apis/mapNotesApi';
import SelectSearch from 'react-select-search';
import { MapLocation } from 'types';
// import Dropdown from './Dropdown';
import NewNote from './PointNoteTextArea';
import PointTypeButton from './PointTypeButton';
import getRenderSearchSelectValue from './RenderSearchSelectValue';
// import PointTypeItem from './PointTypeItem';

interface MapLocationInfoProps {
    mapLocation: MapLocation;
    onMapLocationSave: (props: {
        mapLocation: MapLocation;
        newNoteText: string;
    }) => void;
    pointType: PointTypeEnum;
    pointTypes: PointTypeEnum[];
    setPointType: (pointType: PointTypeEnum) => void;
    pointGroups: PointGroupGet[];
    pointGroup: PointGroupGet;
    setPointGroup: (pointGroup: PointGroupGet) => void;
    // onCancel: () => void;
}

const getBackGroundColor = (pointType: PointTypeEnum) =>
    pointType === 'active'
        ? 'bg-green-100'
        : pointType === 'on_hold'
        ? 'bg-orange-100'
        : 'bg-red-100';

const renderSearchSelectValue = getRenderSearchSelectValue('Point Group');

export default function MapLocationInfo({
    mapLocation,
    onMapLocationSave,
    pointType,
    pointTypes,
    setPointType,
    pointGroups,
    pointGroup,
    setPointGroup,
}: // onCancel,
MapLocationInfoProps): JSX.Element {
    return (
        <div
            className={`p-4 w-full rounded-lg border border-2 ${getBackGroundColor(
                pointType,
            )}  dark:bg-gray-800 dark:border-gray-700`}
        >
            <div className="flex mb-2 justify-between space-x-2">
                <span>
                    <h5 className=" text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {mapLocation.address}
                    </h5>
                </span>
                {/* <button
                    type="button"
                    onClick={onCancel}
                    className="bg-red-700 hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 focus:ring-red-300 focus:ring-4 text-white focus:outline-none font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center"
                >
                    <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    Cancel
                </button> */}
            </div>

            <div className="mb-6 flex space-x-2 items-center">
                {pointTypes.map((pointTypeValue) => (
                    <PointTypeButton
                        key={pointTypeValue}
                        pointType={pointTypeValue}
                        onClick={() => setPointType(pointTypeValue)}
                        selected={pointType === pointTypeValue}
                    />
                ))}
            </div>

            <div className="mb-3">
                <SelectSearch
                    options={pointGroups.map((pointGroupOption) => ({
                        name: pointGroupOption.name,
                        value: pointGroupOption.uuid,
                    }))}
                    renderValue={renderSearchSelectValue}
                    value={pointGroup.uuid || undefined}
                    onChange={(newPointGroupValue) => {
                        if (typeof newPointGroupValue === 'string') {
                            const newPointGroup = pointGroups.find(
                                (pointGroupOption) =>
                                    pointGroupOption.uuid ===
                                    newPointGroupValue,
                            );
                            if (newPointGroup) {
                                setPointGroup(newPointGroup);
                            }
                        }
                    }}
                    placeholder=" "
                />
            </div>
            <p className="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400">
                Coordinates: {mapLocation.coordinates.join(', ')}
            </p>

            <NewNote
                buttonType="save"
                onPointNoteAdd={(newNoteText) => {
                    onMapLocationSave({
                        mapLocation,
                        newNoteText,
                    });
                }}
            />
        </div>
    );
}
