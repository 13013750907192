import React from 'react';
import { PointNoteGet, PointNotePatch } from 'apis/mapNotesApi';
import Linkify from 'linkify-react';
import { IntermediateRepresentation } from 'linkifyjs';

interface PointNoteProps {
    pointNote: PointNoteGet;
    creatorName: string;
    currentUsername: string;
    onPointNoteDelete: (note: PointNoteGet) => void;
    onPointNoteUpdate: (note: PointNotePatch) => void;
}

function SaveIcon(): JSX.Element {
    return (
        <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
            />
        </svg>
    );
}

function CancelIcon(): JSX.Element {
    return (
        <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
        </svg>
    );
}

function DeleteIcon(): JSX.Element {
    return (
        <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
        </svg>
    );
}

function EditIcon(): JSX.Element {
    return (
        <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
        </svg>
    );
}

const renderLink = (props: IntermediateRepresentation): JSX.Element => {
    const { href, opts } = props.attributes;
    return (
        <a
            href={href}
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
            {...opts}
        >
            {props.content}
        </a>
    );
};

export default function PointNote({
    pointNote,
    creatorName,
    currentUsername,
    onPointNoteDelete,
    onPointNoteUpdate,
}: PointNoteProps): JSX.Element {
    const [isEditing, setIsEditing] = React.useState(false);
    const [NoteText, setNoteText] = React.useState(pointNote.text);
    return (
        <div className="flex items-start space-x-4">
            <div className="flex-1 min-w-0">
                {isEditing ? (
                    <textarea
                        id="note-text-textarea"
                        value={NoteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        className="block p-2 h-48 w-full text-gray-900 text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                ) : (
                    <>
                        <p className="text-sm mb-2 font-light text-gray-900 break-all whitespace-pre-wrap bg-gray-100 rounded-md p-2 border border-gray-200 dark:text-white ">
                            <Linkify
                                options={{
                                    render: renderLink,
                                }}
                            >
                                {pointNote.text}
                            </Linkify>
                        </p>
                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            {new Date(
                                pointNote.created_timestamp,
                            ).toLocaleString('fi-FI')}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                            {`${creatorName} (${pointNote.creator_username})`}
                        </p>
                    </>
                )}
            </div>
            <div className="inline-flex items-center">
                {isEditing ? (
                    <>
                        <button
                            type="button"
                            onClick={() => {
                                onPointNoteUpdate({
                                    text: NoteText,
                                    updater_username: currentUsername,
                                });
                                setIsEditing(false);
                            }}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mr-2"
                        >
                            <SaveIcon />
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setIsEditing(false);
                                setNoteText(pointNote.text);
                            }}
                            className="bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 text-white focus:ring-4 focus:outline-none  font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center mr-2"
                        >
                            <CancelIcon />
                            Cancel
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type="button"
                            onClick={() => setIsEditing(true)}
                            className="bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 focus:ring-blue-300 dark:hover:bg-blue-700 dark:focus:ring-blue-800 text-white focus:ring-4 focus:outline-none  font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center mr-2"
                        >
                            <EditIcon />
                            Edit
                        </button>
                        <button
                            type="button"
                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-md text-xs px-2 py-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                            onClick={() => onPointNoteDelete(pointNote)}
                        >
                            <DeleteIcon />
                            Delete
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}
