import { Row } from '@tanstack/react-table';
import { PointGet } from 'apis/mapNotesApi';

export default function PointNotesCell({
    row,
}: {
    row: Row<PointGet>;
}): JSX.Element | null {
    if (!row.original || !row.original.point_notes.length) {
        return null;
    }
    const maxNotes = 3;

    const sortedNotes = row.original.point_notes.sort(
        (a, b) =>
            new Date(b.created_timestamp).getTime() -
            new Date(a.created_timestamp).getTime(),
    );
    return (
        <div className="group">
            <div className="flex flex-col space-y-2">
                <div className="text-gray-900 group-hover:cursor-pointer  group-hover:text-blue-500 group-hover:underline">
                    {sortedNotes.slice(0, maxNotes).map((pointNote) => (
                        <div key={pointNote.uuid}>
                            <p className="font-light truncate w-[40ch]">
                                {pointNote.text}
                            </p>
                        </div>
                    ))}
                    {row.original.point_notes.length > maxNotes && (
                        <div className="flex items-center">
                            ...{' '}
                            {`${
                                row.original.point_notes.length - maxNotes
                            } more`}
                        </div>
                    )}
                </div>
            </div>
            <div className="absolute  z-10 invisible bg-gray-100 group-hover:visible p-2 shadow-md border  shadow-gray-400 rounded-md">
                <h3 className="text-lg">Notes</h3>
                {sortedNotes.map((pointNote) => {
                    const createdDate = new Date(
                        `${pointNote.created_timestamp}Z`,
                    );
                    return (
                        <div
                            className="flex flex-col space-y-1 text-md p-1 rounded-sm p-2"
                            key={pointNote.uuid}
                        >
                            <p className="font-light text-gray-900 break-all whitespace-pre-wrap bg-gray-100 rounded-sm p-1 border border-gray-200 dark:text-white">
                                {pointNote.text}
                            </p>
                            <div className="flex space-x-1 text-[0.75em]">
                                <span>{pointNote.creator_username}</span>
                                <span>
                                    {`${createdDate.toLocaleDateString(
                                        'fi-FI',
                                    )} (${createdDate.toLocaleTimeString(
                                        'fi-FI',
                                    )})`}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
