import Dropdown from 'components/Dropdown';
import { MapBoxStyle } from 'types';

export default function BaseMapStyleSelector({
    mapBoxStyle,
    mapBoxStyles,
    onChange,
}: {
    mapBoxStyle: MapBoxStyle;
    mapBoxStyles: MapBoxStyle[];
    onChange: (newMapBoxStyle: MapBoxStyle) => void;
}): JSX.Element {
    return (
        <div className="flex flex-col">
            <label
                htmlFor="mapboxstyle"
                className="flex-shrink-0 mr-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
            >
                Basemap style
            </label>
            <Dropdown
                value={mapBoxStyle}
                values={mapBoxStyles}
                onChange={onChange}
                getKey={(value) => value}
            />
        </div>
    );
}
